import React from 'react'
import ClientsList from '../../pages/Commercial/Clients/List'
import ClientsUpdate from '../../pages/Commercial/Clients/Update'
import ClientsCreate from '../../pages/Commercial/Clients/Create'
import ClientsView from '../../pages/Commercial/Clients/View'
import PrivateRoute from '../PrivateRoute'
import { CustomSwitch } from '../../components/CustomSwitch'
import { Route } from 'react-router-dom'
import ContractsList from '../../pages/Commercial/Clients/Contracts'

export const ClientsRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/commercial/clients" exact component={ClientsList} />
    <PrivateRoute
      path="/commercial/clients/create"
      component={ClientsCreate}
      exact
    />
    <PrivateRoute
      path="/commercial/clients/view/:id"
      component={ClientsView}
      exact
    />
    <PrivateRoute
      path="/commercial/clients/update/:id"
      component={ClientsUpdate}
      exact
    />
    <PrivateRoute
      path="/commercial/clients/contracts/:id"
      component={ContractsList}
      exact
    />
  </CustomSwitch>
)
