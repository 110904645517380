import { useEffect } from 'react'
import useCollapse from 'react-collapsed'
import { useFormContext } from 'react-hook-form'
import { Input, InputProps } from '../../../../../../components/Form'

interface InputNameProps extends InputProps {
  name: string
}

interface FormNameProps {
  first: InputNameProps
  second?: InputNameProps
  hasVertical?: boolean
  parentField?: {
    name: string
    clearField: string
  }
  changeTab?: React.SetStateAction<string>
}

export const FormName: React.FC<FormNameProps> = ({
  first,
  second,
  parentField,
  changeTab,
  hasVertical
}) => {
  const {
    register,
    watch,
    resetField,
    getValues,
    setValue,
    clearErrors,
    formState: { errors }
  } = useFormContext()
  const watchField = watch(parentField?.name)
  useEffect(() => {
    if (watchField === 'false' && parentField) {
      const workFields = getValues(parentField?.clearField)
      if (workFields) {
        const objectEntries = Object.entries(workFields)
        for (let [key] of objectEntries) {
          key = `${parentField.clearField}.${key}`
          setValue(key, '')
        }
      }
      clearErrors(parentField.clearField)
    }
  }, [
    clearErrors,
    first.name,
    getValues,
    parentField,
    resetField,
    second?.name,
    setValue,
    watchField
  ])
  return hasVertical ? (
    <>
      <div className="row mb-5 pe-xl-5" id="name">
        <Input
          className="col-xl"
          label="Sobrenome (EXATAMENTE conforme aparece no passaporte)"
          inputOptions={{
            classname: 'd-flex align-items-center'
          }}
          errors={errors}
          register={register}
          name={second.name}
          tooltip={{
            container: {
              classname: 'ms-2'
            },
            classname: 'mw-30px',
            message: `<p style="max-width: 300px">
          Separe corretamente o nome do sobrenome
                          </p>`
          }}
          rules={
            parentField
              ? { required: watchField === 'true' && changeTab !== 'previous' }
              : { required: false }
          }
          {...second}
          hasErrorCustom
          hasCustom
        />
      </div>
      <div className="row mb-5 pe-xl-5" id="name">
        <Input
          register={register}
          className="col-xl"
          label="Nome (EXATAMENTE conforme aparece no passaporte)"
          inputOptions={{
            classname: 'd-flex align-items-center'
          }}
          name={first?.name}
          tooltip={{
            container: {
              classname: 'ms-2'
            },
            classname: 'mw-30px',
            message: `<p style="max-width: 300px">
          Separe corretamente o nome do sobrenome
        </p>`
          }}
          errors={errors}
          rules={
            parentField
              ? { required: watchField === 'true' && changeTab !== 'previous' }
              : { required: false }
          }
          {...first}
          hasErrorCustom
          hasCustom
        />
      </div>
    </>
  ) : (
    <div className="row mb-5 pe-xl-5" id="name">
      {!!second?.name && (
        <Input
          className="col-xl"
          label="Sobrenome (EXATAMENTE conforme aparece no passaporte)"
          inputOptions={{
            classname: 'd-flex align-items-center'
          }}
          errors={errors}
          register={register}
          name={second.name}
          tooltip={{
            container: {
              classname: 'ms-2'
            },
            classname: 'mw-30px',
            message: `<p style="max-width: 300px">
          Separe corretamente o nome do sobrenome
                          </p>`
          }}
          rules={
            parentField
              ? { required: watchField === 'true' && changeTab !== 'previous' }
              : { required: changeTab !== 'previous' }
          }
          hasErrorCustom
          hasCustom
          {...second}
        />
      )}
      {!!first?.name && (
        <Input
          register={register}
          className="col-xl"
          label="Nome (EXATAMENTE conforme aparece no passaporte)"
          inputOptions={{
            classname: 'd-flex align-items-center'
          }}
          name={first?.name}
          tooltip={{
            container: {
              classname: 'ms-2'
            },
            classname: 'mw-30px',
            message: `<p style="max-width: 300px">
         Separe corretamente o nome do sobrenome
       </p>`
          }}
          errors={errors}
          rules={
            parentField
              ? { required: watchField === 'true' && changeTab !== 'previous' }
              : { required: changeTab !== 'previous' }
          }
          hasErrorCustom
          hasCustom
          {...first}
        />
      )}
    </div>
  )
}
