import { PageLink } from '../../../../../../assets/layout/core'

export const breadcrumbList: PageLink[] = [
  {
    title: 'Clientes',
    path: '/clients',
    isSeparator: false,
    isActive: false
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false
  }
]
