import React from 'react'
import logo from '../../assets/media/logos/logo.png'
import {
  ContainerHeader,
  ContainerLogo,
  WrapperContent,
  Container
} from './styles'
import { Link } from 'react-router-dom'

export const Home: React.FC = () => {
  return (
    <div>
      <div className="row gy-5 g-xl-8 mb-5">
        <ContainerHeader>
          <WrapperContent>
            <ContainerLogo>
              <a href="#">
                <img
                  alt="Logo"
                  src={logo}
                  className="w-150px logo"
                  style={{ objectFit: 'cover' }}
                />
              </a>
            </ContainerLogo>
          </WrapperContent>
        </ContainerHeader>
      </div>

      <Container>
        <WrapperContent
          className="ps-2"
          style={{
            flexDirection: 'column',
            justifyContent: 'unset',
            alignItems: 'unset'
          }}
        >
          <div>
            <h1>Formulários</h1>
            <p>Selecione o formulário que melhor atende suas necessidades</p>
          </div>
          <div className="w-100 d-flex align-items-center">
            <div className="card px-10 py-10 mt-3 w-100 h-50">
              <div className="d-flex align-items-center justify-content-center h-100 w-100">
                <div className="row w-100">
                  <div className="col-sm">
                    <Link
                      to="/forms/passport"
                      className="btn btn-lg btn-primary min-w-sm-200px  min-h-sm-60px mb-5 btn-block text-center d-flex justify-content-center align-items-center fs-2"
                    >
                      <span className="indicator-label">Passaporte</span>
                    </Link>
                  </div>
                  <div className="col-sm">
                    <Link
                      to="/forms/canada"
                      className="btn btn-lg btn-primary min-w-sm-250px min-h-sm-60px mb-5 btn-block text-center d-flex justify-content-center align-items-center fs-2"
                    >
                      <span className="indicator-label">Visto Canadense</span>
                    </Link>
                  </div>
                  <div className="col-sm">
                    <Link
                      to="/forms/mexico"
                      className="btn btn-lg btn-primary min-w-sm-200px min-h-sm-60px mb-5 btn-block text-center d-flex justify-content-center align-items-center fs-2"
                    >
                      <span className="indicator-label">Visto México</span>
                    </Link>
                  </div>
                  <div className="col-sm">
                    <Link
                      to="/forms/american"
                      className="btn btn-lg btn-primary min-w-sm-200px min-h-sm-60px mb-5 btn-block text-center d-flex justify-content-center align-items-center fs-2"
                    >
                      <span className="indicator-label">Visto Americano</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </WrapperContent>
      </Container>
    </div>
  )
}

export default Home
