import moment from 'moment'
import { useCallback, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { IMaskInput } from 'react-imask'
import {
  Checkbox,
  CheckboxProps,
  Input,
  InputProps,
  Select,
  SelectProps
} from '../../../../../../components/Form'
import { CheckboxWithForm } from '../CheckboxWithForm'
import useCollapse from 'react-collapsed'
import { maxDate, TYPE_CITIZENSHIP } from '../../../../../../common/constants'
import { DatePickerCustom } from '../../../../../../components/Form/dateWithoutState'

interface DatepickerProps {
  name: string
}

interface FormFamilyProps {
  datepickerBirthDate: DatepickerProps
  inputOccupation: InputProps
  inputName: InputProps
  inputAddress: InputProps
  inputCountry: InputProps
  inputRelationship?: InputProps
  selectMaritalStatus: SelectProps
  selectTravelWithYou: SelectProps
  checkboxInTheUsa: CheckboxProps
  label?: string
  type?: string
  positive?: InputProps
  negative?: InputProps
  index?: number
  data?: any[]
  setData?: React.Dispatch<React.SetStateAction<any[]>>
  defaultShow?: string
  tooltip?: {
    type: string
  }
  changeTab?: React.SetStateAction<string>
  hasHeader?: {
    label?: string
    positive?: InputProps
    negative?: InputProps
  }
}

export const FormFamily: React.FC<FormFamilyProps> = ({
  datepickerBirthDate,

  inputName,

  type,

  checkboxInTheUsa,

  index,
  data = [],
  defaultShow = '',

  setData,
  hasHeader,
  changeTab
}) => {
  const {
    register,
    watch,
    setValue,
    getValues,
    control,
    clearErrors,
    formState: { errors }
  } = useFormContext()

  const inputMaskFormat = 'DD/MM/YYYY'
  const [copyData, setCopyData] = useState([...data])
  const [showInput, setShowInput] = useState(
    watch(hasHeader?.positive?.name || '') ||
      data[index]?.isActive ||
      copyData[index]?.isActive ||
      '' ||
      defaultShow
  )
  const hasOpen = hasHeader ? watch(hasHeader.positive.name) : showInput
  const hasChildOpen = watch(checkboxInTheUsa?.name + '.check') === 'true'
  const { getToggleProps, getCollapseProps } = useCollapse({
    isExpanded: hasOpen
  })
  const allowClass = ['show', 'show child']

  const handleClose = useCallback(() => {
    const showClass = ['show', 'show child']
    const watchField = watch(hasHeader?.positive?.name || '')
    if (watchField === 'hidden') {
      const prefixArray = hasHeader?.positive?.name.split('.')
      prefixArray.pop()
      const prefix = prefixArray.join('.')
      const workFields = getValues(prefix)
      if (workFields) {
        const objectEntries = Object.entries(workFields)
        for (let [key] of objectEntries) {
          key = `${prefix}.${key}`
          if (hasHeader?.positive?.name !== key) {
            setValue(key, '')
          }
        }
      }

      clearErrors(prefix)
    }
    if (showClass.includes(data[index]?.isActive)) {
      const prefixArray = hasHeader?.positive?.name.split('.')
      prefixArray.pop()
      const prefix = prefixArray.join('.')
      const workFields = getValues(prefix)
      if (workFields) {
        const objectEntries = Object.entries(workFields)

        for (let [key] of objectEntries) {
          key = `${prefix}.${key}`
          if (hasHeader?.positive?.name !== key) {
            setValue(key, '')
            clearErrors(prefix)
          }
        }
      }
      setData(oldState => {
        const data = [...oldState]
        data[index] = {
          name: '',
          travel: '',
          address: '',
          country: '',
          surname: '',
          isActive: showClass.includes(data[index].isActive)
            ? 'hidden'
            : 'show child',
          birth_date: '',
          occupation: ''
        }

        return data
      })
    }
  }, [
    clearErrors,
    data,
    getValues,
    hasHeader?.positive?.name,
    index,
    setData,
    setValue,
    watch
  ])
  return (
    <div className="row pe-sm-5 pe-xl-0">
      <div>
        {hasHeader?.label && (
          <p className="mb-2 form-check-label text-gray-700 fw-bold p-0">
            {hasHeader?.label}
          </p>
        )}
        <div className="d-flex">
          {hasHeader && hasHeader.positive && (
            <Checkbox
              className="col-xl-1"
              label="Sim"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              register={register}
              {...hasHeader.positive}
              errors={errors}
              rules={{
                required: changeTab !== 'previous',
                onChange: () => {
                  const watchField = watch(hasHeader.positive.name)
                  if (watchField === 'show') {
                    if (type !== 'parents') {
                      setCopyData(oldState => {
                        const data = [...oldState]
                        data[index].isActive = 'show'
                        return [...data]
                      })
                    }
                    setShowInput('show')
                  } else {
                    handleClose()
                    setShowInput('hidden')
                  }
                }
              }}
              value="show"
              type="radio"
              checked={allowClass.includes(showInput)}
              hasErrorCustom
              hasCustom
              {...getToggleProps()}
            />
          )}
          {hasHeader && hasHeader.negative && (
            <Checkbox
              className="col-xl-1"
              label="Não"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              {...hasHeader.negative}
              register={register}
              type="radio"
              value="hidden"
              errors={errors}
              rules={{
                required: changeTab !== 'previous'
              }}
              checked={showInput === 'hidden'}
              hasCustom
            />
          )}
        </div>
      </div>
      <div {...getCollapseProps()}>
        <div className="row pe-sm-5 pe-xl-0 mb-5">
          <Input
            className="col-xl"
            label="Nome completo"
            register={register}
            inputOptions={{
              classname: 'd-flex align-items-center'
            }}
            {...inputName}
            rules={
              changeTab !== 'previous'
                ? {
                    required: hasHeader
                      ? watch(hasHeader.positive.name) === 'show'
                      : allowClass.includes(showInput)
                  }
                : { required: false }
            }
            errors={errors}
            hasErrorCustom
            hasCustom
          />
          <Controller
            shouldUnregister={true}
            control={control}
            name={datepickerBirthDate.name}
            rules={
              changeTab !== 'previous'
                ? {
                    required: hasHeader
                      ? watch(hasHeader.positive.name) === 'show'
                      : allowClass.includes(showInput)
                  }
                : { required: false }
            }
            render={({ field }) => (
              <DatePickerCustom
                popperProps={{ strategy: 'fixed' }}
                locale="ptBR"
                className="col-xl"
                register={register}
                inputOptions={{ classname: 'd-flex align-items-center' }}
                label="Data de nascimento"
                dateFormat="dd/MM/yyyy"
                placeholderText="DD/MM/AAAA"
                hasCustom
                {...field}
                errors={errors}
                hasErrorCustom
                maxDate={maxDate}
                customInput={
                  <IMaskInput
                    mask={Date}
                    pattern={'d/m/Y'}
                    format={date => {
                      return moment(date).format(inputMaskFormat)
                    }}
                    parse={value => {
                      return moment(value, inputMaskFormat).toDate()
                    }}
                    validate={(date: string) => {
                      if (date.length === 10) {
                        if (moment(date, inputMaskFormat).isAfter(moment())) {
                          return false
                        }
                        const age = moment().diff(
                          moment(date, inputMaskFormat),
                          'year'
                        )
                        if (age < 18) {
                          return false
                        }
                      }
                      return true
                    }}
                  />
                }
              />
            )}
          />
        </div>
        <div className="row pe-sm-5 pe-xl-0 mb-5">
          <CheckboxWithForm
            changeTab={changeTab}
            label={`Seu ${
              inputName?.name?.includes('father') ? 'pai' : 'mãe'
            } está nos EUA?`}
            positive={{
              name: checkboxInTheUsa?.name + '.check'
            }}
            negative={{
              name: checkboxInTheUsa?.name + '.check'
            }}
          >
            <div className="row mb-5">
              <Controller
                shouldUnregister={true}
                control={control}
                name={checkboxInTheUsa?.name + '.choice_answer'}
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: hasChildOpen
                      }
                    : { required: false }
                }
                render={({ field }) => (
                  <Select
                    className="col-xl"
                    label="Escolha uma das respostas"
                    hasErrorCustom
                    {...field}
                    // rules={{ required: changeTab !== 'previous' }}
                    options={TYPE_CITIZENSHIP}
                    errors={errors}
                    blank
                  />
                )}
              />
              <Input
                className="col-xl"
                label="Outro"
                name={checkboxInTheUsa?.name + '.other'}
                register={register}
                inputOptions={{
                  classname: 'd-flex align-items-center'
                }}
                errors={errors}
                hasErrorCustom
                hasCustom
              />
            </div>
            {/* <div className=" mb-5">

            </div> */}
          </CheckboxWithForm>
        </div>
      </div>
    </div>
  )
}
