import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { Checkbox, Input, InputProps } from '../../../../../../components/Form'
import { ContainerAnimation } from '../../../styles'
interface CheckboxSimpleProps {
  label: string
  positive: InputProps
  negative: InputProps
  changeTab?: React.SetStateAction<string>
  height?: number
}

export const CheckboxSimple: React.FC<CheckboxSimpleProps> = ({
  label,
  negative,
  positive,
  changeTab
}) => {
  const {
    register,
    formState: { errors }
  } = useFormContext()
  return (
    <div className="row my-10">
      <div>
        <p className="mb-2 form-check-label text-gray-700 fw-bold p-0">
          {label}
        </p>
        <div className="d-flex">
          <Checkbox
            className="col-xl-2"
            label="Sim"
            register={register}
            value="true"
            inputOptions={{
              classname: 'd-flex align-items-center'
            }}
            rules={{ required: changeTab !== 'previous' }}
            {...positive}
            type="radio"
            errors={errors}
            hasCustom
            hasErrorCustom
          />
          <Checkbox
            className="col-xl-2"
            label="Não"
            register={register}
            inputOptions={{
              classname: 'd-flex align-items-center'
            }}
            type="radio"
            value="false"
            rules={{ required: changeTab !== 'previous' }}
            {...negative}
            errors={errors}
            hasCustom
          />
        </div>
      </div>
    </div>
  )
}
