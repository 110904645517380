import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  ContainerHeader,
  ContainerLogo,
  ContainerWrapperContent,
  WrapperContent,
  ContainerAnimation
} from './styles'
import Form, {
  Checkbox,
  Input,
  Select,
  Textarea
} from '../../../components/Form'
import Tabs from '../../../components/Tabs'
import { useLoading } from '../../../hooks/loading'
import api from '../../../services/api'
import { useToast } from '../../../hooks/toast'
import { Loading } from '../../../components/Loading'
import { useHistory, useLocation } from 'react-router-dom'
import { DatePickerCustom } from '../../../components/Form/dateWithoutState'
import { IMaskInput } from 'react-imask'
import moment from 'moment'
import Tab from '../../../components/Tabs/Tab'

import logo from '../../../assets/media/logos/logo.png'
import { StringParam, useQueryParam } from 'use-query-params'
import { FormName } from './components/Forms/Name'

import { FormGeneral } from './components/Forms/General'
import { FormMaritalStatus } from './components/Forms/MaritalStatus'
import { FormFamily } from './components/Forms/Family'
import { CheckboxWithForm } from './components/Forms/CheckboxWithForm'

import { CheckboxSimple } from './components/Forms/CheckboxSimple'
import { FormFormation } from './components/Forms/FormFormation'
import { FormJob } from './components/Forms/FormJob'
import {
  DIVORCED_FIELD_NAME,
  INPUT_MASK,
  LEGALLY_SEPARATED_FIELD_NAME,
  MARRIED_FIELD_NAME,
  maxDate,
  PLACES_APPLICATION,
  SINGLE_FIELD_NAME,
  STABLE_UNION_FIELD_NAME,
  TYPE_CITIZENSHIP,
  TYPE_SOCIAL_NETWORK,
  WIDOWED_FIELD_NAME
} from '../../../common/constants'
import { checkFormatDates } from '../../../utlis/checkDateFormat'
import { CheckboxWithConditionForm } from './components/Forms/CheckboxWithConditionForm'
import Condition from 'yup/lib/Condition'
import { Controller, useFormContext } from 'react-hook-form'
import { FormWillPayYouTrip } from './components/Forms/FormWillPayYouTrip'
import { FormMultpleAddition } from './components/Forms/FormMultipleAddition'
import useCollapse from 'react-collapsed'
type OptionsValue = {
  cetificationType: SelectOptionsValue[]
  gender: SelectOptionsValue[]
  maritalStatus: SelectOptionsValue[]
  previousPassportSituation: SelectOptionsValue[]
  raceColor: SelectOptionsValue[]
  reasonChange: SelectOptionsValue[]
  profession: SelectOptionsValue[]
  states: {
    UF: string
    cities: SelectOptionsValue[]
  }[]
  countries: SelectOptionsValue[]
  nationality: SelectOptionsValue[]
  reasonTravel: SelectOptionsValue[]
  statusLegal: SelectOptionsValue[]
  relationship: SelectOptionsValue[]
  parentage: SelectOptionsValue[]
}
type SelectOptionsValue = {
  name: string
  value: string
}

const optionsReasonTravel = [
  {
    name: 'Negócios, conferência, etc. (B1)',
    value: 1
  },
  {
    name: 'Turismo, Visita familiar, outros (B2)',
    value: 2
  },
  {
    name: 'Negócios e Turismo (B1, B2)',
    value: 3
  },
  {
    name: 'Visto de fronteira (BCC)',
    value: 4
  },
  {
    name: 'Estudante (F,J,M,Q)',
    value: 5
  }
]
const optionsWhoWillPayForYourTrip = [
  {
    name: 'Recursos próprios, eu mesmo pagarei minhas despesas de viagem.',
    value: 1
  },
  {
    name: 'Outra pessoa (física) pagará as despesas da minha viagem.',
    value: 2
  },
  {
    name: 'Empresa / Cia. ou Organização pagará a viagem.',
    value: 3
  }
]
const optionsTypeJob = [
  {
    name: 'Empresa de terceiros',
    value: 1
  },
  {
    name: 'Empresa própria',
    value: 2
  }
]
const inputMaskFormat = 'DD/MM/YYYY'
const FormHasPlanNegative = () => {
  const {
    register,
    control,
    watch,
    formState: { errors }
  } = useFormContext()

  const watchField = watch('has_plan_trip.no.expected_place_of_accomodation')
  return (
    <div className="">
      <p className="col-form-label bold fs-4 text-primary">
        Mesmo não tendo adquirido a viagem, o consulado pede dados previstos
        para uma futura viagem
      </p>
      <div className="row mb-5">
        <Controller
          shouldUnregister={true}
          control={control}
          name="has_plan_trip.no.intended_date_arrival"
          render={({ field }) => (
            <DatePickerCustom
              popperProps={{ strategy: 'fixed' }}
              locale="ptBR"
              className="col-xl"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              register={register}
              label="Data Prevista De Chegada Nos Eua"
              dateFormat="dd/MM/yyyy"
              placeholderText="DD/MM/AAAA"
              hasCustom
              hasErrorCustom
              hasValidationParent={{
                parent_name: 'has_plan_trip.check',
                condition: 'false'
              }}
              minDate={new Date()}
              customInput={
                <IMaskInput
                  mask={Date}
                  pattern={'d/m/Y'}
                  format={date => {
                    return moment(date).format(inputMaskFormat)
                  }}
                  parse={value => {
                    return moment(value, inputMaskFormat).toDate()
                  }}
                />
              }
              {...field}
              errors={errors}
            />
          )}
        />
        <Input
          errors={errors}
          register={register}
          className="col-xl"
          label="Cidade Prevista De Chegada Nos Eua"
          inputOptions={{
            classname: 'd-flex align-items-center'
          }}
          name="has_plan_trip.no.intended_city"
          controlled
          hasValidationParent={{
            parent_name: 'has_plan_trip.check',
            condition: 'false'
          }}
          hasErrorCustom
          hasCustom
        />
        <Input
          errors={errors}
          register={register}
          className="col-xl"
          label="Tempo Previsto De Permanência"
          inputOptions={{
            classname: 'd-flex align-items-center'
          }}
          name="has_plan_trip.no.intended_length_stay"
          controlled
          hasValidationParent={{
            parent_name: 'has_plan_trip.check',
            condition: 'false'
          }}
          hasErrorCustom
          hasCustom
        />
      </div>

      <div className="mb-5">
        <Controller
          shouldUnregister={true}
          control={control}
          name="has_plan_trip.no.expected_place_of_accomodation"
          render={({ field }) => (
            <Select
              {...field}
              className="col-xl"
              label="Onde se hospedará"
              options={[
                {
                  name: 'Hotel a Definir',
                  value: '1'
                },
                {
                  name: 'Hotel com Reserva',
                  value: '2'
                },
                {
                  name: 'Residência',
                  value: '3'
                }
              ]}
              blank
              hasValidationParent={{
                parent_name: 'has_plan_trip.check',
                condition: 'false'
              }}
              onChange={event => field.onChange(event)}
              hasErrorCustom
              hasCustom
              errors={errors}
            />
          )}
        />
      </div>
      {watchField === '2' && (
        <>
          <div className="row mb-5">
            <Input
              errors={errors}
              register={register}
              className="col-xl"
              label="Nome do Hotel"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              name="has_plan_trip.no.accommodation.hotel.name"
              hasValidationParent={{
                parent_name: 'has_plan_trip.check',
                condition: 'false'
              }}
              hasErrorCustom
              hasCustom
            />
            <Controller
              shouldUnregister={true}
              control={control}
              name="has_plan_trip.no.accommodation.hotel.phone"
              render={({ field }) => (
                <Input
                  errors={errors}
                  className="col-xl"
                  label="Telefone"
                  inputOptions={{
                    classname: 'd-flex align-items-center'
                  }}
                  // mask={{
                  //   mask: [
                  //     { mask: INPUT_MASK.PHONE },
                  //     { mask: INPUT_MASK.CELL_PHONE }
                  //   ]
                  // }}
                  controlled
                  hasValidationParent={{
                    parent_name: 'has_plan_trip.check',
                    condition: 'false'
                  }}
                  hasErrorCustom
                  hasCustom
                  {...field}
                />
              )}
            />
            <Input
              errors={errors}
              register={register}
              className="col-xl"
              label="E-mail"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              name="has_plan_trip.no.accommodation.hotel.email"
              type="email"
              controlled
              hasErrorCustom
              hasCustom
            />
          </div>

          <div className="row mb-5">
            <Input
              errors={errors}
              register={register}
              className="col-xl"
              label="Endereço Completo"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              name="has_plan_trip.no.accommodation.hotel.address"
              hasValidationParent={{
                parent_name: 'has_plan_trip.check',
                condition: 'false'
              }}
              hasErrorCustom
              // hasCustom
            />
          </div>
        </>
      )}
      {watchField === '3' && (
        <>
          <div className="row mb-5">
            <Input
              errors={errors}
              register={register}
              className="col-xl"
              label="Nome Completo do Anfitrião"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              name="has_plan_trip.no.accommodation.host.name"
              controlled
              hasValidationParent={{
                parent_name: 'has_plan_trip.check',
                condition: 'false'
              }}
              hasErrorCustom
              hasCustom
            />
            <Controller
              shouldUnregister={true}
              control={control}
              name="has_plan_trip.no.accommodation.host.relationship"
              render={({ field }) => (
                <Select
                  {...field}
                  errors={errors}
                  className="col-xl"
                  label="Grau de Parentesco do Anfitrião"
                  options={[
                    {
                      name: 'Filhos',
                      value: 'Filhos'
                    },
                    {
                      name: 'Pais',
                      value: 'Pais'
                    },
                    {
                      name: 'Irmãos',
                      value: 'Irmãos'
                    },
                    {
                      name: 'Cônjuge',
                      value: 'Cônjuge'
                    },
                    {
                      name: 'Amigos',
                      value: 'Amigos'
                    },
                    {
                      name: 'Outros',
                      value: 'Outros'
                    },
                    { name: 'Negócios', value: 'Negócios' },
                    { name: 'Outro Parente', value: 'Outro Parente' }
                  ]}
                  hasValidationParent={{
                    parent_name: 'has_plan_trip.check',
                    condition: 'false'
                  }}
                  hasErrorCustom
                  hasCustom
                />
              )}
            />
          </div>
          <div className="row mb-5">
            <Controller
              shouldUnregister={true}
              control={control}
              name="has_plan_trip.no.accommodation.host.phone"
              render={({ field }) => (
                <Input
                  errors={errors}
                  className="col-xl"
                  label="Telefone do Anfitrião"
                  inputOptions={{
                    classname: 'd-flex align-items-center'
                  }}
                  // mask={{
                  //   mask: [
                  //     { mask: INPUT_MASK.PHONE },
                  //     { mask: INPUT_MASK.CELL_PHONE }
                  //   ]
                  // }}
                  controlled
                  hasValidationParent={{
                    parent_name: 'has_plan_trip.check',
                    condition: 'false'
                  }}
                  hasErrorCustom
                  hasCustom
                  {...field}
                />
              )}
            />
            <Input
              errors={errors}
              register={register}
              className="col-xl"
              label="E-mail do Anfitrião"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              name="has_plan_trip.no.accommodation.host.email"
              type="email"
              controlled
              hasErrorCustom
              hasCustom
            />
          </div>
          <div className="row mb-5">
            <Input
              errors={errors}
              register={register}
              className="col-xl"
              label="Endereço Completo do Anfitrião"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              name="has_plan_trip.no.accommodation.host.address"
              hasValidationParent={{
                parent_name: 'has_plan_trip.check',
                condition: 'false'
              }}
              hasErrorCustom
              // hasCustom
            />
          </div>
        </>
      )}
    </div>
  )
}
const FormHasPlanPositive = () => {
  const {
    register,
    control,
    formState: { errors }
  } = useFormContext()
  return (
    <div className="">
      <div className="row mb-5">
        <Controller
          shouldUnregister={true}
          control={control}
          name="has_plan_trip.yes.intended_date_arrival"
          render={({ field }) => (
            <DatePickerCustom
              register={register}
              popperProps={{ strategy: 'fixed' }}
              locale="ptBR"
              className="col-xl"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              label="Data Prevista De Chegada Nos Eua"
              dateFormat="dd/MM/yyyy"
              placeholderText="DD/MM/AAAA"
              hasCustom
              hasErrorCustom
              hasValidationParent={{
                parent_name: 'has_plan_trip.check'
              }}
              customInput={
                <IMaskInput
                  mask={Date}
                  pattern={'d/m/Y'}
                  format={date => {
                    return moment(date).format(inputMaskFormat)
                  }}
                  parse={value => {
                    return moment(value, inputMaskFormat).toDate()
                  }}
                />
              }
              {...field}
              errors={errors}
            />
          )}
        />
        <Input
          errors={errors}
          register={register}
          className="col-xl"
          label="Número de Vôo de Desembarque nos EUA"
          inputOptions={{
            classname: 'd-flex align-items-center'
          }}
          name="has_plan_trip.yes.landing_flight_number"
          hasValidationParent={{
            parent_name: 'has_plan_trip.check'
          }}
          controlled
          hasErrorCustom
          hasCustom
        />
        <Input
          errors={errors}
          register={register}
          className="col-xl"
          label="Cidade Prevista De Chegada Nos Eua"
          inputOptions={{
            classname: 'd-flex align-items-center'
          }}
          name="has_plan_trip.yes.intended_city"
          hasValidationParent={{
            parent_name: 'has_plan_trip.check'
          }}
          controlled
          hasErrorCustom
          hasCustom
        />
      </div>

      <div className="row mb-5">
        <Controller
          shouldUnregister={true}
          control={control}
          name="has_plan_trip.yes.us_departure_date"
          render={({ field }) => (
            <DatePickerCustom
              register={register}
              popperProps={{ strategy: 'fixed' }}
              locale="ptBR"
              className="col-xl"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              label="Data de Saída dos EUA"
              dateFormat="dd/MM/yyyy"
              placeholderText="DD/MM/AAAA"
              hasCustom
              hasErrorCustom
              hasValidationParent={{
                parent_name: 'has_plan_trip.check'
              }}
              customInput={
                <IMaskInput
                  mask={Date}
                  pattern={'d/m/Y'}
                  format={date => {
                    return moment(date).format(inputMaskFormat)
                  }}
                  parse={value => {
                    return moment(value, inputMaskFormat).toDate()
                  }}
                />
              }
              {...field}
              errors={errors}
            />
          )}
        />
        <Input
          errors={errors}
          register={register}
          className="col-xl"
          label="Cidade de Saída dos EUA"
          inputOptions={{
            classname: 'd-flex align-items-center'
          }}
          name="has_plan_trip.yes.us_departure_city"
          controlled
          hasValidationParent={{
            parent_name: 'has_plan_trip.check'
          }}
          hasErrorCustom
          hasCustom
        />
      </div>
      <div className="row mb-5">
        <Textarea
          register={register}
          // className="col-xl"
          label="Locais a visitar"
          name="has_plan_trip.yes.places_visit"
          hasValidationParent={{
            parent_name: 'has_plan_trip.check'
          }}
          controlled
          hasErrorCustom
          // hasCustom
          errors={errors}
        />
      </div>

      <div className="row mb-5">
        <Controller
          shouldUnregister={true}
          control={control}
          name="has_plan_trip.yes.intended_date_of_arrival_in_the_us"
          render={({ field }) => (
            <DatePickerCustom
              register={register}
              popperProps={{ strategy: 'fixed' }}
              locale="ptBR"
              className="col-xl"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              label="Data Pretendida de chegada nos EUA"
              dateFormat="dd/MM/yyyy"
              placeholderText="DD/MM/AAAA"
              hasCustom
              hasErrorCustom
              hasValidationParent={{
                parent_name: 'has_plan_trip.check'
              }}
              customInput={
                <IMaskInput
                  mask={Date}
                  pattern={'d/m/Y'}
                  format={date => {
                    return moment(date).format(inputMaskFormat)
                  }}
                  parse={value => {
                    return moment(value, inputMaskFormat).toDate()
                  }}
                />
              }
              {...field}
              errors={errors}
            />
          )}
        />
        <Input
          errors={errors}
          register={register}
          className="col-xl"
          label="Tempo de Permanência Pretendido (Especificar se dias, meses ou anos)"
          inputOptions={{
            classname: 'd-flex align-items-center'
          }}
          name="has_plan_trip.yes.intended_length_stay"
          controlled
          hasValidationParent={{
            parent_name: 'has_plan_trip.check'
          }}
          hasErrorCustom
          hasCustom
        />
      </div>

      <div className="mb-5">
        <p className="col-form-label fw-bold fs-6">
          Endereço Pretendido ou que ficará nos EUA. (ATENÇÃO: Caso não saiba ou
          não tenha local que ficará hospedado, entre ao menos a cidade que
          pretende visitar. Colocaremos um hotel qualquer e iremos te orientar
          para a entrevista.)
        </p>
      </div>
      <div className="row mb-5">
        <Input
          errors={errors}
          register={register}
          className="col-xl"
          label="Rua, nº, complemento"
          inputOptions={{
            classname: 'd-flex align-items-center'
          }}
          name="has_plan_trip.yes.intended.address.street"
          controlled
          hasValidationParent={{
            parent_name: 'has_plan_trip.check'
          }}
          hasErrorCustom
          hasCustom
        />
        {/* <Input
          errors={errors}
          register={register}
          className="col-xl"
          label="Número"
          inputOptions={{
            classname: 'd-flex align-items-center'
          }}
          name="has_plan_trip.yes.intended.address.number"
          controlled
          hasValidationParent={{
            parent_name: 'has_plan_trip.check'
          }}
          hasErrorCustom
          hasCustom
        /> */}
        <Input
          errors={errors}
          register={register}
          className="col-xl"
          label="Bairro"
          inputOptions={{
            classname: 'd-flex align-items-center'
          }}
          name="has_plan_trip.yes.intended.address.district"
          controlled
          hasValidationParent={{
            parent_name: 'has_plan_trip.check'
          }}
          hasErrorCustom
          hasCustom
        />
      </div>

      <div className="row mb-5">
        <Input
          errors={errors}
          register={register}
          className="col-xl"
          label="Cidade"
          inputOptions={{
            classname: 'd-flex align-items-center'
          }}
          name="has_plan_trip.yes.intended.address.city"
          controlled
          hasValidationParent={{
            parent_name: 'has_plan_trip.check'
          }}
          hasErrorCustom
          hasCustom
        />
        <Input
          errors={errors}
          register={register}
          className="col-xl"
          label="Estado"
          inputOptions={{
            classname: 'd-flex align-items-center'
          }}
          name="has_plan_trip.yes.intended.address.state"
          controlled
          hasValidationParent={{
            parent_name: 'has_plan_trip.check'
          }}
          hasErrorCustom
          hasCustom
        />
        <Controller
          shouldUnregister={true}
          control={control}
          name="has_plan_trip.yes.intended.address.zip_code"
          render={({ field }) => (
            <Input
              {...field}
              errors={errors}
              register={register}
              className="col-xl"
              label="Código Postal"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              mask={{
                mask: '00000'
              }}
              hasValidationParent={{
                parent_name: 'has_plan_trip.check'
              }}
              hasErrorCustom
              hasCustom
            />
          )}
        />
      </div>
    </div>
  )
}
export const American: React.FC = () => {
  const location = useLocation()
  const [id] = useQueryParam('B3C1836D42ADD8F9B5C41211C401838C', StringParam)
  const { activeLoading, disableLoading, loading } = useLoading()
  const [changeTab, setChangeTab] = useState('next')
  const { addToast } = useToast()
  const history = useHistory()
  const [activeTab, setActiveTab] = useState(0)
  const [optionsValue, setOptionsValue] = useState<OptionsValue>()
  const [birthDate, setBirthDate] = useState<Date>()
  const [dataForm, setDataForm] = useState<any>({
    social_security_number: 'Não se aplica',
    tax_identification_number: 'Não se aplica'
  })
  const [copyDataForm, setCopyDataForm] = useState<any>({
    social_security_number: 'Não se aplica',
    tax_identification_number: 'Não se aplica'
  })
  const titlePageRef = useRef<HTMLDivElement>()
  const [states, setStates] = useState<[]>([])
  const [hasConfirmed, setHasConfirmed] = useState(false)
  const [hasFather, setHasFather] = useState(true)
  const [formations, setFormations] = useState([{ id: 0, isActive: '' }])
  const [othersPhones, setOthersPhones] = useState([{ id: 0, isActive: '' }])
  const [othersEmails, setOthersEmails] = useState([{ id: 0, isActive: '' }])
  const [othersSocial, setOthersSocial] = useState([{ id: 0, isActive: '' }])
  const [othersTraveling, setOthersTraveling] = useState([
    { id: 0, isActive: '' }
  ])
  const [othersHasBeenUSA, setOthersHasBeenUSA] = useState([
    { id: 0, isActive: '' }
  ])
  const [attachments, setAttachments] = useState<
    { id: number; url?: string; name?: string; file?: File }[]
  >([{ id: 1 }])
  const [optionsSimple] = useState([
    { name: 'Sim', value: 'Sim' },
    { name: 'Não', value: 'Não' }
  ])
  const formatingDateFields = useCallback((originDate: any) => {
    const copyData = Object.entries(originDate)
    for (const [key, value] of copyData) {
      if (value) {
        if (moment(value, 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]', true).isValid()) {
          originDate[key] = moment(value).format(inputMaskFormat)
        }
        if (typeof value === 'object') {
          formatingDateFields(value)
        }
      }
    }
  }, [])

  useEffect(() => {
    window.addEventListener('keydown', event => {
      if (event.key === 'Enter') {
        event.preventDefault()
      }
    })
    return () => {
      window.removeEventListener('keydown', event => {
        if (event.key === 'Enter') {
          event.preventDefault()
        }
      })
    }
  }, [])

  useEffect(() => {
    async function loadData() {
      activeLoading()
      if (id) {
        const convertedId = atob(`${id}`)
        try {
          const response = await api.get(
            `/commercial/public/clients/view/${convertedId}`
          )
          const { data } = response
          const locationData = data.values as any
          const copyData: any = { ...locationData }
          setFormations(
            copyData.formations?.length ? copyData.formations : formations
          )
          setOthersPhones(
            copyData.other_phones?.length ? copyData.other_phones : othersPhones
          )
          setOthersEmails(
            copyData.other_emails?.length ? copyData.other_emails : othersEmails
          )
          setOthersSocial(
            copyData.other_socials?.length
              ? copyData.other_socials
              : othersSocial
          )

          setOthersTraveling(
            copyData.other_traveling?.length
              ? copyData.other_traveling
              : othersTraveling
          )
          setOthersHasBeenUSA(
            copyData.other_have_you_been_to_the_usa?.length
              ? copyData.other_have_you_been_to_the_usa
              : othersHasBeenUSA
          )
          setHasFather(!copyData.not_has_father)

          if (copyData.attachments?.length) {
            const copyAttachment: any[] = []
            for (const attachment of copyData.attachments) {
              if (attachment) {
                fetch(attachment.url)
                  .then(response => response.blob())
                  .then(blob => {
                    const file = new File([blob], attachment.name)
                    copyAttachment[attachment.id] = {
                      ...attachment,
                      file
                    }
                  })
              }
            }
            setAttachments(copyAttachment)
          }
          setDataForm({
            id: data.id,
            social_security_number: 'Não se aplica',
            tax_identification_number: 'Não se aplica',
            ...copyData
          })
          setCopyDataForm({
            id: data.id,
            social_security_number: 'Não se aplica',
            tax_identification_number: 'Não se aplica',
            ...copyData
          })
          disableLoading()
        } catch (err) {
          disableLoading()
          addToast({
            type: 'error',
            title: 'Error ao carregar o cliente',
            description:
              'Houve um error ao carregar o cliente, tente novamente mais tarde!'
          })
        }
      }
      disableLoading()
    }
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const { getToggleProps, getCollapseProps } = useCollapse({
    isExpanded: hasFather,
    onExpandStart() {
      setHasFather(true)
    },
    onCollapseEnd() {
      setHasFather(false)
    }
  })

  const onSubmit = useCallback(
    async formValues => {
      activeLoading()
      const data = {
        ...dataForm,
        ...formValues
      }

      formatingDateFields(data)
      const formData = new FormData()
      const attachmentsData =
        data?.attachments?.map(
          // eslint-disable-next-line array-callback-return
          (dataItem: any, index: number) => {
            if (dataItem) {
              const findAttachment = attachments.find(
                attachment => index === attachment.id
              )
              if (findAttachment) {
                if (findAttachment.file) {
                  return findAttachment
                }
                return dataItem
              }
            }
          }
        ) || []
      for (const attachment of attachmentsData) {
        if (attachment) {
          if (attachment[0]) {
            formData.append('attachments', attachment[0])
          }
          if (attachment.file) {
            formData.append('attachments', attachment.file)
          }
        }
      }
      delete data.attachments

      formData.append('values', JSON.stringify(data))
      formData.append('name', data.fullname)
      formData.append('email', data.email)
      formData.append('cpf', data.cpf)
      formData.append('type', 'american')
      if (dataForm.id) {
        try {
          await api.put(
            `/commercial/public/clients/update/${dataForm.id}`,
            formData
          )
          disableLoading()
          addToast({
            type: 'success',
            title: 'Registro alterado',
            description: 'Registro alterado com sucesso'
          })
          history.go(0)
        } catch (error) {
          addToast({
            type: 'error',
            title: 'Erro ao adicionar registro',
            description:
              'Ocorreu um erro ao adicionar o registro, por favor, tente novamente.'
          })
          disableLoading()
        }
      } else {
        try {
          await api.post('/commercial/public/clients', formData)
          disableLoading()
          addToast({
            type: 'success',
            title: 'Registro criado',
            description: 'Registro criado com sucesso'
          })
          history.go(0)
        } catch (error) {
          addToast({
            type: 'error',
            title: 'Erro ao adicionar registro',
            description:
              'Ocorreu um erro ao adicionar o registro, por favor, tente novamente.'
          })
          disableLoading()
        }
      }
    },
    [
      activeLoading,
      addToast,
      attachments,
      dataForm,
      disableLoading,
      formatingDateFields,
      history
    ]
  )

  const handleChangePage = useCallback((type: string) => {
    if (type === 'next') {
      setChangeTab(() => {
        return 'next'
      })
    } else {
      setChangeTab(() => {
        return 'previous'
      })
    }
  }, [])

  const handleNextForm = useCallback(
    async (data: any) => {
      const formData = {
        ...JSON.parse(JSON.stringify(copyDataForm)),
        ...JSON.parse(JSON.stringify(dataForm)),
        ...JSON.parse(JSON.stringify(data))
      }

      if (data?.attachments) {
        const attachmentsData = data?.attachments
          ?.map(
            // eslint-disable-next-line array-callback-return
            (dataItem: any, index: number) => {
              if (dataItem) {
                const findAttachment = attachments.find(
                  attachment => index === attachment?.id
                )
                if (findAttachment) {
                  if (findAttachment.file) {
                    return findAttachment
                  }
                  return {
                    id: index,
                    name: dataItem[0]?.name,
                    file: dataItem[0]
                  }
                }
              }
            }
          )
          .filter((item: any) => item)
        setAttachments(attachmentsData)
      }
      setDataForm(formData)
      if (id) {
        setCopyDataForm({
          ...copyDataForm,
          ...formData
        })
      }
      if (changeTab === 'previous') {
        setActiveTab(activeTab - 1)
        handleChangePage('next')
      } else {
        setActiveTab(activeTab + 1)
      }
      if (titlePageRef?.current) {
        titlePageRef?.current?.scrollIntoView?.({
          behavior: 'smooth'
        })
      }
    },
    [
      activeTab,
      attachments,
      changeTab,
      copyDataForm,
      dataForm,
      handleChangePage,
      id
    ]
  )
  const loadOptions = useCallback(async () => {
    activeLoading()
    try {
      const { data } = await api.get('/options')
      setOptionsValue({
        ...data
      })
      setStates(
        data.states
          .sort((currentItem: { UF: string }, nextItem: { UF: string }) =>
            currentItem.UF.localeCompare(nextItem.UF)
          )
          .map(
            (item: { UF: string }) => item && { name: item.UF, value: item.UF }
          )
      )
      disableLoading()
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao buscar indice',
        description:
          'Ocorreu um erro ao buscar o indice, por favor, tente novamente.'
      })
      disableLoading()
    }
  }, [activeLoading, addToast, disableLoading])
  useEffect(() => {
    loadOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleRemoveAttachments = useCallback(
    (id: number) => {
      const biggerId = Math.max(...attachments.map(attachment => attachment.id))

      const filterAttachments = attachments.map(attachment =>
        attachment && attachment.id === id
          ? {
              id: (biggerId || 1) + 1,
              url: null,
              name: null
            }
          : { ...attachment }
      )
      setAttachments(filterAttachments)
    },
    [attachments]
  )

  const handleDownloadAttachments = useCallback(
    (attachment: any) => {
      activeLoading()
      fetch(attachment.url)
        .then(response => response.blob())
        .then(blob => {
          disableLoading()
          const blobURL = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = blobURL
          a.download = attachment.name
          document.body.appendChild(a)
          a.click()
        })
    },
    [activeLoading, disableLoading]
  )
  return (
    <div ref={titlePageRef}>
      <Loading isActive={loading} />
      <div className="row gy-5 g-xl-8">
        <ContainerHeader>
          <WrapperContent>
            <ContainerLogo>
              <a href="/">
                <img
                  alt="Logo"
                  src={logo}
                  className="w-150px logo"
                  style={{ objectFit: 'cover' }}
                />
              </a>
            </ContainerLogo>
          </WrapperContent>
        </ContainerHeader>
      </div>
      <ContainerWrapperContent>
        <div className="gy-5 g-xl-8 ps-2">
          <h1>Preenchimento do formulário - Visto Americano</h1>
          <div className="card px-10 py-3 mt-3">
            <Tabs
              classname="justify-content-between"
              setActiveTab={setActiveTab}
              activeTab={activeTab}
            >
              <Tab title="1- Dados Pessoais e viagem" key={0}>
                <div className="form mt-5">
                  <Form
                    className="form"
                    onSubmit={handleNextForm}
                    defaultValues={dataForm}
                  >
                    <div className="row mb-5">
                      <p className="col-form-label fw-bold fs-6">
                        Seu processo de solicitação de visto é?
                      </p>
                      <div className="d-xl-flex ">
                        <Checkbox
                          className="col-sm col-xl mb-2"
                          label="Visto Normal"
                          inputOptions={{
                            classname: 'd-flex align-items-center'
                          }}
                          name="visa_application_process"
                          type="radio"
                          value="normal_visa"
                          hasCustom
                          hasErrorCustom
                          rules={{ required: changeTab !== 'previous' }}
                        />
                        <Checkbox
                          className="col-sm  col-xl"
                          label="Renovação de visto"
                          inputOptions={{
                            classname: 'd-flex align-items-center'
                          }}
                          name="visa_application_process"
                          type="radio"
                          hasCustom
                          rules={{ required: changeTab !== 'previous' }}
                          value="visa_renewal"
                        />
                        <Checkbox
                          className="col-sm  col-xl"
                          label="Visto para Maiores de 79 anos"
                          inputOptions={{
                            classname: 'd-flex align-items-center'
                          }}
                          name="visa_application_process"
                          type="radio"
                          hasCustom
                          rules={{ required: changeTab !== 'previous' }}
                          value="visa_renewal"
                        />
                        <Checkbox
                          className="col-sm  col-xl"
                          label="Visto para menores de 14 anos"
                          inputOptions={{
                            classname: 'd-flex align-items-center'
                          }}
                          name="visa_application_process"
                          type="radio"
                          hasCustom
                          rules={{ required: changeTab !== 'previous' }}
                          value="visa_renewal"
                        />
                      </div>
                    </div>

                    <div className="row mb-5">
                      <Select
                        className="col-xl"
                        label="Escolha um local onde você estará aplicando para o visto"
                        name="place_application"
                        hasCustom
                        rules={{ required: changeTab !== 'previous' }}
                        options={PLACES_APPLICATION}
                        blank
                        controlled
                      />
                      <Select
                        className="col-xl"
                        label="Escolha um local (CASV) onde você estará realizando a Biometria para o visto"
                        name="location_biometrics"
                        hasCustom
                        rules={{ required: changeTab !== 'previous' }}
                        options={PLACES_APPLICATION}
                        blank
                        controlled
                      />
                    </div>

                    <div className="separator border-2 my-10"></div>

                    <div className="row mb-5">
                      <FormName
                        changeTab={changeTab}
                        first={{
                          name: 'name_passport',
                          rules: { required: changeTab !== 'previous' }
                        }}
                        second={{
                          name: 'lastname_passport',
                          rules: { required: changeTab !== 'previous' }
                        }}
                      />
                      <div className="row">
                        <Input
                          className="col-xl"
                          label="Seu nome completo sem abreviações"
                          inputOptions={{
                            classname: 'd-flex align-items-center'
                          }}
                          name="fullname"
                          hasCustom
                          hasErrorCustom
                          rules={{ required: changeTab !== 'previous' }}
                        />
                      </div>
                    </div>

                    <CheckboxWithForm
                      changeTab={changeTab}
                      label="Você já teve algum outro nome?"
                      positive={{
                        name: 'has_other_name'
                      }}
                      negative={{
                        name: 'has_other_name',
                        tooltip: {
                          container: {
                            classname: 'ms-2'
                          },
                          classname: 'mw-30px',
                          message: `<p style="max-width: 300px">
                            Ex: Nome de solteira, religioso, profissional, pseudônimo, etc.
                        </p>`
                        }
                      }}
                    >
                      <FormName
                        changeTab={changeTab}
                        first={{
                          name: 'other_name',
                          label: 'Nome Completo',
                          tooltip: undefined,
                          hasValidationParent: {
                            parent_name: 'has_other_name'
                          }
                        }}
                        // second={{
                        //   name: 'other_lastname',
                        //   hasValidationParent: {
                        //     parent_name: 'has_other_name'
                        //   }
                        // }}
                      />
                    </CheckboxWithForm>
                    <div className="separator border-2 my-10"></div>

                    <div className="row mb-5">
                      <Select
                        className="col-xl"
                        label="Sexo"
                        name="gender"
                        hasCustom
                        rules={{ required: changeTab !== 'previous' }}
                        options={optionsValue?.gender || []}
                        blank
                        controlled
                      />
                      <Select
                        className="col-xl"
                        label="Estado Civil"
                        name="spouse.marital_status"
                        hasCustom
                        rules={{ required: changeTab !== 'previous' }}
                        options={[
                          {
                            name: 'Casado',
                            value: MARRIED_FIELD_NAME
                          },
                          {
                            name: 'Solteiro',
                            value: SINGLE_FIELD_NAME
                          },
                          {
                            name: 'União Estável',
                            value: 'stable_union_with_contract'
                          },
                          {
                            name: 'União Civil Parceria Doméstica',
                            value: 'stable_union_without_contract'
                          },
                          {
                            name: 'Viúvo',
                            value: WIDOWED_FIELD_NAME
                          },
                          {
                            name: 'Divorciado',
                            value: DIVORCED_FIELD_NAME
                          },
                          {
                            name: 'Separado legalmente',
                            value: LEGALLY_SEPARATED_FIELD_NAME
                          }
                        ]}
                        controlled
                        blank
                        defaultValue=""
                      />
                      <DatePickerCustom
                        popperProps={{ strategy: 'fixed' }}
                        locale="ptBR"
                        name="birth_date"
                        className="col-xl"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        label="Data de nascimento"
                        dateFormat="dd/MM/yyyy"
                        placeholderText="DD/MM/AAAA"
                        hasCustom
                        hasErrorCustom
                        rules={{ required: true }}
                        // maxDate={maxDate}
                        customInput={
                          <IMaskInput
                            mask={Date}
                            pattern={'d/m/Y'}
                            format={date => {
                              return moment(date).format(inputMaskFormat)
                            }}
                            parse={value => {
                              return moment(value, inputMaskFormat).toDate()
                            }}
                            // validate={(date: string) => {
                            //   if (date.length === 10) {
                            //     if (
                            //       moment(date, inputMaskFormat).isAfter(
                            //         moment()
                            //       )
                            //     ) {
                            //       return false
                            //     }
                            //     const age = moment().diff(
                            //       moment(date, inputMaskFormat),
                            //       'year'
                            //     )
                            //     if (age < 18) {
                            //       return false
                            //     }
                            //   }
                            //   return true
                            // }}
                          />
                        }
                        controlled
                      />
                    </div>

                    <div className="row mb-5">
                      <h3>Endereço de nascimento</h3>
                    </div>

                    <div className="row mb-5">
                      <Input
                        className="col-xl"
                        label="Cidade de nascimento"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        name="address.city"
                        rules={{ required: changeTab !== 'previous' }}
                        hasErrorCustom
                        hasCustom
                      />
                      <Select
                        className="col-xl"
                        label="Estado de nascimento"
                        labelOptions={{ classname: 'col-xl' }}
                        selectOptions={{
                          classname: 'col-xl'
                        }}
                        name="address.state"
                        rules={{
                          required: changeTab !== 'previous'
                        }}
                        options={states}
                        controlled
                        blank
                      />
                      <Input
                        className="col-xl"
                        label="País de nascimento"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        name="address_birth.country"
                        defaultValue="BRASIL"
                        rules={{ required: changeTab !== 'previous' }}
                        hasCustom
                        hasErrorCustom
                      />
                    </div>
                    {/* <div className="separator border-2 my-6"></div>

                    <div className="separator border-2 my-10"></div>
                    <div className="row mb-5">

                    </div> */}

                    <div className="row mb-5 d-flex">
                      <CheckboxWithForm
                        changeTab={changeTab}
                        label="Possui outra nacionalidade além da indicada acima?"
                        positive={{
                          name: 'has_other_nationality'
                        }}
                        negative={{
                          name: 'has_other_nationality'
                        }}
                      >
                        <Input
                          className="col-xl"
                          label="Informe qual a nacionalidade, o número do passaporte e a validade do mesmo."
                          inputOptions={{
                            classname: 'd-flex align-items-center'
                          }}
                          name="other_nationality"
                          hasValidationParent={{
                            parent_name: 'has_other_nationality'
                          }}
                          hasCustom
                          hasErrorCustom
                        />
                      </CheckboxWithForm>
                    </div>
                    <div className="row mb-5 d-flex">
                      <CheckboxWithForm
                        changeTab={changeTab}
                        label="É Residente permanente de um país diferente do indicado acima?"
                        positive={{
                          name: 'has_resident_permanent'
                        }}
                        negative={{
                          name: 'has_resident_permanent'
                        }}
                      >
                        <Input
                          className="col-xl"
                          label="Informe qual o país"
                          inputOptions={{
                            classname: 'd-flex align-items-center'
                          }}
                          name="resident_permanent"
                          hasValidationParent={{
                            parent_name: 'has_resident_permanent'
                          }}
                          hasCustom
                          hasErrorCustom
                        />
                      </CheckboxWithForm>
                    </div>

                    <div className="row mb-5">
                      <Input
                        className="col-xl"
                        label="Número do CPF"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        name="cpf"
                        rules={{ required: changeTab !== 'previous' }}
                        tooltip={{
                          container: {
                            classname: 'ms-2'
                          },
                          classname: 'mw-30px',
                          message: `<p style="max-width: 300px">
                          Caso não possua, informe 'não possuo'. Se for menor de idade, informe o do pai ou da mãe.
                            </p>`
                        }}
                        hasCustom
                        hasErrorCustom
                      />
                      <Input
                        className="col-xl"
                        label="Número do Social Security U.S.A"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        controlled
                        name="social_security_number"
                        tooltip={{
                          container: {
                            classname: 'ms-2'
                          },
                          classname: 'mw-30px',
                          message: `<p style="max-width: 300px">
                          Informe apenas em números. Caso não possua, informe 'não se aplica'
                            </p>`
                        }}
                        rules={{ required: changeTab !== 'previous' }}
                        hasCustom
                        hasErrorCustom
                      />
                      <Input
                        className="col-xl"
                        label="Número da Identificação Fiscal U.S.A"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        controlled
                        name="tax_identification_number"
                        tooltip={{
                          container: {
                            classname: 'ms-2'
                          },
                          classname: 'mw-30px',
                          message: `<p style="max-width: 300px">
                        Caso não possua, informe não se aplica
                            </p>`
                        }}
                        rules={{ required: changeTab !== 'previous' }}
                        hasCustom
                        hasErrorCustom
                      />
                    </div>

                    <div className="row mb-5">
                      <Select
                        className="col-xl"
                        label="Informe o motivo de sua viagem"
                        options={optionsReasonTravel}
                        labelOptions={{ classname: 'col-xl' }}
                        selectOptions={{
                          classname: 'col-xl'
                        }}
                        name="reason_travel"
                        // mask={{
                        //   mask: '(00) 0000-0000'
                        // }}
                        defaultValue=""
                        blank
                        controlled
                        rules={{ required: changeTab !== 'previous' }}
                        // hasCustom
                      />
                    </div>

                    <div className="row mb-5">
                      <CheckboxWithConditionForm
                        changeTab={changeTab}
                        label="Você tem viagem comprada para os Estados Undios?"
                        positive={{
                          name: 'has_plan_trip.check'
                        }}
                        negative={{
                          name: 'has_plan_trip.check'
                        }}
                        positiveChildren={FormHasPlanPositive}
                        negativeChildren={FormHasPlanNegative}
                      />
                    </div>
                    <div className="separator border-2 my-10"></div>

                    <div className="row mb-5">
                      <Select
                        className="col-xl"
                        label="Quem vai pagar sua viagem?"
                        name="who_will_pay_for_your_trip.check"
                        labelOptions={{ classname: 'col-xl' }}
                        selectOptions={{
                          classname: 'col-xl'
                        }}
                        rules={{ required: changeTab !== 'previous' }}
                        options={optionsWhoWillPayForYourTrip}
                        blank
                        defaultValue=""
                        controlled
                      />
                    </div>

                    <FormWillPayYouTrip changeTab={changeTab} states={states} />
                    <div className="separator border-2 my-10"></div>
                    <div>
                      {othersTraveling.map(
                        (formation, index) =>
                          formation && (
                            <>
                              <FormMultpleAddition
                                changeTab={changeTab}
                                key={`${Math.random() * 9999}`}
                                limit={6}
                                hasCustomFields={[
                                  {
                                    label: 'Nome',
                                    name: `other_traveling.${index}.name`
                                  },
                                  {
                                    label: 'Grau de Parentesco',
                                    name: `other_traveling.${index}.relationship`,
                                    type: 'select',
                                    options: [
                                      ...(optionsValue?.parentage || []),
                                      { name: 'Negócios', value: 'Negócios' },
                                      {
                                        name: 'Outro Parente',
                                        value: 'Outro Parente'
                                      }
                                    ]
                                  }
                                ]}
                                negative={{
                                  name: `other_traveling.${index + 1}.isActive`
                                }}
                                positive={{
                                  name: `other_traveling.${index + 1}.isActive`
                                }}
                                hasHeader={
                                  index === 0
                                    ? {
                                        negative: {
                                          name: `other_traveling.${index}.isActive`
                                        },
                                        positive: {
                                          name: `other_traveling.${index}.isActive`
                                        },
                                        label:
                                          'Existem outras pessoas viajando com você?'
                                      }
                                    : undefined
                                }
                                label="Existem outras pessoas viajando com você?"
                                data={othersTraveling}
                                index={index}
                                setData={setOthersTraveling}
                                parent="child"
                              />
                            </>
                          )
                      )}
                    </div>
                    <div className="separator border-2 my-10"></div>
                    <div className="row mb-5 d-flex">
                      <CheckboxWithForm
                        changeTab={changeTab}
                        label="Você está viajando com um grupo ou Organização?"
                        positive={{
                          name: 'traveling_with_group'
                        }}
                        negative={{
                          name: 'traveling_with_group'
                        }}
                      >
                        <div className="mb-5">
                          <Input
                            className="col-xl"
                            label="Informe o nome do grupo ou Organização"
                            inputOptions={{
                              classname: 'd-flex align-items-center'
                            }}
                            name="traveling_with_group_name"
                            hasCustom
                            hasErrorCustom
                            rules={{
                              required: changeTab !== 'previous'
                            }}
                            hasValidationParent={{
                              parent_name: 'traveling_with_group'
                            }}
                          />
                        </div>
                      </CheckboxWithForm>
                    </div>

                    <div className="separator border-2 my-10"></div>
                    <div>
                      {othersHasBeenUSA.map(
                        (formation, index) =>
                          formation && (
                            <>
                              <FormMultpleAddition
                                limit={4}
                                changeTab={changeTab}
                                key={`${Math.random() * 9999}`}
                                hasCustomFields={[
                                  {
                                    label: 'Data de Entrada',
                                    name: `other_have_you_been_to_the_usa.${index}.date_entry`,
                                    type: 'date',
                                    maxDate: new Date()
                                  },
                                  {
                                    label: 'Tempo que ficou nesta visita(dias)',
                                    name: `other_have_you_been_to_the_usa.${index}.time`,
                                    mask: { mask: '000' }
                                  }
                                ]}
                                negative={{
                                  name: `other_have_you_been_to_the_usa.${
                                    index + 1
                                  }.isActive`
                                }}
                                positive={{
                                  name: `other_have_you_been_to_the_usa.${
                                    index + 1
                                  }.isActive`
                                }}
                                hasHeader={
                                  index === 0
                                    ? {
                                        negative: {
                                          name: `other_have_you_been_to_the_usa.${index}.isActive`
                                        },
                                        positive: {
                                          name: `other_have_you_been_to_the_usa.${index}.isActive`
                                        },
                                        label: 'Você já esteve nos EUA?'
                                      }
                                    : undefined
                                }
                                label="Já fez outras visitas nos EUA?"
                                data={othersHasBeenUSA}
                                index={index}
                                setData={setOthersHasBeenUSA}
                                parent="child"
                              />
                            </>
                          )
                      )}
                    </div>
                    <div className="separator border-2 my-10"></div>
                    <div className="row mb-5 d-flex">
                      <CheckboxWithForm
                        changeTab={changeTab}
                        label="Você já teve um visto americano emitido?"
                        positive={{
                          name: 'american_visa_issued'
                        }}
                        negative={{
                          name: 'american_visa_issued'
                        }}
                      >
                        <div className="row mb-5">
                          <Input
                            className="col-xl"
                            label="Cidade onde foi emitido"
                            inputOptions={{
                              classname: 'd-flex align-items-center'
                            }}
                            name="city_last_visa_issued"
                            hasCustom
                            hasErrorCustom
                            rules={{
                              required: changeTab !== 'previous'
                            }}
                            hasValidationParent={{
                              parent_name: 'american_visa_issued'
                            }}
                          />
                          <Input
                            className="col-xl"
                            label="Número do último visto emitido"
                            inputOptions={{
                              classname: 'd-flex align-items-center'
                            }}
                            name="number_last_visa_issued"
                            hasCustom
                            hasErrorCustom
                            rules={{
                              required: changeTab !== 'previous'
                            }}
                            hasValidationParent={{
                              parent_name: 'american_visa_issued'
                            }}
                            tooltip={{
                              container: {
                                classname: 'ms-2'
                              },
                              classname: 'mw-30px',
                              message: `<p style="max-width: 300px">
                              O número do visto está escrito abaixo da data de vencimento do mesmo. São 8 dígitos em vermelho.
                                </p>`
                            }}
                          />
                          <DatePickerCustom
                            popperProps={{ strategy: 'fixed' }}
                            locale="ptBR"
                            name="issued_date_last_visa_issued"
                            className="col-xl-3"
                            inputOptions={{
                              classname: 'd-flex align-items-center'
                            }}
                            label="Data de emissão"
                            dateFormat="dd/MM/yyyy"
                            placeholderText="DD/MM/AAAA"
                            hasCustom
                            hasErrorCustom
                            hasValidationParent={{
                              parent_name: 'american_visa_issued_lost_visa'
                            }}
                            maxDate={new Date()}
                            customInput={
                              <IMaskInput
                                mask={Date}
                                pattern={'d/m/Y'}
                                format={date => {
                                  return moment(date).format(inputMaskFormat)
                                }}
                                parse={value => {
                                  return moment(value, inputMaskFormat).toDate()
                                }}
                              />
                            }
                            controlled
                          />
                          <DatePickerCustom
                            popperProps={{ strategy: 'fixed' }}
                            locale="ptBR"
                            name="due_date_last_visa_issued"
                            className="col-xl-3"
                            inputOptions={{
                              classname: 'd-flex align-items-center'
                            }}
                            label="Data de vencimento"
                            dateFormat="dd/MM/yyyy"
                            placeholderText="DD/MM/AAAA"
                            hasCustom
                            hasErrorCustom
                            hasValidationParent={{
                              parent_name: 'american_visa_issued_lost_visa'
                            }}
                            customInput={
                              <IMaskInput
                                mask={Date}
                                pattern={'d/m/Y'}
                                format={date => {
                                  return moment(date).format(inputMaskFormat)
                                }}
                                parse={value => {
                                  return moment(value, inputMaskFormat).toDate()
                                }}
                              />
                            }
                            controlled
                          />
                        </div>
                        <CheckboxSimple
                          changeTab={changeTab}
                          label="Você está aplicando para o mesmo tipo de visto?"
                          positive={{
                            name: 'american_visa_issued_same_type',
                            hasValidationParent: {
                              parent_name: 'american_visa_issued'
                            }
                          }}
                          negative={{
                            name: 'american_visa_issued_same_type',
                            hasValidationParent: {
                              parent_name: 'american_visa_issued'
                            }
                          }}
                        />
                        <CheckboxSimple
                          changeTab={changeTab}
                          label="Você está aplicando no mesmo país onde seu visto anterior foi emitido, e este país é a sua principal residência?"
                          positive={{
                            name: 'american_visa_issued_same_country',
                            hasValidationParent: {
                              parent_name: 'american_visa_issued'
                            }
                          }}
                          negative={{
                            name: 'american_visa_issued_same_country',
                            hasValidationParent: {
                              parent_name: 'american_visa_issued'
                            }
                          }}
                        />
                        <CheckboxSimple
                          changeTab={changeTab}
                          label="Você já forneceu as digitais de todos os seus dedos quando da obtenção do último visto EUA recebido?"
                          positive={{
                            name: 'american_visa_issued_data',
                            hasValidationParent: {
                              parent_name: 'american_visa_issued'
                            }
                          }}
                          negative={{
                            name: 'american_visa_issued_data',
                            hasValidationParent: {
                              parent_name: 'american_visa_issued'
                            }
                          }}
                        />
                        <CheckboxWithForm
                          changeTab={changeTab}
                          label="Você já teve algum visto EUA roubado ou perdido/extraviado?"
                          positive={{
                            name: 'american_visa_issued_lost_visa',
                            hasValidationParent: {
                              parent_name: 'american_visa_issued'
                            }
                          }}
                          negative={{
                            name: 'american_visa_issued_lost_visa',
                            hasValidationParent: {
                              parent_name: 'american_visa_issued'
                            }
                          }}
                        >
                          <div className="row mb-5">
                            <DatePickerCustom
                              popperProps={{ strategy: 'fixed' }}
                              locale="ptBR"
                              name="american_visa_issued_lost_visa_year"
                              className="col-xl-3"
                              inputOptions={{
                                classname: 'd-flex align-items-center'
                              }}
                              label="Ano de roubo/extravio"
                              dateFormat="yyyy"
                              placeholderText="AAAA"
                              hasCustom
                              hasErrorCustom
                              hasValidationParent={{
                                parent_name: 'american_visa_issued_lost_visa'
                              }}
                              maxDate={new Date()}
                              showYearPicker
                              customInput={
                                <IMaskInput
                                  mask={Date}
                                  pattern={'YY'}
                                  format={date => {
                                    return moment(date).format(inputMaskFormat)
                                  }}
                                  parse={value => {
                                    return moment(
                                      value,
                                      inputMaskFormat
                                    ).toDate()
                                  }}
                                />
                              }
                              // onChange={date => {
                              //   setBirthDate(date)
                              // }}
                              controlled
                            />
                            {/* <DatePickerCustom
                              popperProps={{ strategy: 'fixed' }}
                              locale="ptBR"
                              name="american_visa_issued_lost_validate"
                              className="col-xl"
                              inputOptions={{
                                classname: 'd-flex align-items-center'
                              }}
                              label="Data de validade"
                              dateFormat="dd/MM/yyyy"
                              placeholderText="DD/MM/AAAA"
                              hasCustom
                              hasErrorCustom
                              hasValidationParent={{
                                parent_name: 'american_visa_issued_lost_visa'
                              }}
                              customInput={
                                <IMaskInput
                                  mask={Date}
                                  pattern={'d/m/Y'}
                                  format={date => {
                                    return moment(date).format(inputMaskFormat)
                                  }}
                                  parse={value => {
                                    return moment(
                                      value,
                                      inputMaskFormat
                                    ).toDate()
                                  }}
                                />
                              }
                              controlled
                            /> */}
                          </div>
                          <div className="row mb-5">
                            <Textarea
                              label="Explique"
                              name="american_visa_issued_lost_explain"
                              style={{ minHeight: 100 }}
                              hasValidationParent={{
                                parent_name: 'american_visa_issued_lost_visa'
                              }}
                              hasCustom
                              hasErrorCustom
                            />
                          </div>
                        </CheckboxWithForm>
                        <CheckboxWithForm
                          changeTab={changeTab}
                          label="Você já teve algum visto EUA cancelado ou revogado? "
                          positive={{
                            name: 'american_visa_issued_visa_canceled',
                            hasValidationParent: {
                              parent_name: 'american_visa_issued'
                            }
                          }}
                          negative={{
                            name: 'american_visa_issued_visa_canceled',
                            hasValidationParent: {
                              parent_name: 'american_visa_issued'
                            }
                          }}
                        >
                          <div className="mb-5">
                            <Textarea
                              label="Explique"
                              name="american_visa_issued_visa_canceled_explain"
                              style={{ minHeight: 100 }}
                              hasValidationParent={{
                                parent_name:
                                  'american_visa_issued_visa_canceled'
                              }}
                              hasCustom
                              hasErrorCustom
                            />
                          </div>
                        </CheckboxWithForm>
                      </CheckboxWithForm>
                    </div>

                    <div className="row mb-5">
                      <CheckboxWithForm
                        changeTab={changeTab}
                        label="Alguma vez lhe foi recusado um pedido de visto dos EUA, ou lhe foi recusada a admissão nos EUA, ou lhe foi retirado um cartão de admissão no ponto de entrada?"
                        positive={{
                          name: 'american_visa_issued_refused_visa_application'
                        }}
                        negative={{
                          name: 'american_visa_issued_refused_visa_application'
                        }}
                      >
                        <div className="mb-5">
                          <Textarea
                            label="Explique"
                            name="american_visa_issued_refused_visa_application_explain"
                            style={{ minHeight: 100 }}
                            hasValidationParent={{
                              parent_name:
                                'american_visa_issued_refused_visa_application'
                            }}
                            hasCustom
                            hasErrorCustom
                          />
                        </div>
                      </CheckboxWithForm>
                    </div>
                    <div className="row mb-5">
                      <CheckboxWithForm
                        changeTab={changeTab}
                        label="Alguém já fez uma petição de imigrante em seu nome com os Serviços
                        de Cidadania e Imigração dos Estados Unidos?"
                        positive={{
                          name: 'has_anyone_ever_filed_an_immigrant_petition_on_your_behalf'
                        }}
                        negative={{
                          name: 'has_anyone_ever_filed_an_immigrant_petition_on_your_behalf'
                        }}
                      >
                        <div className="mb-5">
                          <Textarea
                            label="Explique"
                            name="has_anyone_ever_filed_an_immigrant_petition_on_your_behalf_explain"
                            style={{ minHeight: 100 }}
                            hasValidationParent={{
                              parent_name:
                                'has_anyone_ever_filed_an_immigrant_petition_on_your_behalf'
                            }}
                            hasCustom
                            hasErrorCustom
                          />
                        </div>
                      </CheckboxWithForm>
                    </div>
                    <div className="separator border-2 my-10"></div>
                    <div className="row mb-5">
                      <h3>Endereço do aplicante</h3>
                      <Input
                        className="col-xl-2"
                        label="CEP"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        hasErrorCustom
                        name="applicant.address.zip_code"
                        mask={{
                          mask: INPUT_MASK.ZIP_CODE,
                          type: 'zipCode',
                          prefixInputsData: 'applicant.address.'
                        }}
                        controlled
                        rules={{
                          required: changeTab !== 'previous',
                          minLength: { value: 8, message: 'Cep Inválido' }
                        }}
                        maxLength={10}
                        hasCustom
                      />
                      <Input
                        className="col-xl"
                        label="Rua, nº, complemento"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        name="applicant.address.street"
                        rules={{ required: changeTab !== 'previous' }}
                        hasErrorCustom
                        hasCustom
                      />
                      {/* <Input
                        className="col-xl-2"
                        label="Número"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        name="applicant.address.number"
                        rules={{ required: changeTab !== 'previous' }}
                        hasErrorCustom
                        hasCustom
                      /> */}
                    </div>
                    <div className="row mb-5">
                      <Input
                        className="col-xl"
                        label="Bairro"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        name="applicant.address.district"
                        rules={{ required: changeTab !== 'previous' }}
                        hasErrorCustom
                        hasCustom
                      />
                      <Input
                        className="col-xl"
                        label="Cidade"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        name="applicant.address.city"
                        rules={{ required: changeTab !== 'previous' }}
                        hasErrorCustom
                        hasCustom
                      />
                      <Select
                        className="col-xl"
                        label="Estado"
                        labelOptions={{ classname: 'col-xl' }}
                        selectOptions={{
                          classname: 'col-xl'
                        }}
                        name="applicant.address.state"
                        rules={{
                          required: changeTab !== 'previous'
                        }}
                        options={states}
                        controlled
                        blank
                      />
                    </div>

                    <div className="row mb-5">
                      <Input
                        className="col-xl-2"
                        label="Telefone residencial com DDD"
                        inputOptions={{
                          classname: 'd-flex align-items-center',
                          styles: { paddingRight: 0 }
                        }}
                        name="home_phone"
                        tooltip={{
                          container: {
                            classname: 'ms-2'
                          },
                          classname: 'mw-30px',
                          message: `<p style="max-width: 300px">
                          Caso não possua, informe '0000'
                            </p>`
                        }}
                        rules={{ required: changeTab !== 'previous' }}
                        hasErrorCustom
                        hasCustom
                      />
                      <Input
                        className="col-xl-2"
                        label="Telefone comercial com DDD"
                        inputOptions={{
                          classname: 'd-flex align-items-center',
                          styles: { paddingRight: 0 }
                        }}
                        name="commercial_phone"
                        tooltip={{
                          container: {
                            classname: 'ms-2'
                          },
                          classname: 'mw-30px',
                          message: `<p style="max-width: 300px">
                          Se for estudante, entre com o fone da escola. Caso não possua, entre com '0000'
                            </p>`
                        }}
                        rules={{ required: changeTab !== 'previous' }}
                        hasErrorCustom
                        hasCustom
                      />
                      <Input
                        className="col-xl-2"
                        label="Telefone celular com DDD"
                        tooltip={{
                          container: {
                            classname: 'ms-2'
                          },
                          classname: 'mw-30px',
                          message: `<p style="max-width: 300px">
                          Caso não possua, informe '0000'
                            </p>`
                        }}
                        inputOptions={{
                          classname: 'd-flex align-items-center',
                          styles: { paddingRight: 0 }
                        }}
                        name="cell_phone"
                        rules={{ required: changeTab !== 'previous' }}
                        hasErrorCustom
                        hasCustom
                      />
                      <Input
                        className="col-xl"
                        label="Email"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        type="email"
                        name="email"
                        rules={{ required: changeTab !== 'previous' }}
                        hasErrorCustom
                        hasCustom
                      />
                    </div>

                    <div className="separator border-2 my-10"></div>
                    <div>
                      {othersPhones.map(
                        (formation, index) =>
                          formation && (
                            <>
                              <FormMultpleAddition
                                changeTab={changeTab}
                                key={`${Math.random() * 9999}`}
                                inputData={{
                                  name: `other_phones.${index}.phone`
                                }}
                                negative={{
                                  name: `other_phones.${index + 1}.isActive`
                                }}
                                positive={{
                                  name: `other_phones.${index + 1}.isActive`
                                }}
                                limit={3}
                                hasHeader={
                                  index === 0
                                    ? {
                                        negative: {
                                          name: `other_phones.${index}.isActive`
                                        },
                                        positive: {
                                          name: `other_phones.${index}.isActive`
                                        },
                                        label:
                                          'Tem outros números de telefone utilizados nos últimos 5 anos, diferentes dos atuais?'
                                      }
                                    : undefined
                                }
                                label="Tem outros números de telefones utilizados nos últimos 5 anos, diferentes dos atuais?"
                                data={othersPhones}
                                index={index}
                                setData={setOthersPhones}
                                parent="child"
                              />
                            </>
                          )
                      )}
                    </div>
                    <div className="separator border-2 my-10"></div>
                    <div>
                      {othersEmails.map(
                        (formation, index) =>
                          formation && (
                            <>
                              <FormMultpleAddition
                                limit={3}
                                changeTab={changeTab}
                                key={`${Math.random() * 9999}`}
                                inputData={{
                                  name: `other_emails.${index}.email`,
                                  label: 'Email'
                                }}
                                negative={{
                                  name: `other_emails.${index + 1}.isActive`
                                }}
                                positive={{
                                  name: `other_emails.${index + 1}.isActive`
                                }}
                                hasHeader={
                                  index === 0
                                    ? {
                                        negative: {
                                          name: `other_emails.${index}.isActive`
                                        },
                                        positive: {
                                          name: `other_emails.${index}.isActive`
                                        },
                                        label:
                                          'Tem outros e-mails utilizados nos últimos 5 anos, diferentes dos atuais?'
                                      }
                                    : undefined
                                }
                                label="Tem outros e-mails utilizados nos últimos 5 anos, diferentes dos atuais?"
                                data={othersEmails}
                                index={index}
                                setData={setOthersEmails}
                                parent="child"
                              />
                            </>
                          )
                      )}
                    </div>
                    <div className="separator border-2 my-10"></div>
                    <div>
                      {othersSocial.map(
                        (formation, index) =>
                          formation && (
                            <>
                              <FormMultpleAddition
                                limit={3}
                                changeTab={changeTab}
                                key={`${Math.random() * 9999}`}
                                hasCustomFields={[
                                  {
                                    name: `other_socials.${index}.user`,
                                    label: 'Rede Social',
                                    type: 'select',
                                    options: TYPE_SOCIAL_NETWORK
                                  },
                                  {
                                    name: `other_socials.${index}.social`,
                                    label: 'Usuário'
                                  }
                                ]}
                                negative={{
                                  name: `other_socials.${index + 1}.isActive`
                                }}
                                positive={{
                                  name: `other_socials.${index + 1}.isActive`
                                }}
                                hasHeader={
                                  index === 0
                                    ? {
                                        negative: {
                                          name: `other_socials.${index}.isActive`
                                        },
                                        positive: {
                                          name: `other_socials.${index}.isActive`
                                        },
                                        label:
                                          'Possui redes sociais Pessoais ou empresariais? Caso positivo informe nome da rede e nome de usuário'
                                      }
                                    : undefined
                                }
                                label="Tem outras redes?"
                                data={othersSocial}
                                index={index}
                                setData={setOthersSocial}
                                parent="child"
                              />
                            </>
                          )
                      )}
                    </div>
                    <div className="separator border-2 my-10"></div>
                    <div className="row mb-5">
                      <Input
                        className="col-xl"
                        label="Série e número do passaporte"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        name="passport_series_and_number"
                        // mask={{
                        //   mask: '(00) 0000-0000'
                        // }}
                        controlled
                        rules={{ required: changeTab !== 'previous' }}
                        hasErrorCustom
                        hasCustom
                      />
                      <Input
                        className="col-xl"
                        label="Cidade onde foi emitido o Passaporte"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        name="city_where_passport_was_issued"
                        // mask={{
                        //   mask: '(00) 0000-0000'
                        // }}
                        controlled
                        rules={{ required: changeTab !== 'previous' }}
                        hasErrorCustom
                        hasCustom
                      />
                      <Input
                        className="col-xl"
                        label="Estado onde foi emitido o Passaporte"
                        // inputOptions={{
                        //   classname: 'd-flex align-items-center'
                        // }}
                        name="state_where_passport_was_issued"
                        // mask={{
                        //   mask: '(00) 0000-0000'
                        // }}
                        rules={{ required: changeTab !== 'previous' }}
                        hasErrorCustom
                        hasCustom
                      />
                      <Input
                        className="col-xl"
                        label="País onde foi emitido o Passaporte"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        name="country_where_passport_was_issued"
                        defaultValue="BRASIL"
                        // mask={{
                        //   mask: '(00) 0000-0000'
                        // }}
                        controlled
                        rules={{ required: changeTab !== 'previous' }}
                        hasErrorCustom
                        hasCustom
                      />
                    </div>
                    <div className="row mb-5">
                      <Select
                        className="col-xl"
                        label="Tipo de passaporte"
                        name="type_passport"
                        hasCustom
                        rules={{ required: changeTab !== 'previous' }}
                        options={[
                          {
                            name: 'Regular',
                            value: 'Regular'
                          },
                          {
                            name: 'Oficial',
                            value: 'Oficial'
                          },
                          {
                            name: 'Diplomático',
                            value: 'Diplomático'
                          },
                          {
                            name: 'Laissez-passer',
                            value: 'Laissez-passer'
                          },
                          {
                            name: 'Outros',
                            value: 'Outros'
                          }
                        ]}
                        controlled
                        blank
                        defaultValue=""
                      />
                      <DatePickerCustom
                        popperProps={{ strategy: 'fixed' }}
                        locale="ptBR"
                        name="passport_issue_date"
                        className="col-xl"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        label="Data de emissão do passaporte"
                        dateFormat="dd/MM/yyyy"
                        placeholderText="DD/MM/AAAA"
                        hasCustom
                        hasErrorCustom
                        // selected={issuePassportDate}
                        rules={{ required: changeTab !== 'previous' }}
                        maxDate={new Date()}
                        customInput={
                          <IMaskInput
                            mask={Date}
                            pattern={'d/m/Y'}
                            format={date => {
                              return moment(date).format(inputMaskFormat)
                            }}
                            parse={value => {
                              return moment(value, inputMaskFormat).toDate()
                            }}
                          />
                        }
                        // onChange={date => {
                        //   setIssuePassportDate(date)
                        // }}
                        controlled
                      />
                      <DatePickerCustom
                        popperProps={{ strategy: 'fixed' }}
                        locale="ptBR"
                        name="passport_validate"
                        className="col-xl"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        label="Data de validade do passaporte"
                        dateFormat="dd/MM/yyyy"
                        placeholderText="DD/MM/AAAA"
                        hasCustom
                        hasErrorCustom
                        rules={{ required: changeTab !== 'previous' }}
                        // selected={validatePassportDate}

                        customInput={
                          <IMaskInput
                            mask={Date}
                            pattern={'d/m/Y'}
                            format={date => {
                              return moment(date).format(inputMaskFormat)
                            }}
                            parse={value => {
                              return moment(value, inputMaskFormat).toDate()
                            }}
                          />
                        }
                        // onChange={date => {
                        //   setValidatePassportDate(date)
                        // }}
                        controlled
                      />
                    </div>
                    <div className="row mb-5 d-flex">
                      <FormGeneral
                        changeTab={changeTab}
                        label="Você já teve algum Passaporte roubado ou perdido?"
                        explain={{ name: 'lost_or_stolen_passport.explain' }}
                        positive={{ name: 'lost_or_stolen_passport.check' }}
                        negative={{
                          name: 'lost_or_stolen_passport.check',
                          checked: undefined
                        }}
                      />
                    </div>
                    <div className="separator border-2 my-6"></div>

                    <div className="row  d-flex align-items-end justify-content-end">
                      <div className="text-center col-xl-2 d-flex align-items-end justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-xl btn-primary mb-5"
                        >
                          <span className="indicator-label">Próximo</span>
                        </button>
                      </div>
                    </div>
                  </Form>
                </div>
              </Tab>
              {/* <Tab notClick title="2 - Dados De Viagem" key={1}>
                <div className="form mt-5">
                  <Form
                    className="form"
                    onSubmit={handleNextForm}
                    defaultValues={dataForm}
                  ></Form>
                </div>
              </Tab> */}
              <Tab title="2 - Dados Da familia" key={1}>
                <div className="form mt-5">
                  <Form
                    className="form"
                    onSubmit={handleNextForm}
                    defaultValues={dataForm}
                  >
                    <div className="row mb-5">
                      <div className="row">
                        <h3 className="col">
                          Dados do pai (colocar informação: Mesmo que falecido)
                        </h3>
                        <div className="col">
                          <Checkbox
                            name="not_has_father"
                            {...getToggleProps({
                              onClick: () => setHasFather(old => !old)
                            })}
                            type="checkbox"
                            label="Não consta"
                            hasCustom
                            hasCheckbox
                          />
                        </div>
                      </div>
                      <div {...getCollapseProps()}>
                        {hasFather && (
                          <FormFamily
                            changeTab={changeTab}
                            datepickerBirthDate={{
                              name: 'father.birth_date'
                            }}
                            inputName={{ name: 'father.name' }}
                            inputRelationship={{ name: 'father.surname' }}
                            checkboxInTheUsa={{
                              name: 'father.is_in_the_usa'
                            }}
                            inputAddress={{ name: 'father.address' }}
                            inputCountry={{ name: 'father.country' }}
                            inputOccupation={{ name: 'father.occupation' }}
                            selectTravelWithYou={{
                              name: 'father.travel',
                              options: optionsSimple,
                              blank: true
                            }}
                            selectMaritalStatus={{
                              name: 'father.marital_status',
                              options: optionsValue?.maritalStatus || [],
                              blank: true
                            }}
                            defaultShow="show"
                            type="parents"
                          />
                        )}
                      </div>
                    </div>

                    <div className="separator border-2 my-10"></div>

                    <div className="row mb-5">
                      <h3>
                        Dados da mãe (colocar informação: Mesmo que falecida)
                      </h3>
                      <FormFamily
                        changeTab={changeTab}
                        datepickerBirthDate={{
                          name: 'mother.birth_date'
                        }}
                        checkboxInTheUsa={{ name: 'mother.is_in_the_usa' }}
                        inputName={{ name: 'mother.name' }}
                        inputRelationship={{ name: 'mother.surname' }}
                        inputAddress={{ name: 'mother.address' }}
                        inputCountry={{ name: 'mother.country' }}
                        inputOccupation={{ name: 'mother.occupation' }}
                        selectTravelWithYou={{
                          name: 'mother.travel',
                          options: optionsSimple,
                          blank: true
                        }}
                        selectMaritalStatus={{
                          name: 'mother.marital_status',
                          options: optionsValue?.maritalStatus || [],
                          blank: true
                        }}
                        defaultShow="show"
                        type="parents"
                      />
                    </div>
                    <div className="row mb-5 d-flex">
                      <CheckboxWithForm
                        changeTab={changeTab}
                        label="Você tem algum parente, não incluindo os pais, nos EUA?"
                        positive={{
                          name: 'do_you_have_any_relatives'
                        }}
                        negative={{
                          name: 'do_you_have_any_relatives'
                        }}
                      >
                        <div className="row mb-5">
                          <Input
                            className="col-xl"
                            label="Nome completo"
                            inputOptions={{
                              classname: 'd-flex align-items-center'
                            }}
                            name="do_you_have_any_relatives_fullname"
                            hasCustom
                            hasErrorCustom
                            hasValidationParent={{
                              parent_name: 'do_you_have_any_relatives'
                            }}
                          />
                          <Input
                            className="col-xl"
                            label="Grau de Parentesco"
                            inputOptions={{
                              classname: 'd-flex align-items-center'
                            }}
                            name="do_you_have_any_relatives_relationship"
                            hasCustom
                            hasErrorCustom
                            hasValidationParent={{
                              parent_name: 'do_you_have_any_relatives'
                            }}
                          />
                        </div>
                        <div className="row mb-5">
                          <Select
                            className="col-xl"
                            label="Escolha uma das respostas"
                            name="do_you_have_any_relatives_choice_answer"
                            hasValidationParent={{
                              parent_name: 'do_you_have_any_relatives'
                            }}
                            options={TYPE_CITIZENSHIP}
                            blank
                            controlled
                          />
                          <Input
                            className="col-xl"
                            label="Outro"
                            inputOptions={{
                              classname: 'd-flex align-items-center'
                            }}
                            name="do_you_have_any_relatives_other"
                            hasCustom
                            hasErrorCustom
                          />
                        </div>
                      </CheckboxWithForm>
                    </div>
                    <FormMaritalStatus
                      changeTab={changeTab}
                      datepickerMarriageStart={{
                        name: 'spouse.start_date',
                        selected: birthDate,
                        setSelected: setBirthDate,
                        onChange: date => setBirthDate(date)
                      }}
                      datepickerMarriageEnd={{
                        name: 'spouse.end_start',
                        selected: birthDate,
                        setSelected: setBirthDate,
                        onChange: date => setBirthDate(date)
                      }}
                      inputSpouseName={{ name: 'spouse.name' }}
                      inputSpouseCityBirth={{ name: 'spouse.city_birth' }}
                      inputStatus={{
                        name: 'spouse.marital_status'
                      }}
                      label="Dados do Cônjuge"
                      labelClassname="fs-3"
                      datepickerStableUnion={{
                        name: 'spouse.date',
                        selected: birthDate,
                        setSelected: setBirthDate,
                        onChange: date => setBirthDate(date)
                      }}
                      inputSpouseAddress={{ name: 'spouse.address' }}
                      inputSpouseCountry={{ name: 'spouse.country' }}
                      inputSpouseDescriptionOccupation={{
                        name: 'spouse.description'
                      }}
                      inputSpouseOccupation={{ name: 'spouse.occupation' }}
                      textExplain={{ name: 'spouse.occupation' }}
                      datepickerSpouseDateBirth={{
                        name: 'spouse.birth_date',
                        selected: birthDate,
                        setSelected: setBirthDate,
                        onChange: date => setBirthDate(date)
                      }}
                      selectTravelWithYou={{
                        name: 'spouse.travel_you',
                        options: optionsSimple,
                        blank: true
                      }}
                      selectRelationshipType={{
                        name: 'spouse.relation',
                        options: optionsValue?.relationship || [],
                        blank: true
                      }}
                      states={states}
                      noOptions
                    />
                    <div className="separator border-2 my-10"></div>
                    <div className="row d-flex align-items-end justify-content-between">
                      <div className="text-center col-xl-2 mw-100px d-flex align-items-end justify-content-start">
                        <button
                          type="submit"
                          onClick={() => handleChangePage('previous')}
                          className="btn btn-xl btn-primary mb-5"
                        >
                          <span className="indicator-label">Anterior</span>
                        </button>
                      </div>
                      <div className="text-center col-xl-2 mw-100px d-flex align-items-end justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-xl btn-primary mb-5"
                        >
                          <span className="indicator-label">Próxima</span>
                        </button>
                      </div>
                    </div>
                  </Form>
                </div>
              </Tab>
              <Tab title="3 - Dados Sobre O Trabalho" key={2}>
                <div className="form mt-5">
                  <Form
                    className="form"
                    onSubmit={handleNextForm}
                    defaultValues={dataForm}
                  >
                    <div className="row mb-5">
                      <FormJob
                        label="Está empregado atualmente?"
                        states={states}
                        changeTab={changeTab}
                        datepickerMarriageStart={{
                          name: 'job.start_date',
                          selected: birthDate,
                          setSelected: setBirthDate,
                          onChange: date => setBirthDate(date)
                        }}
                        datepickerMarriageEnd={{
                          name: 'job.end_start',
                          selected: birthDate,
                          setSelected: setBirthDate,
                          onChange: date => setBirthDate(date)
                        }}
                        datepickerSpouseBirthDate={{
                          name: 'job.birth_date'
                          // selected: birthDate,
                          // setSelected: setBirthDate,
                          // onChange: date => setBirthDate(date)
                        }}
                        companyName={{ name: 'job.company_name' }}
                        inputSpouseSurName={{ name: 'job.surname' }}
                        inputStatus={{
                          name: 'job.marital_status'
                        }}
                        datepickerStableUnion={{
                          name: 'job.date',
                          selected: birthDate,
                          setSelected: setBirthDate,
                          onChange: date => setBirthDate(date)
                        }}
                        inputSpouseAddress={{ name: 'job.address' }}
                        inputSpouseCountry={{ name: 'job.country' }}
                        inputSpouseDescriptionOccupation={{
                          name: 'job.description'
                        }}
                        inputSpouseOccupation={{ name: 'job.occupation' }}
                        textExplain={{ name: 'job.explain' }}
                        admissionDate={{
                          name: 'job.admission_date'
                          // selected: birthDate,
                          // setSelected: setBirthDate,
                          // onChange: date => setBirthDate(date)
                        }}
                        selectTravelWithYou={{
                          name: 'job.travel_you',
                          options: optionsSimple,
                          blank: true
                        }}
                        selectJob={{
                          name: 'job.selectJob',
                          options: optionsTypeJob,
                          blank: true
                        }}
                        selectRelationshipType={{
                          name: 'job.relation',
                          options: optionsValue?.relationship || [],
                          blank: true
                        }}
                      />
                    </div>

                    <div className="separator border-2 my-10"></div>
                    <div className="">
                      <CheckboxWithForm
                        changeTab={changeTab}
                        label="Você está no emprego atual a mais de 6 anos?"
                        positive={{
                          name: 'has_been_in_the_current_job_for_more_than_6_years',
                          label: 'Não'
                        }}
                        negative={{
                          name: 'has_been_in_the_current_job_for_more_than_6_years',
                          label: 'Sim'
                        }}
                      >
                        <div className="row mb-5">
                          <Input
                            className="col-xl"
                            label="Nome da última empresa"
                            inputOptions={{
                              classname: 'd-flex align-items-center'
                            }}
                            hasValidationParent={{
                              parent_name:
                                'has_been_in_the_current_job_for_more_than_6_years'
                            }}
                            name="has_been_in_the_current_job_for_more_than_6_years_last_company_name"
                            hasCustom
                            hasErrorCustom
                          />
                        </div>
                        <div className="row mb-5">
                          <Input
                            className="col-xl"
                            label="Endereço última empresa"
                            inputOptions={{
                              classname: 'd-flex align-items-center'
                            }}
                            name="has_been_in_the_current_job_for_more_than_6_years_last_company_address"
                            hasCustom
                            hasErrorCustom
                            hasValidationParent={{
                              parent_name:
                                'has_been_in_the_current_job_for_more_than_6_years'
                            }}
                          />
                        </div>
                        <div className="row mb-5">
                          <Input
                            className="col-xl"
                            label="Telefone última empresa"
                            inputOptions={{
                              classname: 'd-flex align-items-center'
                            }}
                            name="has_been_in_the_current_job_for_more_than_6_years_last_company_phone"
                            hasCustom
                            hasErrorCustom
                            hasValidationParent={{
                              parent_name:
                                'has_been_in_the_current_job_for_more_than_6_years'
                            }}
                          />
                          <Input
                            className="col-xl"
                            label="Função na última empresa"
                            inputOptions={{
                              classname: 'd-flex align-items-center'
                            }}
                            name="has_been_in_the_current_job_for_more_than_6_years_last_company_role"
                            hasCustom
                            hasValidationParent={{
                              parent_name:
                                'has_been_in_the_current_job_for_more_than_6_years'
                            }}
                            hasErrorCustom
                          />
                        </div>
                        <div className="row mb-5">
                          <DatePickerCustom
                            label="Data de entrada última empresa"
                            popperProps={{ strategy: 'fixed' }}
                            locale="ptBR"
                            name="has_been_in_the_current_job_for_more_than_6_years_last_company_admission_date"
                            className="col-xl"
                            inputOptions={{
                              classname: 'd-flex align-items-center'
                            }}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="DD/MM/AAAA"
                            hasCustom
                            hasErrorCustom
                            hasValidationParent={{
                              parent_name:
                                'has_been_in_the_current_job_for_more_than_6_years'
                            }}
                            maxDate={new Date()}
                            customInput={
                              <IMaskInput
                                mask={Date}
                                pattern={'d/m/Y'}
                                format={date => {
                                  return moment(date).format(inputMaskFormat)
                                }}
                                parse={value => {
                                  return moment(value, inputMaskFormat).toDate()
                                }}
                              />
                            }
                            // onChange={date => {
                            //   setBirthDate(date)
                            // }}
                            controlled
                          />
                          <DatePickerCustom
                            label="Data de saída última empresa"
                            popperProps={{ strategy: 'fixed' }}
                            locale="ptBR"
                            name="has_been_in_the_current_job_for_more_than_6_years_last_company_shutdown_date"
                            className="col-xl"
                            inputOptions={{
                              classname: 'd-flex align-items-center'
                            }}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="DD/MM/AAAA"
                            hasCustom
                            hasErrorCustom
                            hasValidationParent={{
                              parent_name:
                                'has_been_in_the_current_job_for_more_than_6_years'
                            }}
                            maxDate={new Date()}
                            customInput={
                              <IMaskInput
                                mask={Date}
                                pattern={'d/m/Y'}
                                format={date => {
                                  return moment(date).format(inputMaskFormat)
                                }}
                                parse={value => {
                                  return moment(value, inputMaskFormat).toDate()
                                }}
                              />
                            }
                            // onChange={date => {
                            //   setBirthDate(date)
                            // }}
                            controlled
                          />
                        </div>
                      </CheckboxWithForm>
                    </div>
                    <div className="separator border-2 my-10"></div>
                    <CheckboxWithForm
                      changeTab={changeTab}
                      label="Tem graduação, ou pós graduação concluídos? "
                      positive={{
                        name: 'have_undergraduate_postgraduate',
                        label: 'Sim'
                      }}
                      negative={{
                        name: 'have_undergraduate_postgraduate',
                        label: 'Não'
                      }}
                    >
                      <div className="row mb-5">
                        <Input
                          className="col-xl"
                          label="Dados da Formação Escolar. faculdade ou cursos de pós graduação ou ensino secundário. Nome da Instituição"
                          inputOptions={{
                            classname: 'd-flex align-items-center'
                          }}
                          name="school_training_data"
                          hasCustom
                          hasErrorCustom
                          rules={{ required: changeTab !== 'previous' }}
                          hasValidationParent={{
                            parent_name: 'have_undergraduate_postgraduate'
                          }}
                        />
                      </div>
                      <div className="row mb-5">
                        <h3>Endereço Completo</h3>
                        <Input
                          hasValidationParent={{
                            parent_name: 'have_undergraduate_postgraduate'
                          }}
                          className="col-xl"
                          label="CEP"
                          inputOptions={{
                            classname: 'd-flex align-items-center'
                          }}
                          hasErrorCustom
                          name="school_training.address.zip_code"
                          mask={{
                            mask: INPUT_MASK.ZIP_CODE,
                            type: 'zipCode',
                            prefixInputsData: 'school_training.address.'
                          }}
                          controlled
                          rules={{
                            required: changeTab !== 'previous',
                            minLength: { value: 8, message: 'Cep Inválido' }
                          }}
                          maxLength={10}
                          hasCustom
                        />
                        <Input
                          hasValidationParent={{
                            parent_name: 'have_undergraduate_postgraduate'
                          }}
                          className="col-xl"
                          label="Rua, nº, complemento"
                          inputOptions={{
                            classname: 'd-flex align-items-center'
                          }}
                          name="school_training.address.street"
                          rules={{ required: changeTab !== 'previous' }}
                          hasErrorCustom
                          hasCustom
                        />
                        {/* <Input
                          hasValidationParent={{
                            parent_name: 'have_undergraduate_postgraduate'
                          }}
                          className="col-xl"
                          label="Número"
                          inputOptions={{
                            classname: 'd-flex align-items-center'
                          }}
                          name="school_training.address.number"
                          rules={{ required: changeTab !== 'previous' }}
                          hasErrorCustom
                          hasCustom
                        /> */}
                        <Input
                          hasValidationParent={{
                            parent_name: 'have_undergraduate_postgraduate'
                          }}
                          className="col-xl"
                          label="Bairro"
                          inputOptions={{
                            classname: 'd-flex align-items-center'
                          }}
                          name="school_training.address.district"
                          rules={{ required: changeTab !== 'previous' }}
                          hasErrorCustom
                          hasCustom
                        />
                      </div>

                      <div className="row mb-5">
                        <Input
                          hasValidationParent={{
                            parent_name: 'have_undergraduate_postgraduate'
                          }}
                          className="col-xl"
                          label="Cidade"
                          inputOptions={{
                            classname: 'd-flex align-items-center'
                          }}
                          name="school_training.address.city"
                          rules={{ required: changeTab !== 'previous' }}
                          hasErrorCustom
                          hasCustom
                        />
                        <Select
                          hasValidationParent={{
                            parent_name: 'have_undergraduate_postgraduate'
                          }}
                          className="col-xl"
                          label="Estado"
                          labelOptions={{ classname: 'col-xl' }}
                          selectOptions={{
                            classname: 'col-xl'
                          }}
                          name="school_training.address.state"
                          rules={{
                            required: changeTab !== 'previous'
                          }}
                          options={states}
                          // disabled={
                          //   countryValue?.country?.toLowerCase() !== 'brasil'
                          // }
                          // onChange={handleSelectState}
                          // value={selectedValue?.state || ''}
                          controlled
                          blank
                        />
                      </div>
                      <div className="row mb-5">
                        <Input
                          hasValidationParent={{
                            parent_name: 'have_undergraduate_postgraduate'
                          }}
                          className="col-xl"
                          label="Complemento"
                          inputOptions={{
                            classname: 'd-flex align-items-center'
                          }}
                          name="school_training.address.complement"
                          // hasCustom
                          hasErrorCustom
                        />
                      </div>
                      <div className="row mb-5">
                        <Input
                          hasValidationParent={{
                            parent_name: 'have_undergraduate_postgraduate'
                          }}
                          className="col-xl"
                          label="Curso de Estudo"
                          inputOptions={{
                            classname: 'd-flex align-items-center'
                          }}
                          name="school_training_data_course"
                          hasCustom
                          hasErrorCustom
                          rules={{ required: changeTab !== 'previous' }}
                        />
                        <DatePickerCustom
                          hasValidationParent={{
                            parent_name: 'have_undergraduate_postgraduate'
                          }}
                          label="Data de início"
                          name="school_training_data_start_date"
                          className="col-xl"
                          inputOptions={{
                            classname: 'd-flex align-items-center'
                          }}
                          placeholderText="DD/MM/AAAA"
                          hasCustom
                          hasErrorCustom
                          rules={{ required: changeTab !== 'previous' }}
                          maxDate={new Date()}
                          controlled
                        />
                        <DatePickerCustom
                          hasValidationParent={{
                            parent_name: 'have_undergraduate_postgraduate'
                          }}
                          label="Data de término"
                          popperProps={{ strategy: 'fixed' }}
                          locale="ptBR"
                          name="school_training_data_end_date"
                          className="col-xl"
                          inputOptions={{
                            classname: 'd-flex align-items-center'
                          }}
                          dateFormat="dd/MM/yyyy"
                          placeholderText="DD/MM/AAAA"
                          hasCustom
                          hasErrorCustom
                          rules={{ required: changeTab !== 'previous' }}
                          maxDate={new Date()}
                          customInput={
                            <IMaskInput
                              mask={Date}
                              pattern={'d/m/Y'}
                              format={date => {
                                return moment(date).format(inputMaskFormat)
                              }}
                              parse={value => {
                                return moment(value, inputMaskFormat).toDate()
                              }}
                            />
                          }
                          // onChange={date => {
                          //   setBirthDate(date)
                          // }}
                          controlled
                        />
                      </div>
                    </CheckboxWithForm>
                    <div className="separator border-2 my-10"></div>
                    <CheckboxWithForm
                      changeTab={changeTab}
                      label="Está fazendo algum curso superior no momento?"
                      positive={{
                        name: 'currently_higher_course',
                        label: 'Sim'
                      }}
                      negative={{
                        name: 'currently_higher_course',
                        label: 'Não'
                      }}
                    >
                      <div className="row mb-5">
                        <Input
                          className="col-xl"
                          label="Nome da Instituição"
                          inputOptions={{
                            classname: 'd-flex align-items-center'
                          }}
                          name="currently_higher_course_data"
                          hasCustom
                          hasErrorCustom
                          rules={{ required: changeTab !== 'previous' }}
                          hasValidationParent={{
                            parent_name: 'currently_higher_course'
                          }}
                        />
                      </div>
                      <div className="row mb-5">
                        <h3>Endereço Completo</h3>
                        <Input
                          hasValidationParent={{
                            parent_name: 'currently_higher_course'
                          }}
                          className="col-xl"
                          label="CEP"
                          inputOptions={{
                            classname: 'd-flex align-items-center'
                          }}
                          hasErrorCustom
                          name="currently_higher_course.address.zip_code"
                          mask={{
                            mask: INPUT_MASK.ZIP_CODE,
                            type: 'zipCode',
                            prefixInputsData: 'currently_higher_course.address.'
                          }}
                          controlled
                          rules={{
                            required: changeTab !== 'previous',
                            minLength: { value: 8, message: 'Cep Inválido' }
                          }}
                          maxLength={10}
                          hasCustom
                        />
                        <Input
                          hasValidationParent={{
                            parent_name: 'currently_higher_course'
                          }}
                          className="col-xl"
                          label="Rua, nº, complemento"
                          inputOptions={{
                            classname: 'd-flex align-items-center'
                          }}
                          name="currently_higher_course.address.street"
                          rules={{ required: changeTab !== 'previous' }}
                          hasErrorCustom
                          hasCustom
                        />
                        {/* <Input
                          hasValidationParent={{
                            parent_name: 'currently_higher_course'
                          }}
                          className="col-xl"
                          label="Número"
                          inputOptions={{
                            classname: 'd-flex align-items-center'
                          }}
                          name="currently_higher_course.address.number"
                          rules={{ required: changeTab !== 'previous' }}
                          hasErrorCustom
                          hasCustom
                        /> */}
                        <Input
                          hasValidationParent={{
                            parent_name: 'currently_higher_course'
                          }}
                          className="col-xl"
                          label="Bairro"
                          inputOptions={{
                            classname: 'd-flex align-items-center'
                          }}
                          name="currently_higher_course.address.district"
                          rules={{ required: changeTab !== 'previous' }}
                          hasErrorCustom
                          hasCustom
                        />
                      </div>

                      <div className="row mb-5">
                        <Input
                          hasValidationParent={{
                            parent_name: 'currently_higher_course'
                          }}
                          className="col-xl"
                          label="Cidade"
                          inputOptions={{
                            classname: 'd-flex align-items-center'
                          }}
                          name="currently_higher_course.address.city"
                          rules={{ required: changeTab !== 'previous' }}
                          hasErrorCustom
                          hasCustom
                        />
                        <Select
                          hasValidationParent={{
                            parent_name: 'currently_higher_course'
                          }}
                          className="col-xl"
                          label="Estado"
                          labelOptions={{ classname: 'col-xl' }}
                          selectOptions={{
                            classname: 'col-xl'
                          }}
                          name="currently_higher_course.address.state"
                          rules={{
                            required: changeTab !== 'previous'
                          }}
                          options={states}
                          // disabled={
                          //   countryValue?.country?.toLowerCase() !== 'brasil'
                          // }
                          // onChange={handleSelectState}
                          // value={selectedValue?.state || ''}
                          controlled
                          blank
                        />
                      </div>
                      <div className="row mb-5">
                        <Input
                          hasValidationParent={{
                            parent_name: 'currently_higher_course'
                          }}
                          className="col-xl"
                          label="Complemento"
                          inputOptions={{
                            classname: 'd-flex align-items-center'
                          }}
                          name="currently_higher_course.address.complement"
                          hasErrorCustom
                        />
                      </div>
                      <div className="row mb-5">
                        <Input
                          hasValidationParent={{
                            parent_name: 'currently_higher_course'
                          }}
                          className="col-xl"
                          label="Curso de Estudo"
                          inputOptions={{
                            classname: 'd-flex align-items-center'
                          }}
                          name="currently_higher_course_data_course"
                          hasCustom
                          hasErrorCustom
                          rules={{ required: changeTab !== 'previous' }}
                        />
                        <DatePickerCustom
                          hasValidationParent={{
                            parent_name: 'currently_higher_course'
                          }}
                          label="Data de início"
                          name="currently_higher_course_data_start_date"
                          className="col-xl"
                          inputOptions={{
                            classname: 'd-flex align-items-center'
                          }}
                          placeholderText="DD/MM/AAAA"
                          hasCustom
                          hasErrorCustom
                          rules={{ required: changeTab !== 'previous' }}
                          maxDate={new Date()}
                          controlled
                        />
                      </div>
                    </CheckboxWithForm>

                    <div className="separator border-2 my-10"></div>
                    <div className="">
                      {formations.map(
                        (formation, index) =>
                          formation && (
                            <>
                              <FormFormation
                                states={states}
                                changeTab={changeTab}
                                key={`${Math.random() * 9999}`}
                                datepickerStartDate={{
                                  name: `formations.${index}.start_date`
                                }}
                                datepickerEndDate={{
                                  name: `formations.${index}.end_date`
                                }}
                                inputSchoolName={{
                                  name: `formations.${index}.school_name`
                                }}
                                inputCourseStudy={{
                                  name: `formations.${index}.course_study`
                                }}
                                inputAddress={{
                                  name: `formations.${index}.address`
                                }}
                                negative={{
                                  name: `formations.${index + 1}.isActive`
                                }}
                                positive={{
                                  name: `formations.${index + 1}.isActive`
                                }}
                                hasHeader={
                                  index === 0
                                    ? {
                                        negative: {
                                          name: `formations.${index}.isActive`
                                        },
                                        positive: {
                                          name: `formations.${index}.isActive`
                                        },
                                        label: 'Adicionar outra formação?'
                                      }
                                    : undefined
                                }
                                label="Adicionar outra formação?"
                                data={formations}
                                index={index}
                                setData={setFormations}
                                parent="child"
                              />
                            </>
                          )
                      )}
                    </div>
                    <div className="separator border-2 my-10"></div>
                    <div className="row mb-5">
                      <CheckboxWithForm
                        changeTab={changeTab}
                        label="Você viajou para outros países nos últimos 5 anos?"
                        positive={{
                          name: 'have_you_traveled_to_other_countries_in_the_last_5_years'
                        }}
                        negative={{
                          name: 'have_you_traveled_to_other_countries_in_the_last_5_years'
                        }}
                      >
                        <div className="row mb-10">
                          <Textarea
                            label="Informe todos os países que visitou nos últimos 5 anos"
                            name="have_you_traveled_to_other_countries_in_the_last_5_years_explain"
                            style={{ minHeight: 100 }}
                            maxLength={300}
                            hasValidationParent={{
                              parent_name:
                                'have_you_traveled_to_other_countries_in_the_last_5_years'
                            }}
                            hasCustom
                            hasErrorCustom
                          />
                        </div>
                      </CheckboxWithForm>
                    </div>
                    <div className="row mb-5">
                      <CheckboxWithForm
                        changeTab={changeTab}
                        label="Você fala outras línguas?"
                        positive={{
                          name: 'do_you_speak_other_languages'
                        }}
                        negative={{
                          name: 'do_you_speak_other_languages'
                        }}
                      >
                        <div className="row mb-10">
                          <Textarea
                            label="Informe todas as línguas que fala"
                            name="do_you_speak_other_languages_explain"
                            style={{ minHeight: 100 }}
                            maxLength={300}
                            hasValidationParent={{
                              parent_name: 'do_you_speak_other_languages'
                            }}
                            hasCustom
                            hasErrorCustom
                          />
                        </div>
                      </CheckboxWithForm>
                    </div>
                    <div className="separator border-2 my-10"></div>
                    <div className="row d-flex align-items-end justify-content-between">
                      <div className="text-center col-xl-2 mw-100px d-flex align-items-end justify-content-start">
                        <button
                          type="submit"
                          onClick={() => handleChangePage('previous')}
                          className="btn btn-xl btn-primary mb-5"
                        >
                          <span className="indicator-label">Anterior</span>
                        </button>
                      </div>
                      <div className="text-center col-xl-2 mw-100px d-flex align-items-end justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-xl btn-primary mb-5"
                        >
                          <span className="indicator-label">Próxima</span>
                        </button>
                      </div>
                    </div>
                  </Form>
                </div>
              </Tab>
              <Tab title="4 - Dados De Segurança" key={3}>
                <div className="form mt-5">
                  <Form
                    className="form"
                    onSubmit={
                      changeTab === 'previous' ? handleNextForm : onSubmit
                    }
                    defaultValues={dataForm}
                  >
                    <div className="row mb-5">
                      <FormGeneral
                        changeTab={changeTab}
                        label="Você tem alguma habilidade especializada ou de formação, em armas de fogo, explosivos, armas nucleares, biológicas ou experiência química?"
                        explain={{
                          name: 'do_you_have_any_specialized_skill_chemical_experience.explain'
                        }}
                        negative={{
                          name: 'do_you_have_any_specialized_skill_chemical_experience.check'
                        }}
                        positive={{
                          name: 'do_you_have_any_specialized_skill_chemical_experience.check'
                        }}
                      />
                      <FormGeneral
                        changeTab={changeTab}
                        label="Você prestou serviço militar?"
                        explain={{
                          name: 'did_you_do_military_service.explain'
                        }}
                        negative={{
                          name: 'did_you_do_military_service.check'
                        }}
                        positive={{
                          name: 'did_you_do_military_service.check'
                        }}
                      />
                      <FormGeneral
                        changeTab={changeTab}
                        label="Você já serviu, foi um membro, ou esteve envolvido com uma unidade paramilitar, unidade de vigilante, grupo rebelde, grupo de guerrilha ou a uma organização insurgente ou pertence a um clã ou tribo?"
                        explain={{
                          name: 'have_served_paramilitary.explain'
                        }}
                        negative={{
                          name: 'have_served_paramilitary.check'
                        }}
                        positive={{
                          name: 'have_served_paramilitary.check'
                        }}
                      />
                      <FormGeneral
                        changeTab={changeTab}
                        label="Você tem uma doença transmissível de importância para a saúde pública, como a tuberculose (TB)?"
                        explain={{
                          name: 'have_communicable_disease.explain'
                        }}
                        negative={{
                          name: 'have_communicable_disease.check'
                        }}
                        positive={{
                          name: 'have_communicable_disease.check'
                        }}
                      />
                      <FormGeneral
                        changeTab={changeTab}
                        label="Você tem um distúrbio mental ou físico que represente ou possa representar uma ameaça para a segurança ou bem-estar de si mesmo ou dos outros?"
                        explain={{
                          name: 'you_have_a_mental_disorder.explain'
                        }}
                        negative={{
                          name: 'you_have_a_mental_disorder.check'
                        }}
                        positive={{
                          name: 'you_have_a_mental_disorder.check'
                        }}
                      />
                      <FormGeneral
                        changeTab={changeTab}
                        label="Você é ou já foi viciado em drogas?"
                        explain={{
                          name: 'are_you_or_have_you_ever_been_addicted_to_drugs.explain'
                        }}
                        negative={{
                          name: 'are_you_or_have_you_ever_been_addicted_to_drugs.check'
                        }}
                        positive={{
                          name: 'are_you_or_have_you_ever_been_addicted_to_drugs.check'
                        }}
                      />
                      <FormGeneral
                        changeTab={changeTab}
                        label="Alguma vez você violou, ou foi envolvido em uma conspiração para violar qualquer lei relativa às substâncias controladas?"
                        explain={{
                          name: 'have_you_ever_violated.explain'
                        }}
                        negative={{
                          name: 'have_you_ever_violated.check'
                        }}
                        positive={{
                          name: 'have_you_ever_violated.check'
                        }}
                      />
                      <FormGeneral
                        changeTab={changeTab}
                        label="Você vem para os Estados Unidos para exercer a prostituição ou comercialização ilegal ou, ainda, foi envolvido em prostituição ou na busca de prostitutas nos últimos 10 anos?"
                        explain={{
                          name: 'you_come_to_the_united_states_to_practice_prostitution.explain'
                        }}
                        negative={{
                          name: 'you_come_to_the_united_states_to_practice_prostitution.check'
                        }}
                        positive={{
                          name: 'you_come_to_the_united_states_to_practice_prostitution.check'
                        }}
                      />
                      <FormGeneral
                        changeTab={changeTab}
                        label="Alguma vez você esteve envolvido ou procurou se envolver em lavagem de dinheiro?"
                        explain={{
                          name: 'have_you_ever_been_involved_or_sought_to_become_involved_in_money_laundering.explain'
                        }}
                        negative={{
                          name: 'have_you_ever_been_involved_or_sought_to_become_involved_in_money_laundering.check'
                        }}
                        positive={{
                          name: 'have_you_ever_been_involved_or_sought_to_become_involved_in_money_laundering.check'
                        }}
                      />
                      <FormGeneral
                        changeTab={changeTab}
                        label="Você procurará o envolvimento em espionagem, sabotagem, violações de controle de exportação, ou qualquer outra atividade ilegal enquanto nos Estados Unidos?"
                        explain={{
                          name: 'you_will_seek_involvement_in_espionage.explain'
                        }}
                        negative={{
                          name: 'you_will_seek_involvement_in_espionage.check'
                        }}
                        positive={{
                          name: 'you_will_seek_involvement_in_espionage.check'
                        }}
                      />
                      <FormGeneral
                        changeTab={changeTab}
                        label="Você procurará o envolvimento em atividades terroristas, enquanto nos Estados Unidos, ou já está envolvido em atividades terroristas?"
                        explain={{
                          name: 'you_will_seek_involvement_in_terrorist_activities.explain'
                        }}
                        negative={{
                          name: 'you_will_seek_involvement_in_terrorist_activities.check'
                        }}
                        positive={{
                          name: 'you_will_seek_involvement_in_terrorist_activities.check'
                        }}
                      />
                      <FormGeneral
                        changeTab={changeTab}
                        label="Alguma vez você prestou ou teve a intenção de prestar assistência financeira ou outro apoio a terroristas ou organizações terroristas?"
                        explain={{
                          name: 'have_you_ever_provided_or_intended_to_provide_financial_assistance_or_other_support_to_terrorists_or_terrorist_organizations.explain'
                        }}
                        negative={{
                          name: 'have_you_ever_provided_or_intended_to_provide_financial_assistance_or_other_support_to_terrorists_or_terrorist_organizations.check'
                        }}
                        positive={{
                          name: 'have_you_ever_provided_or_intended_to_provide_financial_assistance_or_other_support_to_terrorists_or_terrorist_organizations.check'
                        }}
                      />
                      <FormGeneral
                        changeTab={changeTab}
                        label="Você é um membro ou representante de uma organização terrorista?"
                        explain={{
                          name: 'you_are_a_member_or_representative_of_a_terrorist_organization.explain'
                        }}
                        negative={{
                          name: 'you_are_a_member_or_representative_of_a_terrorist_organization.check'
                        }}
                        positive={{
                          name: 'you_are_a_member_or_representative_of_a_terrorist_organization.check'
                        }}
                      />
                      <FormGeneral
                        changeTab={changeTab}
                        label="Alguma vez você ordenou, incitou, esteve comprometido, assistiu ou participou em genocídio?"
                        explain={{
                          name: 'have_you_ever_ordered_incited_engaged_in_assisted_or_participated_in_genocide.explain'
                        }}
                        negative={{
                          name: 'have_you_ever_ordered_incited_engaged_in_assisted_or_participated_in_genocide.check'
                        }}
                        positive={{
                          name: 'have_you_ever_ordered_incited_engaged_in_assisted_or_participated_in_genocide.check'
                        }}
                      />
                      <FormGeneral
                        changeTab={changeTab}
                        label="Alguma vez você cometeu, ordenou, incitou, assistiu ou participou de atos de tortura?"
                        explain={{
                          name: 'have_you_ever_committed_ordered_incited_assisted_or_participated_in_acts_of_torture.explain'
                        }}
                        negative={{
                          name: 'have_you_ever_committed_ordered_incited_assisted_or_participated_in_acts_of_torture.check'
                        }}
                        positive={{
                          name: 'have_you_ever_committed_ordered_incited_assisted_or_participated_in_acts_of_torture.check'
                        }}
                      />
                      <FormGeneral
                        changeTab={changeTab}
                        label="Você já cometeu, ordenou, incitou, ou participou de execuções extrajudiciais, assassinatos políticos, ou outros atos de violência?"
                        explain={{
                          name: 'have_you_ever_committed_ordered_incited_or_participated_in_extrajudicial_killings_political_assassinations_or_other_acts_of_violence.explain'
                        }}
                        negative={{
                          name: 'have_you_ever_committed_ordered_incited_or_participated_in_extrajudicial_killings_political_assassinations_or_other_acts_of_violence.check'
                        }}
                        positive={{
                          name: 'have_you_ever_committed_ordered_incited_or_participated_in_extrajudicial_killings_political_assassinations_or_other_acts_of_violence.check'
                        }}
                      />
                      <FormGeneral
                        changeTab={changeTab}
                        label="Alguma vez você foi responsável, diretamente ou indiretamente, de graves violações da liberdade religiosa?"
                        explain={{
                          name: 'have_you_ever_been_responsible_directly_or_indirectly_for_serious_violations_of_religious_freedom.explain'
                        }}
                        negative={{
                          name: 'have_you_ever_been_responsible_directly_or_indirectly_for_serious_violations_of_religious_freedom.check'
                        }}
                        positive={{
                          name: 'have_you_ever_been_responsible_directly_or_indirectly_for_serious_violations_of_religious_freedom.check'
                        }}
                      />
                      <FormGeneral
                        changeTab={changeTab}
                        label="Alguma vez você foi objeto de uma audiência de deportação ou remoção?"
                        explain={{
                          name: 'have_you_ever_been_the_subject_of_a_deportation_or_removal_hearing.explain'
                        }}
                        negative={{
                          name: 'have_you_ever_been_the_subject_of_a_deportation_or_removal_hearing.check'
                        }}
                        positive={{
                          name: 'have_you_ever_been_the_subject_of_a_deportation_or_removal_hearing.check'
                        }}
                      />
                      <FormGeneral
                        changeTab={changeTab}
                        label="Alguma vez você procurou obter ou ajudar outros a obter um visto de entrada nos Estados Unidos, ou qualquer outro benefício de imigração dos Estados Unidos por fraude ou deturpação deliberada ou outros meios ilícitos?"
                        explain={{
                          name: 'have_you_ever_sought_to_obtain_or_help_others_obtain_a_visa_to_enter_the_united_states_or_any_other_united_states_immigration_benefit_by_fraud_or_willful_misrepresentation_or_other_unlawful_means.explain'
                        }}
                        negative={{
                          name: 'have_you_ever_sought_to_obtain_or_help_others_obtain_a_visa_to_enter_the_united_states_or_any_other_united_states_immigration_benefit_by_fraud_or_willful_misrepresentation_or_other_unlawful_means.check'
                        }}
                        positive={{
                          name: 'have_you_ever_sought_to_obtain_or_help_others_obtain_a_visa_to_enter_the_united_states_or_any_other_united_states_immigration_benefit_by_fraud_or_willful_misrepresentation_or_other_unlawful_means.check'
                        }}
                      />
                      <FormGeneral
                        changeTab={changeTab}
                        label="Você ultrapassou ilegalmente o período de tempo concedido por um funcionário da imigração violando os termos de um visto americano?"
                        explain={{
                          name: 'you_illegally_overstayed_the_time_period_granted_by_an_immigration_official_in_violation_of_the_terms_of_a_u_s_visa.explain'
                        }}
                        negative={{
                          name: 'you_illegally_overstayed_the_time_period_granted_by_an_immigration_official_in_violation_of_the_terms_of_a_u_s_visa.check'
                        }}
                        positive={{
                          name: 'you_illegally_overstayed_the_time_period_granted_by_an_immigration_official_in_violation_of_the_terms_of_a_u_s_visa.check'
                        }}
                      />
                      <FormGeneral
                        changeTab={changeTab}
                        label="Alguma vez manteve a custódia de uma criança americana fora dos Estados Unidos a partir de uma pessoa que tinha a guarda legal de um tribunal E.U.A.?"
                        explain={{
                          name: 'have_you_ever_retained_custody_of_an_american_child_outside_the_united_states_from_a_person_who_had_legal_custody_in_a_u_s_court_of_law.explain'
                        }}
                        negative={{
                          name: 'have_you_ever_retained_custody_of_an_american_child_outside_the_united_states_from_a_person_who_had_legal_custody_in_a_u_s_court_of_law.check'
                        }}
                        positive={{
                          name: 'have_you_ever_retained_custody_of_an_american_child_outside_the_united_states_from_a_person_who_had_legal_custody_in_a_u_s_court_of_law.check'
                        }}
                      />
                      <FormGeneral
                        changeTab={changeTab}
                        label="Você já votou nos Estados Unidos em violação de qualquer lei ou regulamento?"
                        explain={{
                          name: 'have_you_ever_voted_in_the_united_states_in_violation_of_any_law_or_regulation.explain'
                        }}
                        negative={{
                          name: 'have_you_ever_voted_in_the_united_states_in_violation_of_any_law_or_regulation.check'
                        }}
                        positive={{
                          name: 'have_you_ever_voted_in_the_united_states_in_violation_of_any_law_or_regulation.check'
                        }}
                      />
                      <FormGeneral
                        changeTab={changeTab}
                        label="Alguma vez você renunciou à cidadania norte-americana com a finalidade de evitar o imposto?"
                        explain={{
                          name: 'have_you_ever_renounced_your_us_citizenship_for_the_purpose_of_avoiding_tax.explain'
                        }}
                        negative={{
                          name: 'have_you_ever_renounced_your_us_citizenship_for_the_purpose_of_avoiding_tax.check'
                        }}
                        positive={{
                          name: 'have_you_ever_renounced_your_us_citizenship_for_the_purpose_of_avoiding_tax.check'
                        }}
                      />
                    </div>
                    <div className="row mb-10">
                      <Textarea
                        label="Informações Adicionais"
                        name="use_this_space_to_send_additional_information_that_may_contribute_to_the_analysis_of_your_profile"
                        style={{ minHeight: 100 }}
                        maxLength={300}
                        hasErrorCustom
                        hasCustom
                      />
                    </div>
                    <div className="separator border-2 my-6"></div>
                    <div className="row mb-5">
                      <h1>Anexos</h1>
                      <p>
                        Insira seus documentos. Ex (Passaporte, visto
                        anteriores, foto digital e etc)
                      </p>
                    </div>
                    {attachments?.map(attachment =>
                      attachment?.name ? (
                        <div className="row mb-5" key={Math.random() * 9999}>
                          <div className="col-sm">
                            <Input
                              name={`attachments.${attachment.id}.name`}
                              value={attachment.name}
                              readOnly
                            />
                          </div>
                          <div className="col-sm d-flex justify-content-between container-download">
                            {!!attachment.url && (
                              <div className="col-xl-3 col-sm mb-5 mw-150px">
                                <button
                                  type="button"
                                  className="btn btn-lg btn-block btn-primary"
                                  onClick={() =>
                                    handleDownloadAttachments(attachment)
                                  }
                                >
                                  Baixar
                                </button>
                              </div>
                            )}
                            <div className="col-sm">
                              <button
                                type="button"
                                className="btn btn-lg btn-block btn-primary"
                                onClick={() =>
                                  handleRemoveAttachments(attachment?.id)
                                }
                              >
                                Excluir
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="row mb-5 d-flex"
                          key={Math.random() * 9999}
                        >
                          <Input
                            className="col-sm"
                            inputOptions={{ classname: 'pe-1' }}
                            label=""
                            name={`attachments.${attachment.id}`}
                            onChange={event => {
                              if (
                                event.target.files?.[0]?.size &&
                                event.target.files?.[0]?.size / 1024 > 6000
                              ) {
                                addToast({
                                  title: 'Arquivo grande',
                                  description:
                                    'O arquivo precisa ter menos que 5MB',
                                  type: 'info'
                                })
                                return (event.target.value = '')
                              }
                              return event
                            }}
                            type="file"
                            hasCustom
                          />
                          <div className="col-sm ms-md-5">
                            <button
                              type="button"
                              className="btn btn-lg btn-block btn-primary"
                              onClick={() =>
                                handleRemoveAttachments(attachment?.id)
                              }
                            >
                              Excluir
                            </button>
                          </div>
                        </div>
                      )
                    )}
                    <div className="row d-flex align-items-end justify-content-end">
                      <div className="text-center col-md-2 mw-100px d-flex align-items-end justify-content-end">
                        <button
                          type="button"
                          className="btn btn-xl btn-primary mb-5"
                          onClick={() => {
                            const findBigger = Math.max(
                              ...attachments.map(attachment => attachment.id)
                            )
                            const biggerId =
                              findBigger === Infinity ||
                              findBigger === -Infinity
                                ? 1
                                : findBigger
                            setAttachments(old => {
                              const copyOld = [...old]
                              copyOld.push({
                                id: (biggerId || 1) + 1
                              })
                              return copyOld
                            })
                          }}
                        >
                          <span className="indicator-label">Adicionar</span>
                        </button>
                      </div>
                    </div>

                    {hasConfirmed && (
                      <>
                        <div className="separator border-2 my-6"></div>
                        <h3 className="col-form-label fs-3">
                          Assinou eletronicamente a sua solicitação em{' '}
                          {new Date().toLocaleString()}. A informação que
                          forneceu as suas informações e outras informações foi
                          submetida com maior atenção às informações sobre as
                          agências governamentais que incluem a informação legal
                          sobre a criminalidade ou a imigração. As principais
                          ferramentas digitais de identificação digital (NGI) ou
                          sistemas digitais de impressão digital de
                          identificação digital (NGI) ou sistemas digitais de
                          impressão digitais ). Uma fotografia que forneceu com
                          a sua atenção pode ser usada para verificar o emprego
                          ou outros propósitos de aplicações da lei Americana.
                        </h3>
                      </>
                    )}
                    <div className="separator border-2 my-6"></div>
                    <div className="row d-flex align-items-end justify-content-between">
                      <div className="text-center col-md-2 mw-100px d-flex align-items-end justify-content-start">
                        <button
                          type="submit"
                          onClick={() => handleChangePage('previous')}
                          className="btn btn-xl btn-primary mb-5"
                        >
                          <span className="indicator-label">Anterior</span>
                        </button>
                      </div>
                      {hasConfirmed ? (
                        <div className="text-center col-md-2 mw-100px d-flex align-items-end justify-content-end">
                          <button
                            type="submit"
                            className="btn btn-xl btn-primary mb-5"
                          >
                            <span className="indicator-label">Enviar</span>
                          </button>
                        </div>
                      ) : (
                        <div className="text-center col-md-2 mw-100px d-flex align-items-end justify-content-end">
                          <button
                            type="button"
                            className="btn btn-xl btn-primary mb-5"
                            onClick={event => {
                              event.preventDefault()
                              setHasConfirmed(true)
                            }}
                          >
                            <span className="indicator-label">Confirmar</span>
                          </button>
                        </div>
                      )}
                    </div>
                  </Form>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </ContainerWrapperContent>
    </div>
  )
}

export default American
