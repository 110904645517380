import moment from 'moment'
import { useCallback, useEffect } from 'react'
import useCollapse from 'react-collapsed'
import { Controller, useFormContext } from 'react-hook-form'
import { IMaskInput } from 'react-imask'
import {
  FROM_HOME_FIELD_NAME,
  INPUT_MASK,
  OPTIONS_YES_OR_NO,
  RETIREE_FIELD_NAME,
  STUDENT_FIELD_NAME
} from '../../../../../../common/constants'
import {
  Checkbox,
  Input,
  InputProps,
  Select,
  SelectProps,
  Textarea,
  TextareaProps
} from '../../../../../../components/Form'
import { DateProps } from '../../../../../../components/Form/date'
import { DatePickerCustom } from '../../../../../../components/Form/dateWithoutState'
import { genericMaskWithTwoZeroWithPoint } from '../../../../../../utlis/mask'
import { CheckboxSimple } from '../CheckboxSimple'
import { CheckboxWithForm } from '../CheckboxWithForm'

import { ContainerAnimation } from './styles'

interface DatepickerProps extends DateProps {
  selected?: Date
  setSelected?: React.Dispatch<React.SetStateAction<Date>>
}

interface FormJobProps {
  datepickerMarriageStart: DatepickerProps
  datepickerMarriageEnd: DatepickerProps
  admissionDate: any
  datepickerSpouseBirthDate: any
  datepickerStableUnion: DatepickerProps
  companyName: InputProps
  inputSpouseSurName: InputProps
  inputSpouseAddress: InputProps
  inputSpouseOccupation: InputProps
  inputSpouseCountry: InputProps
  inputSpouseDescriptionOccupation: InputProps
  selectTravelWithYou: SelectProps
  selectRelationshipType: SelectProps
  selectJob: SelectProps
  textExplain: TextareaProps
  label?: string
  type?: string
  inputStatus?: InputProps
  states?: any[]
  changeTab?: React.SetStateAction<string>
}

export const FormJob: React.FC<FormJobProps> = ({
  datepickerMarriageStart,
  datepickerMarriageEnd,

  inputStatus,
  label,
  datepickerStableUnion,

  admissionDate,
  selectTravelWithYou,
  selectRelationshipType,
  selectJob,
  changeTab,
  states
}) => {
  const inputMaskFormat = 'DD/MM/YYYY'
  const {
    register,
    watch,
    getValues,
    setValue,
    control,
    clearErrors,
    formState: { errors }
  } = useFormContext()
  const watchField = watch(inputStatus?.name)

  const formPrefix = 'formJob'

  useEffect(() => {
    if (watchField === 'single') {
      const prefixArray = inputStatus.name.split('.')
      prefixArray.pop()
      const prefix = prefixArray.join('.')
      const workFields = getValues(prefix)
      if (workFields) {
        setValue(admissionDate.name, '')
        setValue(datepickerStableUnion.name, '')
        setValue(datepickerStableUnion.name, '')
        setValue(selectTravelWithYou.name, '')
        setValue(datepickerMarriageStart.name, '')
        setValue(datepickerMarriageEnd.name, '')
        setValue(selectRelationshipType.name, '')
        const objectEntries = Object.entries(workFields)
        for (let [key] of objectEntries) {
          key = `${prefix}.${key}`
          if (inputStatus.name !== key) {
            setValue(key, '')
          }
        }
      }
      clearErrors(prefix)
    }
  }, [
    clearErrors,
    datepickerMarriageEnd.name,
    datepickerMarriageStart.name,
    admissionDate.name,
    datepickerStableUnion.name,
    getValues,
    inputStatus.name,
    selectRelationshipType.name,
    selectTravelWithYou,
    setValue,
    watchField
  ])

  const verifyIfCheckboxValueIsEqualToYes = watchField === 'yes'
  const verifyIfCheckboxValueIsEqualToStudent =
    watchField === STUDENT_FIELD_NAME
  const verifyIfCheckboxValueIsEqualToRetiree =
    watchField === RETIREE_FIELD_NAME
  const verifyIfCheckboxValueIsEqualToFromHome =
    watchField === FROM_HOME_FIELD_NAME
  const verifyIfCheckboxValueIsEqualToNo = watchField === 'no'

  const handleGetRules = (condition: boolean) => {
    return changeTab !== 'previous'
      ? {
          required: condition
        }
      : { required: false }
  }

  const clearValues = useCallback(
    ({ data, prefix }: { data: any; prefix: string }) => {
      const objectEntries = Object.entries(data)
      for (const [key, value] of objectEntries) {
        if (!value) continue
        if (typeof value === 'object') {
          clearValues({
            data: value,
            prefix: prefix?.length ? `${prefix}.${key}` : prefix
          })
        }
        setValue(`${prefix}.${key}`, null)
      }
    },
    [setValue]
  )
  useEffect(() => {
    if (watchField !== getValues(inputStatus?.name)) {
      const workFields = getValues(formPrefix)

      if (workFields) {
        clearValues({ data: workFields, prefix: formPrefix })
      }
      clearErrors(formPrefix)
    }
  }, [clearErrors, clearValues, getValues, inputStatus?.name, watchField])

  const studentCollapse = useCollapse({
    isExpanded: verifyIfCheckboxValueIsEqualToStudent
  })
  const yesCollapse = useCollapse({
    isExpanded: verifyIfCheckboxValueIsEqualToYes
  })

  return (
    <div>
      <div>
        {!!label && <p className="mb-2 col-form-label fw-bold fs-6">{label}</p>}
        <div className="d-flex flex-column flex-sm-row">
          <div className="col mb-2">
            <Checkbox
              className="col-xl-1"
              label="Sim"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              register={register}
              {...inputStatus}
              type="radio"
              rules={{ required: changeTab !== 'previous' }}
              errors={errors}
              hasErrorCustom
              value="yes"
              hasCustom
              {...yesCollapse?.getToggleProps()}
            />
          </div>
          <div className="col mb-2">
            <Checkbox
              className="col-xl-1"
              label="Estudante"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              register={register}
              {...inputStatus}
              type="radio"
              rules={{ required: changeTab !== 'previous' }}
              errors={errors}
              value={STUDENT_FIELD_NAME}
              hasCustom
              {...studentCollapse?.getToggleProps()}
            />
          </div>
          <div className="col mb-2">
            <Checkbox
              className="col-xl-1"
              label="Aposentado"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              register={register}
              {...inputStatus}
              type="radio"
              value={RETIREE_FIELD_NAME}
              rules={{ required: changeTab !== 'previous' }}
              hasCustom
              errors={errors}
            />
          </div>
          <div className="col mb-2">
            <Checkbox
              className="col-xl-1"
              label="Do Lar"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              register={register}
              {...inputStatus}
              type="radio"
              value={FROM_HOME_FIELD_NAME}
              rules={{ required: changeTab !== 'previous' }}
              hasCustom
              errors={errors}
            />
          </div>
          <div className="col mb-2">
            <Checkbox
              className="col-xl-1"
              label="Não"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              register={register}
              {...inputStatus}
              type="radio"
              value="no"
              rules={{ required: changeTab !== 'previous' }}
              hasCustom
              errors={errors}
            />
          </div>
        </div>
      </div>
      <div className="row mb-5">
        <div
          // isShow={verifyIfCheckboxValueIsEqualToYes ? 'show' : 'hidden'}
          // id="form_job_yes"
          {...yesCollapse?.getCollapseProps()}
        >
          <div className="row mb-5">
            <Controller
              shouldUnregister={true}
              control={control}
              name={formPrefix + '.yes.type_company'}
              rules={handleGetRules(verifyIfCheckboxValueIsEqualToYes)}
              render={({ field }) => (
                <Select
                  className="col-xl-3"
                  label="Tipo de empresa"
                  register={register}
                  selectOptions={{
                    classname: 'd-flex align-items-center'
                  }}
                  {...selectJob}
                  {...field}
                  errors={errors}
                  hasErrorCustom
                />
              )}
            />
            <Controller
              shouldUnregister={true}
              control={control}
              name={formPrefix + '.yes.admission_date'}
              rules={handleGetRules(verifyIfCheckboxValueIsEqualToYes)}
              render={({ field }) => (
                <DatePickerCustom
                  popperProps={{ strategy: 'fixed' }}
                  locale="ptBR"
                  className="col-xl-3"
                  register={register}
                  inputOptions={{ classname: 'd-flex align-items-center' }}
                  label="Data de admissão ou abertura"
                  dateFormat="dd/MM/yyyy"
                  placeholderText="DD/MM/AAAA"
                  hasCustom
                  maxDate={new Date()}
                  // selected={admissionDateValue}
                  {...field}
                  errors={errors}
                  hasErrorCustom
                  customInput={
                    <IMaskInput
                      mask={Date}
                      pattern={'d/m/Y'}
                      format={date => {
                        return moment(date).format(inputMaskFormat)
                      }}
                      parse={value => {
                        return moment(value, inputMaskFormat).toDate()
                      }}
                    />
                  }
                  // onChange={(e: any) => {
                  //   field.onChange(e)
                  //   setAdmissionDateValue(e)
                  // }}
                />
              )}
            />
            <Input
              register={register}
              className="col-xl"
              label="Nome da empresa"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              name={formPrefix + '.yes.company_name'}
              rules={handleGetRules(verifyIfCheckboxValueIsEqualToYes)}
              errors={errors}
              hasErrorCustom
              hasCustom
            />
            <Input
              register={register}
              className="col-xl-2"
              label="Cargo"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              name={formPrefix + '.yes.company_role'}
              rules={handleGetRules(verifyIfCheckboxValueIsEqualToYes)}
              errors={errors}
              hasErrorCustom
              hasCustom
            />
          </div>
          <h3>Endereço completo</h3>

          <div className="row mb-5">
            <Controller
              shouldUnregister={true}
              control={control}
              name={formPrefix + '.yes.address.zip_code'}
              rules={handleGetRules(verifyIfCheckboxValueIsEqualToYes)}
              render={({ field }) => (
                <Input
                  register={register}
                  className="col-xl-2"
                  label="CEP"
                  inputOptions={{
                    classname: 'd-flex align-items-center'
                  }}
                  hasErrorCustom
                  // name={formPrefix + '.' + "address.zip_code"}
                  mask={{
                    mask: INPUT_MASK.ZIP_CODE,
                    type: 'zipCode',
                    prefixInputsData: formPrefix + '.yes.address.',
                    customFields: [
                      {
                        name: 'state',
                        type: 'state',
                        api_field: 'uf'
                      }
                    ]
                  }}
                  rules={handleGetRules(verifyIfCheckboxValueIsEqualToYes)}
                  maxLength={10}
                  hasCustom
                  {...field}
                  errors={errors}
                />
              )}
            />
            <Input
              register={register}
              className="col-xl"
              label="Rua, nº, complemento"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              name={formPrefix + '.' + 'yes.address.street'}
              rules={handleGetRules(verifyIfCheckboxValueIsEqualToYes)}
              errors={errors}
              hasErrorCustom
              hasCustom
            />
            {/* <Input
              register={register}
              className="col-xl-2"
              label="Número"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              name={formPrefix + '.' + 'yes.address.number'}
              rules={
                changeTab !== 'previous'
                  ? {
                      required: verifyIfCheckboxValueIsEqualToYes
                    }
                  : { required: false }
              }
              hasErrorCustom
              hasCustom
              errors={errors}
            /> */}
          </div>
          <div className="row mb-5">
            <Input
              register={register}
              className="col-xl"
              label="Bairro"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              name={formPrefix + '.' + 'yes.address.district'}
              rules={
                changeTab !== 'previous'
                  ? {
                      required: verifyIfCheckboxValueIsEqualToYes
                    }
                  : { required: false }
              }
              hasErrorCustom
              hasCustom
              errors={errors}
            />
            <Input
              register={register}
              className="col-xl"
              label="Cidade"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              name={formPrefix + '.' + 'yes.address.city'}
              rules={handleGetRules(verifyIfCheckboxValueIsEqualToYes)}
              hasErrorCustom
              hasCustom
              errors={errors}
            />
            <Select
              className="col-xl"
              label="Estado"
              labelOptions={{ classname: 'col-xl' }}
              selectOptions={{
                classname: 'col-xl'
              }}
              name={formPrefix + '.' + 'yes.address.state'}
              rules={handleGetRules(verifyIfCheckboxValueIsEqualToYes)}
              options={states}
              register={register}
              blank
              errors={errors}
            />
          </div>

          <div className="row mb-5">
            <Input
              register={register}
              className="col-xl"
              label="Telefone com DDD"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              name={formPrefix + '.' + 'yes.phone'}
              rules={handleGetRules(verifyIfCheckboxValueIsEqualToYes)}
              errors={errors}
              hasErrorCustom
              hasCustom
            />
            <Controller
              shouldUnregister={true}
              control={control}
              name={formPrefix + '.' + 'yes.monthly_salary'}
              rules={handleGetRules(verifyIfCheckboxValueIsEqualToYes)}
              render={({ field }) => {
                return (
                  <Input
                    {...field}
                    className="col-xl"
                    label="Salário Mensal em Reais"
                    inputOptions={{
                      classname: 'd-flex align-items-center'
                    }}
                    onChange={(e: any) => {
                      field.onChange(
                        genericMaskWithTwoZeroWithPoint(e.target.value)
                      )
                    }}
                    hasCustom
                    hasErrorCustom
                    errors={errors}
                  />
                )
              }}
            />
            <Controller
              shouldUnregister={true}
              control={control}
              name={formPrefix + '.' + 'yes.has_own_immobile'}
              rules={handleGetRules(verifyIfCheckboxValueIsEqualToYes)}
              render={({ field }) => (
                <Select
                  className="col-xl"
                  label="Tem Imóvel Próprio?"
                  options={[
                    {
                      name: 'Não',
                      value: 'Não'
                    },
                    {
                      name: 'Sim, imóveis em meu nome',
                      value: 'Sim, imóveis em meu nome'
                    },
                    {
                      name: 'Sim, imóveis familiares mas não estão em meu nome',
                      value: 'Sim, imóveis familiares mas não estão em meu nome'
                    }
                  ]}
                  blank
                  defaultValue=""
                  errors={errors}
                  {...field}
                />
              )}
            />
          </div>
          <CheckboxWithForm
            changeTab={changeTab}
            label="Possui outras fontes de rendas? (Comissões, pensões, alugueis, etc)"
            positive={{
              name: formPrefix + '.yes.have_other_sources_income.check',
              rules: handleGetRules(verifyIfCheckboxValueIsEqualToYes)
            }}
            negative={{
              name: formPrefix + '.yes.have_other_sources_income.check',
              rules: handleGetRules(verifyIfCheckboxValueIsEqualToYes)
            }}
          >
            <div className="row mb-5">
              <Controller
                shouldUnregister={true}
                control={control}
                name={formPrefix + '.' + 'yes.added_value_of_extra_income'}
                rules={handleGetRules(verifyIfCheckboxValueIsEqualToYes)}
                render={({ field }) => {
                  return (
                    <Input
                      {...field}
                      register={register}
                      className="col-xl"
                      label="Valor das rendas extras somadas"
                      rules={handleGetRules(verifyIfCheckboxValueIsEqualToYes)}
                      inputOptions={{
                        classname: 'd-flex align-items-center'
                      }}
                      onChange={(e: any) => {
                        field.onChange(
                          genericMaskWithTwoZeroWithPoint(e.target.value)
                        )
                      }}
                      hasValidationParent={{
                        parent_name:
                          formPrefix + '.yes.have_other_sources_income.check'
                      }}
                      hasCustom
                      hasErrorCustom
                      errors={errors}
                    />
                  )
                }}
              />
            </div>
          </CheckboxWithForm>

          <div className="row mb-5">
            <Textarea
              label="Descreva suas funções diárias e descreva quais são suas fontes de renda extras"
              name={formPrefix + '.' + 'yes.current_position'}
              style={{ minHeight: 100 }}
              register={register}
              rules={handleGetRules(verifyIfCheckboxValueIsEqualToYes)}
              hasErrorCustom
              errors={errors}
              hasCustom
            />
          </div>

          <CheckboxWithForm
            changeTab={changeTab}
            label="Declarou imposto de renda no último período?"
            positive={{
              name: formPrefix + '.' + 'yes.declared_income_tax.check',
              rules: handleGetRules(verifyIfCheckboxValueIsEqualToYes)
            }}
            negative={{
              name: formPrefix + '.' + 'yes.declared_income_tax.check',
              rules: handleGetRules(verifyIfCheckboxValueIsEqualToYes)
            }}
          >
            <div className="row mb-5">
              <Controller
                shouldUnregister={true}
                control={control}
                name={formPrefix + '.' + 'yes.declared_income_tax_value'}
                render={({ field }) => {
                  return (
                    <Input
                      {...field}
                      register={register}
                      className="col-xl"
                      label="Informe qual valor bruto"
                      inputOptions={{
                        classname: 'd-flex align-items-center'
                      }}
                      onChange={(e: any) => {
                        field.onChange(
                          genericMaskWithTwoZeroWithPoint(e.target.value)
                        )
                      }}
                      hasCustom
                      hasErrorCustom
                      errors={errors}
                    />
                  )
                }}
              />
            </div>
          </CheckboxWithForm>
        </div>
        <div
          // isShow={verifyIfCheckboxValueIsEqualToStudent ? 'show' : 'hidden'}
          // id="form_job_student"
          {...studentCollapse?.getCollapseProps()}
        >
          <div className="row mb-5 ">
            <Input
              register={register}
              className="col-xl"
              label="Nome da Escola"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              name={formPrefix + '.student.course_name'}
              rules={handleGetRules(verifyIfCheckboxValueIsEqualToStudent)}
              errors={errors}
              hasErrorCustom
              hasCustom
            />
            <Input
              register={register}
              className="col-xl"
              label="Curso que estuda"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              name={formPrefix + '.' + 'student.course_studying'}
              rules={handleGetRules(verifyIfCheckboxValueIsEqualToStudent)}
              errors={errors}
              hasErrorCustom
              hasCustom
            />
            <Input
              register={register}
              className="col-xl"
              label="Período ou série"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              name={formPrefix + '.' + 'student.series'}
              rules={handleGetRules(verifyIfCheckboxValueIsEqualToStudent)}
              errors={errors}
              hasErrorCustom
              hasCustom
            />
          </div>
          <div className="row mb-5 ">
            <Controller
              shouldUnregister={true}
              name={formPrefix + '.student.date_entered_institution'}
              rules={handleGetRules(verifyIfCheckboxValueIsEqualToStudent)}
              render={({ field }) => (
                <DatePickerCustom
                  popperProps={{ strategy: 'fixed' }}
                  locale="ptBR"
                  className="col-xl"
                  register={register}
                  inputOptions={{
                    classname: 'd-flex align-items-center'
                  }}
                  label="Data que entrou na instituição de ensino"
                  dateFormat="dd/MM/yyyy"
                  placeholderText="DD/MM/AAAA"
                  hasCustom
                  {...field}
                  errors={errors}
                  hasErrorCustom
                  customInput={
                    <IMaskInput
                      mask={Date}
                      pattern={'d/m/Y'}
                      format={date => {
                        return moment(date).format(inputMaskFormat)
                      }}
                      parse={value => {
                        return moment(value, inputMaskFormat).toDate()
                      }}
                    />
                  }
                />
              )}
            />

            <Input
              register={register}
              className="col-xl"
              label="Telefone com DDD"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              name={formPrefix + '.' + 'student.phone'}
              rules={handleGetRules(verifyIfCheckboxValueIsEqualToStudent)}
              errors={errors}
              hasErrorCustom
              hasCustom
            />
          </div>

          <h3>Endereço completo</h3>

          <div className="row mb-5">
            <Controller
              shouldUnregister={true}
              control={control}
              name={formPrefix + '.' + 'student.address.zip_code'}
              rules={handleGetRules(verifyIfCheckboxValueIsEqualToStudent)}
              render={({ field }) => (
                <Input
                  register={register}
                  className="col-xl-2"
                  label="CEP"
                  inputOptions={{
                    classname: 'd-flex align-items-center'
                  }}
                  hasErrorCustom
                  mask={{
                    mask: INPUT_MASK.ZIP_CODE,
                    type: 'zipCode',
                    prefixInputsData: formPrefix + '.student.address.',
                    customFields: [
                      {
                        name: 'state',
                        type: 'state',
                        api_field: 'uf'
                      }
                    ]
                  }}
                  maxLength={10}
                  hasCustom
                  errors={errors}
                  {...field}
                />
              )}
            />
            <Input
              register={register}
              className="col-xl"
              label="Rua, nº, complemento"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              name={formPrefix + '.' + 'student.address.street'}
              rules={handleGetRules(verifyIfCheckboxValueIsEqualToStudent)}
              errors={errors}
              hasErrorCustom
              hasCustom
            />
            {/* <Input
              register={register}
              className="col-xl-2"
              label="Número"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              name={formPrefix + '.' + 'student.address.number'}
              rules={handleGetRules(verifyIfCheckboxValueIsEqualToStudent)}
              errors={errors}
              hasErrorCustom
              hasCustom
            /> */}
          </div>
          <div className="row mb-5">
            <Input
              register={register}
              className="col-xl"
              label="Bairro"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              name={formPrefix + '.' + 'student.address.district'}
              rules={handleGetRules(verifyIfCheckboxValueIsEqualToStudent)}
              errors={errors}
              hasErrorCustom
              hasCustom
            />
            <Input
              register={register}
              className="col-xl"
              label="Cidade"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              name={formPrefix + '.' + 'student.address.city'}
              rules={handleGetRules(verifyIfCheckboxValueIsEqualToStudent)}
              errors={errors}
              hasErrorCustom
              hasCustom
            />
            <Controller
              shouldUnregister={true}
              control={control}
              name={formPrefix + '.' + 'student.address.state'}
              rules={handleGetRules(verifyIfCheckboxValueIsEqualToStudent)}
              render={({ field }) => (
                <Select
                  className="col-xl"
                  label="Estado"
                  labelOptions={{ classname: 'col-xl' }}
                  selectOptions={{
                    classname: 'col-xl'
                  }}
                  options={states}
                  {...field}
                  errors={errors}
                  // disabled={
                  //   countryValue?.country?.toLowerCase() !== 'brasil'
                  // }
                  // onChange={handleSelectState}
                  // value={selectedValue?.state || ''}
                  // controlled
                  blank
                />
              )}
            />
          </div>

          <CheckboxWithForm
            changeTab={changeTab}
            label="Possui renda como estudante (estágio, bolsa, etc)"
            positive={{
              name: formPrefix + '.student.income_student.check',
              rules: handleGetRules(verifyIfCheckboxValueIsEqualToStudent)
            }}
            negative={{
              name: formPrefix + '.student.income_student.check',
              rules: handleGetRules(verifyIfCheckboxValueIsEqualToStudent)
            }}
          >
            <div className="row mb-5">
              <Controller
                shouldUnregister={true}
                control={control}
                name={formPrefix + '.' + 'student.income_student_value'}
                rules={handleGetRules(verifyIfCheckboxValueIsEqualToStudent)}
                render={({ field }) => {
                  return (
                    <Input
                      {...field}
                      register={register}
                      className="col-xl"
                      label="Valor renda como estudante"
                      inputOptions={{
                        classname: 'd-flex align-items-center'
                      }}
                      hasValidationParent={{
                        parent_name:
                          formPrefix + '.student.income_student.check'
                      }}
                      errors={errors}
                      onChange={(e: any) => {
                        field.onChange(
                          genericMaskWithTwoZeroWithPoint(e.target.value)
                        )
                      }}
                      hasCustom
                      hasErrorCustom
                    />
                  )
                }}
              />
              <Input
                register={register}
                className="col-xl"
                label="Fonte pagadora da renda como estudante"
                inputOptions={{
                  classname: 'd-flex align-items-center'
                }}
                hasValidationParent={{
                  parent_name: formPrefix + '.student.income_student.check'
                }}
                name={
                  formPrefix +
                  '.' +
                  'student.paying_source_of_income_as_a_student'
                }
                errors={errors}
                hasCustom
                hasErrorCustom
              />
            </div>
          </CheckboxWithForm>
        </div>
        <ContainerAnimation
          isShow={verifyIfCheckboxValueIsEqualToRetiree ? 'show' : 'hidden'}
          id="form_job_retiree"
        >
          <p>
            Informações sobre ocupações anteriores (trabalho, estudos, serviço
            militar) Trabalhos anteriores ao atual Informar os 2 últimos locais
            de trabalho (nos últimos 5 anos) como empregado ou empregador, sem
            contar o atual
          </p>
          <div className="row mb-5 ">
            <Input
              register={register}
              className="col-xl"
              label="Nome completo da empresa"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              name={formPrefix + '.' + 'retiree.company_name_1'}
              rules={handleGetRules(verifyIfCheckboxValueIsEqualToRetiree)}
              errors={errors}
              hasErrorCustom
              hasCustom
            />
          </div>
          <h3>Endereço completo</h3>

          <div className="row mb-5">
            <Controller
              shouldUnregister={true}
              control={control}
              name={formPrefix + '.' + 'retiree.address_1.zip_code'}
              rules={handleGetRules(verifyIfCheckboxValueIsEqualToRetiree)}
              render={({ field }) => (
                <Input
                  register={register}
                  className="col-xl-2"
                  label="CEP"
                  inputOptions={{
                    classname: 'd-flex align-items-center'
                  }}
                  hasErrorCustom
                  mask={{
                    mask: INPUT_MASK.ZIP_CODE,
                    type: 'zipCode',
                    prefixInputsData: formPrefix + '.retiree.address_1.',
                    customFields: [
                      {
                        name: 'state',
                        type: 'state',
                        api_field: 'uf'
                      }
                    ]
                  }}
                  maxLength={10}
                  hasCustom
                  {...field}
                  errors={errors}
                />
              )}
            />
            <Input
              register={register}
              className="col-xl"
              label="Rua, nº, complemento"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              name={formPrefix + '.' + 'retiree.address_1.street'}
              rules={handleGetRules(verifyIfCheckboxValueIsEqualToRetiree)}
              hasErrorCustom
              hasCustom
            />
            {/* <Input
              register={register}
              className="col-xl-2"
              label="Número"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              name={formPrefix + '.' + 'retiree.address_1.number'}
              rules={handleGetRules(verifyIfCheckboxValueIsEqualToRetiree)}
              errors={errors}
              hasErrorCustom
              hasCustom
            /> */}
          </div>
          <div className="row mb-5">
            <Input
              register={register}
              className="col-xl"
              label="Bairro"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              name={formPrefix + '.' + 'retiree.address_1.district'}
              rules={handleGetRules(verifyIfCheckboxValueIsEqualToRetiree)}
              errors={errors}
              hasErrorCustom
              hasCustom
            />
            <Input
              register={register}
              className="col-xl"
              label="Cidade"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              name={formPrefix + '.' + 'retiree.address_1.city'}
              rules={handleGetRules(verifyIfCheckboxValueIsEqualToRetiree)}
              errors={errors}
              hasErrorCustom
              hasCustom
            />
            <Select
              className="col-xl"
              label="Estado"
              labelOptions={{ classname: 'col-xl' }}
              selectOptions={{
                classname: 'col-xl'
              }}
              name={formPrefix + '.' + 'retiree.address_1.state'}
              rules={handleGetRules(verifyIfCheckboxValueIsEqualToRetiree)}
              errors={errors}
              options={states}
              register={register}
              blank
            />
            <Input
              register={register}
              className="col-xl"
              label="Pais"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              name={formPrefix + '.' + 'retiree.address_1.country'}
              rules={handleGetRules(verifyIfCheckboxValueIsEqualToRetiree)}
              errors={errors}
              hasErrorCustom
              hasCustom
            />
          </div>

          <div className="row mb-5 ">
            <Input
              register={register}
              className="col-xl"
              label="Nome completo do supervisor / gerente"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              name={formPrefix + '.' + 'retiree.fullname_manager_1'}
              rules={handleGetRules(verifyIfCheckboxValueIsEqualToRetiree)}
              errors={errors}
              hasErrorCustom
              hasCustom
            />
          </div>
          <div className="row mb-5 ">
            <Input
              register={register}
              className="col-xl"
              label="Telefone com DDD"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              name={formPrefix + '.' + 'retiree.phone_1'}
              rules={handleGetRules(verifyIfCheckboxValueIsEqualToRetiree)}
              errors={errors}
              hasErrorCustom
              hasCustom
            />
            <Input
              register={register}
              className="col-xl"
              label="Último cargo"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              name={formPrefix + '.' + 'retiree.last_job_1'}
              rules={handleGetRules(verifyIfCheckboxValueIsEqualToRetiree)}
              errors={errors}
              hasErrorCustom
              hasCustom
            />
          </div>

          <div className="row mb-5 ">
            <Controller
              shouldUnregister={true}
              control={control}
              name={formPrefix + '.' + 'retiree.adimission_date' + '_1'}
              rules={handleGetRules(verifyIfCheckboxValueIsEqualToRetiree)}
              render={({ field }) => (
                <DatePickerCustom
                  popperProps={{ strategy: 'fixed' }}
                  locale="ptBR"
                  className="col-xl"
                  register={register}
                  inputOptions={{ classname: 'd-flex align-items-center' }}
                  label="Data de admissão"
                  dateFormat="dd/MM/yyyy"
                  placeholderText="DD/MM/AAAA"
                  hasCustom
                  maxDate={new Date()}
                  // selected={birthDate}
                  {...field}
                  rules={handleGetRules(verifyIfCheckboxValueIsEqualToRetiree)}
                  errors={errors}
                  hasErrorCustom
                  customInput={
                    <IMaskInput
                      mask={Date}
                      pattern={'d/m/Y'}
                      format={date => {
                        return moment(date).format(inputMaskFormat)
                      }}
                      parse={value => {
                        return moment(value, inputMaskFormat).toDate()
                      }}
                    />
                  }
                  // onChange={(e: any) => {
                  //   field.onChange(e)
                  //   setBirthDate(e)
                  // }}
                />
              )}
            />
            <Controller
              shouldUnregister={true}
              control={control}
              // name={formPrefix + '.' + admissionDate.name}
              name={formPrefix + '.' + 'retiree.shutdown_date_1'}
              rules={handleGetRules(verifyIfCheckboxValueIsEqualToRetiree)}
              render={({ field }) => (
                <DatePickerCustom
                  popperProps={{ strategy: 'fixed' }}
                  locale="ptBR"
                  className="col-xl"
                  register={register}
                  inputOptions={{ classname: 'd-flex align-items-center' }}
                  label="Data de desligamento"
                  dateFormat="dd/MM/yyyy"
                  placeholderText="DD/MM/AAAA"
                  hasCustom
                  {...field}
                  rules={handleGetRules(verifyIfCheckboxValueIsEqualToRetiree)}
                  errors={errors}
                  hasErrorCustom
                  customInput={
                    <IMaskInput
                      mask={Date}
                      pattern={'d/m/Y'}
                      format={date => {
                        return moment(date).format(inputMaskFormat)
                      }}
                      parse={value => {
                        return moment(value, inputMaskFormat).toDate()
                      }}
                    />
                  }
                />
              )}
            />
          </div>
          <div className="row mb-5">
            <Textarea
              label="Descreva brevemente suas funções"
              name={formPrefix + '.' + 'retiree.briefly_describe_your_duties_1'}
              style={{ minHeight: 100 }}
              rules={handleGetRules(verifyIfCheckboxValueIsEqualToRetiree)}
              errors={errors}
              hasErrorCustom
              hasCustom
            />
          </div>
        </ContainerAnimation>
        <ContainerAnimation
          isShow={verifyIfCheckboxValueIsEqualToFromHome ? 'show' : 'hidden'}
          id="form_job_form_home"
        >
          <></>
        </ContainerAnimation>
        <ContainerAnimation
          isShow={verifyIfCheckboxValueIsEqualToNo ? 'show' : 'hidden'}
          id="form_job_no"
        >
          <></>
        </ContainerAnimation>
      </div>
    </div>
  )
}
