import styled, { css } from 'styled-components'
import Tooltip from '../../../components/Tooltip'

export const ContainerHeader = styled.div`
  background-color: #fff;
  box-shadow: 0px 4px 6px 0px rgb(12 0 46 / 6%);
  margin-bottom: 20px;
  height: 64px;
`

export const WrapperContent = styled.div`
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  height: 100%;
`

export const ContainerLogo = styled.div``

export const ContainerWrapperContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  .mobile-margin {
    input {
      margin-left: 15px;
    }
  }
  input:disabled {
    background-color: #eaeaea !important;
  }
  select:disabled {
    background-color: #eaeaea !important;
  }
  .mw-xs {
    @media (min-width: 1200px) {
      input {
        max-width: 236px;
      }
    }
  }
`

export const Table = styled.table`
  @media (max-width: 1200px) {
    thead.table-border {
      tr {
        th {
          padding: 5px;
        }
      }
    }
    /* border: 1px solid #ccc; */
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;

    tr {
      /* background-color: #f8f8f8;
      border: 1px solid #ddd; */
      padding: 0.35em;
    }

    th,
    td {
      padding: 0.625em;
      text-align: left;
    }

    th {
      font-size: 0.85em;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }
    border: 0;

    thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    tr {
      /* border-bottom: 3px solid #ddd; */
      display: block;
      margin-bottom: 0.625em;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, auto));
    }

    td {
      /* border-bottom: 1px solid #ddd; */
      font-size: 1em;
      text-align: left;
      display: flex;
      flex-wrap: wrap;
      font-weight: 600;
      max-width: 250px;
    }

    td::before {
      content: attr(data-label);
      /* float: left; */
      /* font-weight: bold; */
      width: 100%;
      font-weight: 500;
      text-transform: uppercase;
    }

    td:last-child {
      border-bottom: 0;
    }
    .responsive-align {
      display: flex;
      justify-content: start !important;
      align-items: center;
    }
  }
`
export const Row = styled.tr`
  background: #fbfcfd;
`

export const LinkContainer = styled.div`
  display: flex;
  justify-content: center;

  > div {
    margin: 0px 0.4em;

    cursor: pointer;
  }

  a + a {
    margin-left: 5px;
  }
`

export const Error = styled(Tooltip)`
  height: 20px;
  width: fit-content;
  margin: 0;
  svg {
    margin: 0;
  }

  span {
    width: max-content;
    background: #c53030;
    color: #fff;
    &::before {
      border-color: #c53030 transparent;
    }
  }
`
