import React, { useCallback, useEffect, useState } from 'react'
import DataTable from '../../../../components/DataTable'
import { ClientData, nameEntity, nameSource } from '../domain/info'
import { headers } from '../domain/headers'
import { breadcrumbList } from '../domain/breadcrumb'
import Container from '../../../../components/Container'
import { StringParam, useQueryParam } from 'use-query-params'
import Form, { Select } from '../../../../components/Form'
import { Date as DatePicker } from '../../../../components/Form/date'
import Modal from '../../../../components/Modal'
import { FormClient } from '../components/Form'
import { useUpdateDataTable } from '../../../../hooks/dataTable'
const ClientList: React.FC = () => {
  const [status, setStatus] = useQueryParam('status', StringParam)
  const [date, setDate] = useQueryParam('date', StringParam)
  const [endDateQuery, setEndDateQuery] = useQueryParam('endDate', StringParam)
  const [clearFilter, setClearFilter] = useState<any>()
  const { updateDataTable } = useUpdateDataTable()
  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()

  const [modalEdit, setModalEdit] = useState(false)
  const [typesForms] = useState([
    {
      name: 'Passaporte',
      value: 'passport'
    },
    {
      name: 'Canadá',
      value: 'canada'
    },
    {
      name: 'México',
      value: 'mexico'
    },
    {
      name: 'Americano',
      value: 'american'
    }
  ])
  const [searchParameters, setSearchParameters] = useState<any[]>([])
  const [currentItemUpdate, setCurrentItemUpdate] = useState<ClientData>(
    {} as ClientData
  )
  const handleClearFilter = useCallback(() => {
    setClearFilter({
      status: '',
      start_date: '',
      end_date: ''
    })
    setStartDate(undefined)
    setEndDate(undefined)
    setSearchParameters([])
  }, [])
  const handleSetFilter = useCallback(data => {
    setSearchParameters([data])
    setStatus('')
    setDate('')
    setEndDateQuery('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    setSearchParameters([{ status, start_date: date, end_date: endDateQuery }])
    setStatus('')
    setDate('')
    setEndDateQuery('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handleClickOnClose = () => {
    setModalEdit(false)
    updateDataTable()
  }
  return (
    <>
      <Container pageTitle="Listagem" breadcrumb={breadcrumbList} tools={[]}>
        <Form onSubmit={handleSetFilter} defaultValues={clearFilter}>
          <div className="form">
            <div className="row mb-5">
              <DatePicker
                className="col-md-3"
                name="start_date"
                label="Início"
                selected={startDate}
                onChange={date => setStartDate(date)}
                controlled
              />
              <DatePicker
                label="Término"
                className="col-md-3"
                name="end_date"
                selected={endDate}
                onChange={date => setEndDate(date)}
                controlled
              />
              <Select
                className="col-md-3"
                label="Tipo de Formulário"
                name="type"
                options={typesForms}
                blank
                defaultValue=""
              />
              <Select
                className="col-md-3"
                name="status"
                label="Status"
                options={[
                  {
                    name: 'Novo',
                    value: 'new'
                  },
                  {
                    name: 'Em Andamento',
                    value: 'in progress'
                  },
                  {
                    name: 'Concluido',
                    value: 'concluded'
                  }
                ]}
                blank
                defaultValue=""
              />
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <div className="col-md-2 d-flex justify-content-end">
              <button
                type="reset"
                className="btn btn-light-primary"
                onClick={handleClearFilter}
              >
                Limpar
              </button>
            </div>
            <div className="col-md-1 d-flex justify-content-end">
              <button type="submit" className="btn btn-primary">
                Filtrar
              </button>
            </div>
          </div>
        </Form>
        <DataTable
          source={nameSource}
          entity={nameEntity}
          format={{ orderBy: 'name' }}
          notHasChildren
          headers={headers}
          searchParameters={[{ ...searchParameters[0] }]}
          actions={[
            {
              name: 'Atualizar',
              title: 'Atualizar',
              spanIcon: 'fa fa-edit me-5',
              target: '_blank',
              location: (item: ClientData) => {
                let url
                if (item?.type?.toLowerCase() === 'passaporte') {
                  url = '/forms/passport'
                }
                if (item?.type?.toLowerCase() === 'canadá') {
                  url = '/forms/canada'
                }
                if (item?.type?.toLowerCase() === 'méxico') {
                  url = '/forms/mexico'
                }
                if (item?.type?.toLowerCase() === 'americano') {
                  url = '/forms/american'
                }
                const convertedId = btoa(`${item.id}`)
                return {
                  pathname: `${url}?B3C1836D42ADD8F9B5C41211C401838C=${convertedId}`
                }
              }
            },
            {
              name: 'Status',
              title: 'Mudar Status',
              spanIcon: 'fa-regular fa-clipboard',
              target: '_blank',
              onClick: (item: ClientData) => {
                setModalEdit(true)
                setCurrentItemUpdate(item)
              }
            }
          ]}
        />
      </Container>
      {modalEdit && (
        <Modal
          onClickButtonCancel={handleClickOnClose}
          isOpenModal={modalEdit}
          pageTitle={'Editar'}
          Children={
            <FormClient
              typeForm="update"
              initialValues={{
                ...currentItemUpdate,
                idUpdate: currentItemUpdate?.id
              }}
              isOpenInModal={{
                handleOnClose: handleClickOnClose,
                idParent: currentItemUpdate?.id
              }}
            />
          }
        />
      )}
    </>
  )
}

export default ClientList
