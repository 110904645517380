import React, { useEffect, useState } from 'react'
import {
  RegisterOptions,
  useController,
  useFormContext,
  UseFormRegister
} from 'react-hook-form'
import DatePicker, {
  ReactDatePickerProps,
  registerLocale
} from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ptBR from 'date-fns/locale/pt-BR'
import { DatePickerContainer, Error } from './styles'
import { FiAlertCircle } from 'react-icons/fi'
import moment from 'moment'
import { TooltipComponent } from '../TooltipComponent'
import { checkFormatDates } from '../../utlis/checkDateFormat'
import { IMaskInput } from 'react-imask'
registerLocale('ptBR', ptBR)

export interface DateProps extends Omit<ReactDatePickerProps, 'onChange'> {
  register?: UseFormRegister<any>
  name?: string
  rules?: RegisterOptions
  hasError?: any
  errors?: any
  label?: string
  controlled?: boolean
  customDatepicker?: boolean
  fullControlled?: boolean
  price?: boolean
  hasCustom?: boolean
  labelOptions?: any
  inputOptions?: any
  hasErrorCustom?: boolean
  onChange?: (date: Date) => void
  selected?: Date
  tooltip?: {
    message: string
    classname?: string
    label?: string
    container?: {
      classname: string
    }
  }
  hasValidationParent?: {
    parent_name: string
    condition?: string
  }
}

export function DatePickerCustom({
  register,
  name,
  label,
  rules,
  errors,
  hasError,
  className,
  labelOptions,
  inputOptions,
  hasCustom,
  tooltip,
  hasErrorCustom,
  hasValidationParent,
  onChange,
  ...rest
}: DateProps) {
  const methods = useFormContext()
  const dateValue = methods?.watch(name)
  const [customRules, setCustomRules] = useState(rules)
  useEffect(() => {
    if (hasValidationParent) {
      const watchField = methods.watch(hasValidationParent.parent_name)
      setCustomRules({
        ...rules,
        required: watchField === (hasValidationParent?.condition || 'true')
      })
      return
    }
    setCustomRules(rules)
  }, [hasValidationParent, methods, rules])
  const keys = name.split('.')
  let error = keys.length === 2 ? errors?.[keys[0]]?.[keys[1]] : errors?.[name]
  error = keys.length === 3 ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]] : error
  error =
    keys.length === 4
      ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]]?.[keys[3]]
      : error
  error =
    keys.length === 5
      ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]]?.[keys[3]]?.[keys[4]]
      : error
  const selected = dateValue
    ? moment(dateValue, checkFormatDates(rest.value)).toDate()
    : null

  return hasCustom ? (
    <DatePickerContainer className={className + ' row'} erro={error}>
      {label && (
        <label
          htmlFor={name}
          className={
            'col-form-label fw-bold fs-6 form-label fw-bold fs-6 ' +
            labelOptions?.classname
          }
          style={labelOptions?.styles}
        >
          {label}
        </label>
      )}
      <div className={inputOptions?.classname}>
        <DatePicker
          dateFormat="P"
          {...(register && register(name, customRules))}
          locale="ptBR"
          className="form-control form-control-lg form-control-solid"
          selected={selected}
          onChange={onChange}
          autoComplete="no-fill"
          customInput={
            <IMaskInput
              mask={Date}
              pattern={'d/m/Y'}
              format={date => {
                return moment(date).format('DD/MM/YYYY')
              }}
              parse={value => {
                return moment(value, 'DD/MM/YYYY').toDate()
              }}
            />
          }
          {...rest}
        />
        {/* {error?.message ? (
          hasErrorCustom ? (
            <p className="error">{error.message}</p>
          ) : (
            <Error title={error.message}>
              <FiAlertCircle color="#c53030" size={20} />
            </Error>
          )
        ) : undefined}
        {hasError?.error && (
          <Error title={hasError?.message}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )} */}
        {/* {error?.type === 'required' ? (
          hasErrorCustom ? (
            <p className="error">
              {error?.message || `O campo ${label} é obrigatório`}
            </p>
          ) : undefined
        ) : undefined} */}
        {tooltip && (
          <TooltipComponent
            label={tooltip.label || ''}
            classname={tooltip.classname}
            options={{
              icon: {
                styles: {
                  width: 36,
                  height: 36
                }
              }
            }}
            {...tooltip}
          />
        )}
      </div>
    </DatePickerContainer>
  ) : (
    <DatePickerContainer className={className} erro={error}>
      {label && (
        <label
          htmlFor={name}
          className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
        >
          {label}
        </label>
      )}
      <div>
        <DatePicker
          dateFormat="P"
          // {...(register && register(name, rules))}
          locale="ptBR"
          className="form-control form-control-lg form-control-solid"
          selected={selected}
          onChange={onChange}
          customInput={
            <IMaskInput
              mask={Date}
              pattern={'d/m/Y'}
              format={date => {
                return moment(date).format('DD/MM/YYYY')
              }}
              parse={value => {
                return moment(value, 'DD/MM/YYYY').toDate()
              }}
            />
          }
          {...rest}
        />
        {error?.message && (
          <Error title={'Obrigatório'}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
        {hasError?.error && (
          <Error title={'Obrigatório'}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
        {error?.type === 'required' && (
          <Error title={'Obrigatório'}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
      </div>
    </DatePickerContainer>
  )
}
