import React, { useCallback, useEffect, useState } from 'react'
import {
  ContainerHeader,
  ContainerLogo,
  ContainerWrapperContent,
  WrapperContent,
  ContainerAnimation
} from './styles'
import Form, {
  Checkbox,
  Input,
  Select,
  Textarea
} from '../../../components/Form'
import Tabs from '../../../components/Tabs'
import { useLoading } from '../../../hooks/loading'
import api from '../../../services/api'
import { useToast } from '../../../hooks/toast'
import { Loading } from '../../../components/Loading'
import { useHistory, useLocation } from 'react-router-dom'
import { Date as DatePicker } from '../../../components/Form/date'
import { IMaskInput } from 'react-imask'
import moment from 'moment'
import Tab from '../../../components/Tabs/Tab'
import { Alert } from '../../../components/Alert'
import logo from '../../../assets/media/logos/logo.png'
import { StringParam, useQueryParam } from 'use-query-params'
import { FormName } from './components/Forms/Name'
import { FormWork } from './components/Forms/Work'
import { FormGeneral } from './components/Forms/General'
import { FormMaritalStatus } from './components/Forms/MaritalStatus'
import { FormFamily } from './components/Forms/Family'
import { FormPeople } from './components/Forms/People'
type OptionsValue = {
  cetificationType: SelectOptionsValue[]
  gender: SelectOptionsValue[]
  maritalStatus: SelectOptionsValue[]
  previousPassportSituation: SelectOptionsValue[]
  raceColor: SelectOptionsValue[]
  reasonChange: SelectOptionsValue[]
  profession: SelectOptionsValue[]
  states: {
    UF: string
    cities: SelectOptionsValue[]
  }[]
  countries: SelectOptionsValue[]
  nationality: SelectOptionsValue[]
  reasonTravel: SelectOptionsValue[]
  statusLegal: SelectOptionsValue[]
  relationship: SelectOptionsValue[]
}
type SelectOptionsValue = {
  name: string
  value: string
}
type FormData = {
  id?: number
  full_name: string
  gender: string
  parentage: {
    name: string
    gender: string
  }[]
  race_color: string
  marital_status: string
  nationality: string
  country: string
  state: string
  city: string
  previousNames: {
    name: string
    reason: string
  }[]
  document_number: string
  issue_date: Date
  birth_date: Date
  issuing_body: string
  state_expedition: string
  cpf: string
  cpf_responsible: string
  certificate: {
    enrollment: string
    type: string
    number: string
    book: string
    sheet: string
    registry: string
    state_expedition: string
    city_expedition: string
  }
  previousPassport: {
    situation: string
    series: string
    number: string
    hasUpdateName: boolean
  }
  profession: string
  email: string
  email_confirmation: string
  applicant: {
    country: string
    zip_code: string
    state: string
    city: string
    street: string
    district: string
    ddd: string
    phone: string
  }
  emancipated: boolean
  international_adoption: boolean
  new_model_certificate: boolean
  cities?: any
  countryValue?: any
  sons?: any
}
export const Canada: React.FC = () => {
  const location = useLocation()
  const [id] = useQueryParam('B3C1836D42ADD8F9B5C41211C401838C', StringParam)
  const { activeLoading, disableLoading, loading } = useLoading()
  const [changeTab, setChangeTab] = useState('next')
  const { addToast } = useToast()
  const history = useHistory()
  const [activeTab, setActiveTab] = useState(0)
  const [optionsValue, setOptionsValue] = useState<OptionsValue>()
  const [birthDate, setBirthDate] = useState<Date>()
  const [issuePassportDate, setIssuePassportDate] = useState<Date>()
  const [validatePassportDate, setValidatePassportDate] = useState<Date>()
  const [issueRGDate, setIssueRGDate] = useState<Date>()
  const [livedAbroadStartDate, setLivedAbroadStartDate] = useState<Date>()
  const [livedAbroadEndDate, setLivedAbroadEndDate] = useState<Date>()
  const [departureDate, setDepartureDate] = useState<Date>()
  const [returnDate, setReturnDate] = useState<Date>()
  const [courseStartDate, setCourseStartDate] = useState<Date>()
  const [courseEndDate, setCourseEndDate] = useState<Date>()
  const [dataForm, setDataForm] = useState<any>({})
  const [copyDataForm, setCopyDataForm] = useState<FormData>({} as FormData)
  const [hasOtherName, setHasOtherName] = useState(false)
  const [hasLivedAbroad, setHasLivedAbroad] = useState(false)
  const [hasCourse, setHasCourse] = useState(false)
  const [peoples, setPeoples] = useState([{ id: 0, isActive: '' }])
  const [familiars, setFamiliars] = useState([{ id: 0, isActive: '' }])
  const [sons, setSons] = useState([{ id: 0, isActive: '' }])
  const [brothers, setBrothers] = useState([{ id: 0, isActive: '' }])
  const [attachments, setAttachments] = useState<
    { id: number; url?: string; name?: string; file?: File }[]
  >([{ id: 1 }])
  const [optionsSimple] = useState([
    { name: 'Sim', value: 'Sim' },
    { name: 'Não', value: 'Não' }
  ])

  const inputMaskFormat = 'DD/MM/YYYY'
  const [alert, setIsActiveAlert] = useState<{
    isActive: boolean
    id: number
    name: string
  }>({
    id: 0,
    isActive: false,
    name: ''
  })

  const formatingDateFields = useCallback((originDate: any) => {
    const copyData = Object.entries(originDate)
    for (const [key, value] of copyData) {
      if (value) {
        if (moment(value, 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]', true).isValid()) {
          originDate[key] = moment(value).format(inputMaskFormat)
        }
        if (typeof value === 'object') {
          formatingDateFields(value)
        }
      }
    }
  }, [])

  useEffect(() => {
    window.addEventListener('keydown', event => {
      if (event.key === 'Enter') {
        event.preventDefault()
      }
    })
    return () => {
      window.removeEventListener('keydown', event => {
        if (event.key === 'Enter') {
          event.preventDefault()
        }
      })
    }
  }, [])

  useEffect(() => {
    async function loadData() {
      activeLoading()
      if (id) {
        const convertedId = atob(`${id}`)
        try {
          const response = await api.get(
            `/commercial/public/clients/view/${convertedId}`
          )
          const { data } = response
          const locationData = data.values as any
          const copyData: any = { ...locationData }
          setSons(copyData.sons.length ? copyData.sons : sons)
          setBrothers(copyData.brothers.length ? copyData.brothers : brothers)
          setPeoples(copyData.peoples.length ? copyData.peoples : peoples)
          setFamiliars(
            copyData.familiars.length ? copyData.familiars : familiars
          )
          if (copyData.attachments?.length) {
            const copyAttachment: any[] = []
            for (const attachment of copyData.attachments) {
              if (attachment) {
                fetch(attachment.url)
                  .then(response => response.blob())
                  .then(blob => {
                    const file = new File([blob], attachment.name)
                    copyAttachment[attachment.id] = {
                      ...attachment,
                      file
                    }
                  })
              }
            }
            setAttachments(copyAttachment)
          }
          setDataForm({
            id: data.id,
            ...copyData
          })
          setCopyDataForm({
            id: data.id,
            ...copyData
          })
          disableLoading()
        } catch (err) {
          disableLoading()
          addToast({
            type: 'error',
            title: 'Error ao carregar o cliente',
            description:
              'Houve um error ao carregar o cliente, tente novamente mais tarde!'
          })
        }
      }
      disableLoading()
    }
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const onSubmit = useCallback(
    async formValues => {
      activeLoading()
      const data = {
        ...dataForm,
        ...formValues
      }

      formatingDateFields(data)
      const formData = new FormData()

      const attachmentsData =
        data?.attachments?.map(
          // eslint-disable-next-line array-callback-return
          (dataItem: any, index: number) => {
            if (dataItem) {
              const findAttachment = attachments.find(
                attachment => index === attachment.id
              )
              if (findAttachment) {
                if (findAttachment.file) {
                  return findAttachment
                }
                return dataItem
              }
            }
          }
        ) || []
      for (const attachment of attachmentsData) {
        if (attachment) {
          if (attachment[0]) {
            formData.append('attachments', attachment[0])
          }
          if (attachment.file) {
            formData.append('attachments', attachment.file)
          }
        }
      }
      delete data.attachments
      formData.append('values', JSON.stringify(data))
      formData.append('name', data.name)
      formData.append('email', data.email)
      formData.append('cpf', data.cpf)
      formData.append('type', 'canada')
      if (dataForm.id) {
        try {
          await api.put(
            `/commercial/public/clients/update/${dataForm.id}`,
            formData
          )
          disableLoading()
          addToast({
            type: 'success',
            title: 'Registro alterado',
            description: 'Registro alterado com sucesso'
          })
          history.go(0)
        } catch (error) {
          addToast({
            type: 'error',
            title: 'Erro ao adicionar registro',
            description:
              'Ocorreu um erro ao adicionar o registro, por favor, tente novamente.'
          })
          disableLoading()
        }
      } else {
        try {
          await api.post('/commercial/public/clients', formData)
          disableLoading()
          addToast({
            type: 'success',
            title: 'Registro criado',
            description: 'Registro criado com sucesso'
          })
          history.go(0)
        } catch (error) {
          addToast({
            type: 'error',
            title: 'Erro ao adicionar registro',
            description:
              'Ocorreu um erro ao adicionar o registro, por favor, tente novamente.'
          })
          disableLoading()
        }
      }
    },
    [
      activeLoading,
      addToast,
      attachments,
      dataForm,
      disableLoading,
      formatingDateFields,
      history
    ]
  )

  const handleChangePage = useCallback((type: string) => {
    if (type === 'next') {
      setChangeTab(() => {
        return 'next'
      })
    } else {
      setChangeTab(() => {
        return 'previous'
      })
    }
  }, [])

  const handleNextForm = useCallback(
    async (data: any) => {
      const formData = {
        ...JSON.parse(JSON.stringify(copyDataForm)),
        ...JSON.parse(JSON.stringify(dataForm)),
        ...JSON.parse(JSON.stringify(data))
      }

      if (data?.attachments) {
        const attachmentsData = data?.attachments
          ?.map(
            // eslint-disable-next-line array-callback-return
            (dataItem: any, index: number) => {
              if (dataItem) {
                const findAttachment = attachments.find(
                  attachment => index === attachment?.id
                )
                if (findAttachment) {
                  if (findAttachment.file) {
                    return findAttachment
                  }
                  return {
                    id: index,
                    name: dataItem[0]?.name,
                    file: dataItem[0]
                  }
                }
              }
            }
          )
          .filter((item: any) => item)
        setAttachments(attachmentsData)
      }
      setDataForm(formData)
      if (id) {
        setCopyDataForm({
          ...copyDataForm,
          ...formData
        })
      }
      if (changeTab === 'previous') {
        setActiveTab(activeTab - 1)
        handleChangePage('next')
      } else {
        setActiveTab(activeTab + 1)
      }
    },
    [
      activeTab,
      attachments,
      changeTab,
      copyDataForm,
      dataForm,
      handleChangePage,
      id
    ]
  )
  const loadOptions = useCallback(async () => {
    activeLoading()
    try {
      const { data } = await api.get('/options')
      setOptionsValue({
        ...data
      })
      disableLoading()
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao buscar indice',
        description:
          'Ocorreu um erro ao buscar o indice, por favor, tente novamente.'
      })
      disableLoading()
    }
  }, [activeLoading, addToast, disableLoading])
  useEffect(() => {
    loadOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlerClickButtonCancellAlert = () => {
    setIsActiveAlert({
      id: 0,
      isActive: false,
      name: ''
    })
  }
  const handlerClickButtonConfirmAlert = async () => {
    setIsActiveAlert({
      id: 0,
      isActive: false,
      name: ''
    })
  }

  const handleRemoveAttachments = useCallback(
    (id: number) => {
      const biggerId = Math.max(...attachments.map(attachment => attachment.id))

      const filterAttachments = attachments.map(attachment =>
        attachment && attachment.id === id
          ? {
              id: (biggerId || 1) + 1,
              url: null,
              name: null
            }
          : { ...attachment }
      )
      setAttachments(filterAttachments)
    },
    [attachments]
  )

  const handleDownloadAttachments = useCallback(
    (attachment: any) => {
      activeLoading()
      fetch(attachment.url)
        .then(response => response.blob())
        .then(blob => {
          disableLoading()
          const blobURL = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = blobURL
          a.download = attachment.name
          document.body.appendChild(a)
          a.click()
        })
    },
    [activeLoading, disableLoading]
  )

  return (
    <div>
      <Loading isActive={loading} />
      <div className="row gy-5 g-xl-8">
        <ContainerHeader>
          <WrapperContent>
            <ContainerLogo>
              <a href="/">
                <img
                  alt="Logo"
                  src={logo}
                  className="w-150px logo"
                  style={{ objectFit: 'cover' }}
                />
              </a>
            </ContainerLogo>
          </WrapperContent>
        </ContainerHeader>
      </div>
      <ContainerWrapperContent>
        <div className="gy-5 g-xl-8 ps-2">
          <h1>Preenchimento do formulário - Visto Canadense</h1>
          <div className="card px-10 py-3 mt-3">
            <Tabs
              classname="justify-content-between"
              setActiveTab={setActiveTab}
              activeTab={activeTab}
            >
              <Tab notClick title="Dados Pessoais" key={0}>
                <div className="form mt-5">
                  <Form
                    className="form"
                    onSubmit={handleNextForm}
                    defaultValues={dataForm}
                  >
                    <h1 className="mb-5">Informações Gerais</h1>
                    <div className="row mb-5">
                      <Input
                        className="col-xl"
                        label="Onde nos conheceu?"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        name="where_meet_us"
                        hasCustom
                        hasErrorCustom
                        tooltip={{
                          container: {
                            classname: 'ms-2'
                          },
                          classname: 'mw-30px',
                          message: `
                            <p style="max-width: 300px">
                              Informe onde nos conheceu
                            </p>`
                        }}
                        rules={{
                          required: changeTab !== 'previous'
                        }}
                      />
                    </div>
                    <FormName
                      changeTab={changeTab}
                      first={{ name: 'name' }}
                      second={{ name: 'lastName' }}
                    />
                    <div className="row mb-5 d-flex">
                      <p>Já teve algum outro nome?</p>
                      <div className="d-flex">
                        <Checkbox
                          className="col-xl-1"
                          label="Sim"
                          inputOptions={{
                            classname: 'd-flex align-items-center'
                          }}
                          name="hasOtherName"
                          type="radio"
                          value="true"
                          setData={setHasOtherName}
                          hasCustom
                          hasErrorCustom
                        />
                        <Checkbox
                          className="col-xl"
                          label="Não"
                          inputOptions={{
                            classname: 'd-flex align-items-center'
                          }}
                          name="hasOtherName"
                          type="radio"
                          hasCustom
                          rules={{ required: changeTab !== 'previous' }}
                          value="false"
                          setData={setHasOtherName}
                          tooltip={{
                            container: {
                              classname: 'ms-2'
                            },
                            classname: 'mw-30px',
                            message: `<p style="max-width: 300px">
                          Exemplo: Nome de solteira(o)
                            </p>`
                          }}
                        />
                      </div>
                    </div>

                    <div className="separator border-2 my-6"></div>

                    <ContainerAnimation
                      isShow={hasOtherName ? 'show' : 'hidden'}
                      id="name"
                    >
                      <FormName
                        changeTab={changeTab}
                        parentField={{
                          name: 'hasOtherName',
                          clearField: 'otheName'
                        }}
                        first={{ name: 'otheName.name' }}
                        second={{ name: 'otheName.lastName' }}
                      />
                    </ContainerAnimation>

                    <div className="row mb-5">
                      <Select
                        className="col-xl"
                        label="Sexo"
                        name="gender"
                        hasCustom
                        hasErrorCustom
                        rules={{ required: changeTab !== 'previous' }}
                        options={optionsValue?.gender || []}
                        blank
                        controlled
                      />
                      <DatePicker
                        popperProps={{ strategy: 'fixed' }}
                        locale="ptBR"
                        name="birth_date"
                        className="col-xl"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        label="Data de nascimento:"
                        dateFormat="dd/MM/yyyy"
                        placeholderText="DD/MM/AAAA"
                        hasCustom
                        hasErrorCustom
                        rules={{ required: changeTab !== 'previous' }}
                        selected={birthDate}
                        tooltip={{
                          container: {
                            classname: 'ms-2'
                          },
                          classname: 'mw-30px',
                          message: `<p style="max-width: 300px">
                          Informe a data de seu nascimento
                            </p>`
                        }}
                        maxDate={new Date()}
                        customInput={
                          <IMaskInput
                            mask={Date}
                            pattern={'d/m/Y'}
                            format={date => {
                              return moment(date).format(inputMaskFormat)
                            }}
                            parse={value => {
                              return moment(value, inputMaskFormat).toDate()
                            }}
                          />
                        }
                        onChange={date => {
                          setBirthDate(date)
                        }}
                        controlled
                      />
                    </div>

                    <div className="row mb-5">
                      <Input
                        className="col-xl"
                        label="Cidade de nascimento"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        controlled
                        name="city_birth"
                        tooltip={{
                          container: {
                            classname: 'ms-2'
                          },
                          classname: 'mw-30px',
                          message: `<p style="max-width: 300px">
                          Informe a cidade de nascimento
                            </p>`
                        }}
                        rules={{ required: changeTab !== 'previous' }}
                        hasCustom
                        hasErrorCustom
                      />
                      <Input
                        className="col-xl"
                        label="País de nascimento"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        name="country_birth"
                        tooltip={{
                          container: {
                            classname: 'ms-2'
                          },
                          classname: 'mw-30px',
                          message: `<p style="max-width: 300px">
                          Informe o país de seu nascimento
                            </p>`
                        }}
                        rules={{ required: changeTab !== 'previous' }}
                        hasCustom
                        hasErrorCustom
                      />
                    </div>

                    <div className="separator border-2 my-6"></div>

                    <div className="row mb-5 pe-5">
                      <p>Já morou fora do Brasil por mais de 6 meses?</p>
                      <div className="d-flex">
                        <Checkbox
                          className="col-xl-1"
                          label="Sim "
                          inputOptions={{
                            classname: 'd-flex align-items-center'
                          }}
                          name="hasLivedAbroad"
                          type="radio"
                          hasCustom
                          hasErrorCustom
                          rules={{ required: changeTab !== 'previous' }}
                          value="true"
                          setData={setHasLivedAbroad}
                          clearField={{
                            defaultedValues: {
                              livedAbroad_country: '',
                              livedAbroad_legal_status: '',
                              livedAbroad_start_date: '',
                              livedAbroad_end_date: ''
                            }
                          }}
                        />
                        <Checkbox
                          className="col-xl-1"
                          label="Não"
                          inputOptions={{
                            classname: 'd-flex align-items-center'
                          }}
                          name="hasLivedAbroad"
                          type="radio"
                          hasCustom
                          value="false"
                          setData={setHasLivedAbroad}
                          rules={{ required: changeTab !== 'previous' }}
                        />
                      </div>
                    </div>

                    <ContainerAnimation
                      isShow={hasLivedAbroad ? 'show' : 'hidden'}
                      height="600"
                      id="lived abrroad"
                    >
                      <div className="row mb-5 pe-5">
                        <Input
                          className="col-xl"
                          label="Em que país morou?"
                          inputOptions={{
                            classname: 'd-flex align-items-center'
                          }}
                          name="livedAbroad_country"
                          tooltip={{
                            container: {
                              classname: 'ms-2'
                            },
                            classname: 'mw-30px',
                            message: `<p style="max-width: 300px">
                            Informe o país que morou fora
                            </p>`
                          }}
                          rules={
                            changeTab !== 'previous'
                              ? { required: hasLivedAbroad }
                              : undefined
                          }
                          hasCustom
                          hasErrorCustom
                        />
                        <Select
                          className="col-xl"
                          label="Status legal"
                          selectOptions={{
                            classname: 'd-flex align-items-center'
                          }}
                          name="livedAbroad_legal_status"
                          tooltip={{
                            container: {
                              classname: 'ms-2'
                            },
                            classname: 'mw-30px',
                            message: `<p style="max-width: 300px">
                            Qual era seu status nessa país? Ex. Estudante, Trabalhador, etc.
                            </p>`
                          }}
                          options={optionsValue?.statusLegal || []}
                          rules={
                            changeTab !== 'previous'
                              ? { required: hasLivedAbroad }
                              : undefined
                          }
                          blank
                          hasErrorCustom
                          controlled
                          hasCustom
                        />
                      </div>
                      <div className="row mb-5 pe-5">
                        <DatePicker
                          popperProps={{ strategy: 'fixed' }}
                          locale="ptBR"
                          name="livedAbroad_start_date"
                          className="col-xl"
                          inputOptions={{
                            classname: 'd-flex align-items-center'
                          }}
                          label="Morou fora do Brasil de"
                          dateFormat="dd/MM/yyyy"
                          placeholderText="DD/MM/AAAA"
                          hasCustom
                          hasErrorCustom
                          selected={livedAbroadStartDate}
                          tooltip={{
                            container: {
                              classname: 'ms-2'
                            },
                            classname: 'mw-30px',
                            message: `<p style="max-width: 300px">
                            Data que saiu do Brasil para morar nesse país
                              </p>`
                          }}
                          rules={
                            changeTab !== 'previous'
                              ? { required: hasLivedAbroad }
                              : undefined
                          }
                          customInput={
                            <IMaskInput
                              mask={Date}
                              pattern={'d/m/Y'}
                              format={date => {
                                return moment(date).format(inputMaskFormat)
                              }}
                              parse={value => {
                                return moment(value, inputMaskFormat).toDate()
                              }}
                            />
                          }
                          onChange={date => {
                            setLivedAbroadStartDate(date)
                          }}
                          controlled
                        />
                        <DatePicker
                          popperProps={{ strategy: 'fixed' }}
                          locale="ptBR"
                          name="livedAbroad_end_date"
                          className="col-xl"
                          inputOptions={{
                            classname: 'd-flex align-items-center'
                          }}
                          label="Morou fora do Brasil até"
                          dateFormat="dd/MM/yyyy"
                          placeholderText="DD/MM/AAAA"
                          hasCustom
                          hasErrorCustom
                          selected={livedAbroadEndDate}
                          tooltip={{
                            container: {
                              classname: 'ms-2'
                            },
                            classname: 'mw-30px',
                            message: `<p style="max-width: 300px">
                            Quando retornou ao Brasil
                              </p>`
                          }}
                          rules={
                            changeTab !== 'previous'
                              ? { required: hasLivedAbroad }
                              : undefined
                          }
                          customInput={
                            <IMaskInput
                              mask={Date}
                              pattern={'d/m/Y'}
                              format={date => {
                                return moment(date).format(inputMaskFormat)
                              }}
                              parse={value => {
                                return moment(value, inputMaskFormat).toDate()
                              }}
                            />
                          }
                          onChange={date => {
                            setLivedAbroadEndDate(date)
                          }}
                          controlled
                        />
                      </div>
                    </ContainerAnimation>

                    <div className="separator border-2 my-6"></div>

                    <div className="row mb-5">
                      <Input
                        className="col-xl"
                        label="Número do passaporte"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        name="passport.number"
                        tooltip={{
                          container: {
                            classname: 'ms-2'
                          },
                          classname: 'mw-30px',
                          message: `<p style="max-width: 300px">
                          Entre com o número de seu passaporte. Inicia com 2 letras e segue com 6 números. Ex. FD123456
                            </p>`
                        }}
                        rules={{
                          required: changeTab !== 'previous'
                        }}
                        hasCustom
                        hasErrorCustom
                      />
                    </div>

                    <div className="row mb-5">
                      <DatePicker
                        popperProps={{ strategy: 'fixed' }}
                        locale="ptBR"
                        name="passport_issue_date"
                        className="col-xl"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        label="Data de emissão do passaporte"
                        dateFormat="dd/MM/yyyy"
                        placeholderText="DD/MM/AAAA"
                        hasCustom
                        hasErrorCustom
                        selected={issuePassportDate}
                        rules={{ required: changeTab !== 'previous' }}
                        tooltip={{
                          container: {
                            classname: 'ms-2'
                          },
                          classname: 'mw-30px',
                          message: `<p style="max-width: 300px">
                          Informe com atenção a data de emissão do passaporte
                            </p>`
                        }}
                        customInput={
                          <IMaskInput
                            mask={Date}
                            pattern={'d/m/Y'}
                            format={date => {
                              return moment(date).format(inputMaskFormat)
                            }}
                            parse={value => {
                              return moment(value, inputMaskFormat).toDate()
                            }}
                          />
                        }
                        onChange={date => {
                          setIssuePassportDate(date)
                        }}
                        controlled
                      />
                      <DatePicker
                        popperProps={{ strategy: 'fixed' }}
                        locale="ptBR"
                        name="passport_validate"
                        className="col-xl"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        label="Data de validade do passaporte"
                        dateFormat="dd/MM/yyyy"
                        placeholderText="DD/MM/AAAA"
                        hasCustom
                        hasErrorCustom
                        rules={{ required: changeTab !== 'previous' }}
                        selected={validatePassportDate}
                        tooltip={{
                          container: {
                            classname: 'ms-2'
                          },
                          classname: 'mw-30px',
                          message: `<p style="max-width: 300px">
                          Informe com atenção a data de vencimento do passaporte
                            </p>`
                        }}
                        customInput={
                          <IMaskInput
                            mask={Date}
                            pattern={'d/m/Y'}
                            format={date => {
                              return moment(date).format(inputMaskFormat)
                            }}
                            parse={value => {
                              return moment(value, inputMaskFormat).toDate()
                            }}
                          />
                        }
                        onChange={date => {
                          setValidatePassportDate(date)
                        }}
                        controlled
                      />
                    </div>
                    <div className="row mb-5">
                      <Input
                        className="col-xl"
                        label="Número do seu RG"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        controlled
                        mask={{
                          mask: Number
                        }}
                        name="rg.number"
                        rules={{ required: changeTab !== 'previous' }}
                        hasCustom
                        hasErrorCustom
                      />
                      <DatePicker
                        popperProps={{ strategy: 'fixed' }}
                        locale="ptBR"
                        name="rg_issued_date"
                        className="col-xl"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        label="Data de emissão do seu RG"
                        dateFormat="dd/MM/yyyy"
                        placeholderText="DD/MM/AAAA"
                        hasCustom
                        hasErrorCustom
                        selected={issueRGDate}
                        tooltip={{
                          container: {
                            classname: 'ms-2'
                          },
                          classname: 'mw-30px',
                          message: `<p style="max-width: 300px">
                          Informe com atenção a data de vencimento de emissão do seu RG
                            </p>`
                        }}
                        rules={{ required: changeTab !== 'previous' }}
                        customInput={
                          <IMaskInput
                            mask={Date}
                            pattern={'d/m/Y'}
                            format={date => {
                              return moment(date).format(inputMaskFormat)
                            }}
                            parse={value => {
                              return moment(value, inputMaskFormat).toDate()
                            }}
                          />
                        }
                        onChange={date => {
                          setIssueRGDate(date)
                        }}
                        controlled
                      />
                    </div>
                    <div className="row mb-5">
                      <Input
                        className="col-xl"
                        label="Número do CPF"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        name="cpf"
                        mask={{
                          mask: '000.000.000-00',
                          type: 'cpf'
                        }}
                        hasErrorCustom
                        controlled
                        rules={{
                          required: true,
                          minLength: {
                            value: 14,
                            message: 'Cpf Inválido'
                          }
                        }}
                        hasCustom
                      />
                    </div>

                    <div className="separator border-2 my-6"></div>

                    <div className="row mb-5">
                      <Input
                        className="col-xl"
                        label="CEP"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        hasErrorCustom
                        name="address.zip_code"
                        mask={{
                          mask: '00000-000',
                          type: 'zipCode',
                          prefixInputsData: 'address.'
                        }}
                        controlled
                        rules={{
                          required: changeTab !== 'previous',
                          minLength: { value: 8, message: 'Cep Inválido' }
                        }}
                        maxLength={10}
                        hasCustom
                      />
                      <Input
                        className="col-xl"
                        label="Endereço residencial"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        name="address.street"
                        rules={{ required: changeTab !== 'previous' }}
                        hasErrorCustom
                        hasCustom
                      />
                    </div>
                    <div className="row mb-5">
                      <Input
                        className="col-xl"
                        label="Número"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        name="address.number"
                        rules={{ required: changeTab !== 'previous' }}
                        hasErrorCustom
                        hasCustom
                      />
                      <Input
                        className="col-xl"
                        label="Complemento"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        name="address.complement"
                        hasCustom
                        hasErrorCustom
                      />
                    </div>
                    <div className="row mb-5">
                      <Input
                        className="col-xl"
                        label="Cidade"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        name="address.city"
                        rules={{ required: changeTab !== 'previous' }}
                        hasErrorCustom
                        hasCustom
                      />
                      <Input
                        className="col-xl"
                        label="Bairro"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        name="address.district"
                        rules={{ required: changeTab !== 'previous' }}
                        hasErrorCustom
                        hasCustom
                      />
                    </div>
                    <div className="row mb-5">
                      <Input
                        className="col-xl"
                        label="DDD + Telefone Comercial"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        name="commercial_phone"
                        mask={{
                          mask: '(00) 0000-0000'
                        }}
                        controlled
                        rules={{ required: changeTab !== 'previous' }}
                        hasErrorCustom
                        hasCustom
                      />
                      <Input
                        className="col-xl"
                        label="
                          DDD + Telefone Celular"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        mask={{
                          mask: '(00) 00000-0000'
                        }}
                        name="phone"
                        rules={{ required: changeTab !== 'previous' }}
                        controlled
                        hasErrorCustom
                        hasCustom
                      />
                    </div>
                    <div className="row mb-5">
                      <Input
                        className="col-xl"
                        label="Email"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        name="email"
                        rules={{
                          required: changeTab !== 'previous',

                          pattern: {
                            value: /\S+@\S+\.\S+/,
                            message: 'Email inválido'
                          }
                        }}
                        hasCustom
                        hasErrorCustom
                      />
                    </div>

                    <div className="separator border-2 my-6"></div>

                    <div className="row mb-5">
                      <Select
                        className="col-xl"
                        label="Motivo da viagem"
                        selectOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        name="reason_travel"
                        hasCustom
                        options={optionsValue?.reasonTravel || []}
                        blank
                        tooltip={{
                          container: {
                            classname: 'ms-2'
                          },
                          classname: 'mw-30px',
                          message: `<p style="max-width: 300px">
                          Qual é o motivo da sua viagem?
                            </p>`
                        }}
                        controlled
                      />
                    </div>

                    <div className="row mb-5">
                      <DatePicker
                        popperProps={{ strategy: 'fixed' }}
                        locale="ptBR"
                        name="departure_date"
                        className="col-xl"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        label="Data de ida"
                        dateFormat="dd/MM/yyyy"
                        placeholderText="DD/MM/AAAA"
                        hasCustom
                        hasErrorCustom
                        selected={departureDate}
                        rules={{ required: changeTab !== 'previous' }}
                        tooltip={{
                          container: {
                            classname: 'ms-2'
                          },
                          classname: 'mw-30px',
                          message: `<p style="max-width: 300px">
                          Quando pretende ir para o Canadá? Informe o dia que pretende desembarcar no Canadá
                            </p>`
                        }}
                        customInput={
                          <IMaskInput
                            mask={Date}
                            pattern={'d/m/Y'}
                            format={date => {
                              return moment(date).format(inputMaskFormat)
                            }}
                            parse={value => {
                              return moment(value, inputMaskFormat).toDate()
                            }}
                          />
                        }
                        onChange={date => {
                          setDepartureDate(date)
                        }}
                        controlled
                      />
                      <DatePicker
                        popperProps={{ strategy: 'fixed' }}
                        locale="ptBR"
                        name="return_date"
                        className="col-xl"
                        rules={{ required: changeTab !== 'previous' }}
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        label="Data de volta"
                        dateFormat="dd/MM/yyyy"
                        placeholderText="DD/MM/AAAA"
                        hasCustom
                        hasErrorCustom
                        selected={returnDate}
                        tooltip={{
                          container: {
                            classname: 'ms-2'
                          },
                          classname: 'mw-30px',
                          message: `<p style="max-width: 300px">
                          Qual a data de retorno? Informe a data que pretende voltar para o Brasil
                            </p>`
                        }}
                        customInput={
                          <IMaskInput
                            mask={Date}
                            pattern={'d/m/Y'}
                            format={date => {
                              return moment(date).format(inputMaskFormat)
                            }}
                            parse={value => {
                              return moment(value, inputMaskFormat).toDate()
                            }}
                          />
                        }
                        onChange={date => {
                          setReturnDate(date)
                        }}
                        controlled
                      />
                    </div>

                    <div className="row mb-5">
                      <Input
                        className="col-xl"
                        label="Valor disponível para a viagem (em dolares canadenses)"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        name="value_available"
                        tooltip={{
                          container: {
                            classname: 'ms-2'
                          },
                          classname: 'mw-30px',
                          message: `<p style="max-width: 300px">
                          Quanto tem disponível para fazer a viagem? Estimamos algo em torno de CAD1500/mês/pessoa
                            </p>`
                        }}
                        price
                        rules={{ required: changeTab !== 'previous' }}
                        hasErrorCustom
                        hasCustom
                      />
                      <Input
                        className="col-xl"
                        label="
                          Nome da pessoa, local que irá visitar ou nome da escola"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        name="known.name"
                        tooltip={{
                          container: {
                            classname: 'ms-2'
                          },
                          classname: 'mw-30px',
                          message: `<p style="max-width: 300px">
                          Pode ser o nome do hotel, escola ou conhecido no Canadá
                            </p>`
                        }}
                        rules={{ required: changeTab !== 'previous' }}
                        hasErrorCustom
                        hasCustom
                      />
                    </div>
                    <div className="row mb-5">
                      <Input
                        className="col-xl"
                        label="Endereço"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        name="known.address"
                        tooltip={{
                          container: {
                            classname: 'ms-2'
                          },
                          classname: 'mw-30px',
                          message: `<p style="max-width: 300px">
                          Endereço do hotel, escola ou conhecido no Canadá
                            </p>`
                        }}
                        rules={{ required: changeTab !== 'previous' }}
                        hasErrorCustom
                        hasCustom
                      />
                      <Input
                        className="col-xl"
                        label="
                          Relação com você"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        name="known.relationship"
                        tooltip={{
                          container: {
                            classname: 'ms-2'
                          },
                          classname: 'mw-30px',
                          message: `<p style="max-width: 300px">
                          Ex.: Hotel, escola, filho, etc.
                            </p>`
                        }}
                        rules={{ required: changeTab !== 'previous' }}
                        hasErrorCustom
                        hasCustom
                      />
                    </div>

                    <div className="separator border-2 my-6"></div>

                    <div className="row mb-5 ">
                      <p>Você tem algum curso superior?</p>
                      <div className="d-flex">
                        <Checkbox
                          className="col-xl-1"
                          label="Sim "
                          inputOptions={{
                            classname: 'd-flex align-items-center'
                          }}
                          name="hasCourse"
                          type="radio"
                          value="true"
                          setData={setHasCourse}
                          clearField={{
                            defaultedValues: {
                              course: {
                                name: '',
                                institute_name: '',
                                city: ''
                              },
                              course_start_date: '',
                              course_end_date: ''
                            }
                          }}
                          hasCustom
                          hasErrorCustom
                          rules={{ required: changeTab !== 'previous' }}
                        />
                        <Checkbox
                          className="col-xl-1"
                          label="Não"
                          inputOptions={{
                            classname: 'd-flex align-items-center'
                          }}
                          name="hasCourse"
                          type="radio"
                          hasCustom
                          value="false"
                          setData={setHasCourse}
                          rules={{ required: changeTab !== 'previous' }}
                        />
                      </div>
                    </div>

                    <div className="row mb-5 pe-5">
                      <ContainerAnimation
                        isShow={hasCourse ? 'show' : 'hidden'}
                        height="600"
                        id="course"
                      >
                        <div className="row mb-5">
                          <DatePicker
                            popperProps={{ strategy: 'fixed' }}
                            locale="ptBR"
                            name="course_start_date"
                            className="col-xl"
                            inputOptions={{
                              classname: 'd-flex align-items-center'
                            }}
                            label="Data de inicio"
                            dateFormat="dd/MM/yyyy"
                            placeholderText="DD/MM/AAAA"
                            hasCustom
                            hasErrorCustom
                            selected={courseStartDate}
                            tooltip={{
                              container: {
                                classname: 'ms-2'
                              },
                              classname: 'mw-30px',
                              message: `<p style="max-width: 300px">
                              Quando começou nessa escola ou universidade?
                                </p>`
                            }}
                            customInput={
                              <IMaskInput
                                mask={Date}
                                pattern={'d/m/Y'}
                                format={date => {
                                  return moment(date).format(inputMaskFormat)
                                }}
                                parse={value => {
                                  return moment(value, inputMaskFormat).toDate()
                                }}
                              />
                            }
                            onChange={date => {
                              setCourseStartDate(date)
                            }}
                            controlled
                          />
                          <DatePicker
                            popperProps={{ strategy: 'fixed' }}
                            locale="ptBR"
                            name="course_end_date"
                            className="col-xl"
                            inputOptions={{
                              classname: 'd-flex align-items-center'
                            }}
                            label="Data de término"
                            dateFormat="dd/MM/yyyy"
                            placeholderText="DD/MM/AAAA"
                            hasCustom
                            selected={courseEndDate}
                            tooltip={{
                              container: {
                                classname: 'ms-2'
                              },
                              classname: 'mw-30px',
                              message: `<p style="max-width: 300px">
                              Caso ainda esteja estudando deixe o campo em branco
                                </p>`
                            }}
                            customInput={
                              <IMaskInput
                                mask={Date}
                                pattern={'d/m/Y'}
                                format={date => {
                                  return moment(date).format(inputMaskFormat)
                                }}
                                parse={value => {
                                  return moment(value, inputMaskFormat).toDate()
                                }}
                              />
                            }
                            onChange={date => {
                              setCourseEndDate(date)
                            }}
                            controlled
                          />
                        </div>
                        <div className="row mb-5">
                          <Input
                            className="col-xl"
                            label="Curso que estudou"
                            inputOptions={{
                              classname: 'd-flex align-items-center'
                            }}
                            name="course.name"
                            tooltip={{
                              container: {
                                classname: 'ms-2'
                              },
                              classname: 'mw-30px',
                              message: `<p style="max-width: 300px">
                              Informe o nome do curso
                            </p>`
                            }}
                            hasCustom
                            hasErrorCustom
                          />
                        </div>
                        <div className="row mb-5">
                          <Input
                            className="col-xl"
                            label="
                            Nome da escola ou faculdade"
                            inputOptions={{
                              classname: 'd-flex align-items-center'
                            }}
                            name="course.institute_name"
                            tooltip={{
                              container: {
                                classname: 'ms-2'
                              },
                              classname: 'mw-30px',
                              message: `<p style="max-width: 300px">
                              Informe o nome da escola ou universidade
                            </p>`
                            }}
                            hasCustom
                            hasErrorCustom
                          />
                          <Input
                            className="col-xl"
                            label="Cidade"
                            inputOptions={{
                              classname: 'd-flex align-items-center'
                            }}
                            name="course.city"
                            tooltip={{
                              container: {
                                classname: 'ms-2'
                              },
                              classname: 'mw-30px',
                              message: `<p style="max-width: 300px">
                              Ela está situada em qual cidade?
                            </p>`
                            }}
                            hasCustom
                            hasErrorCustom
                          />
                        </div>
                      </ContainerAnimation>
                    </div>

                    <div className="separator border-2 my-6"></div>

                    <h1>Atividade atual</h1>
                    <p>
                      Entre com os empregos dos últimos 10 anos. Se estudante,
                      entre com os dados da escola.
                    </p>

                    <FormWork
                      changeTab={changeTab}
                      datepickerStart={{
                        name: 'works.0.start_date',
                        selected: birthDate,
                        setSelected: setBirthDate,
                        onChange: date => setBirthDate(date)
                      }}
                      datepickerEnd={{
                        name: 'works.0.end_date',
                        selected: birthDate,
                        setSelected: setBirthDate,
                        onChange: date => setBirthDate(date)
                      }}
                      inputOcupation={{
                        name: 'works.0.ocupation'
                      }}
                      inputCompany={{
                        name: 'works.0.company'
                      }}
                      inputCity={{
                        name: 'works.0.city'
                      }}
                    />

                    <FormWork
                      changeTab={changeTab}
                      datepickerStart={{
                        name: 'works.1.start_date',
                        selected: birthDate,
                        setSelected: setBirthDate,
                        onChange: date => setBirthDate(date)
                      }}
                      datepickerEnd={{
                        name: 'works.1.end_date',
                        selected: birthDate,
                        setSelected: setBirthDate,
                        onChange: date => setBirthDate(date)
                      }}
                      inputOcupation={{
                        name: 'works.1.ocupation'
                      }}
                      inputCompany={{
                        name: 'works.1.company'
                      }}
                      inputCity={{
                        name: 'works.1.city'
                      }}
                      negative={{
                        name: 'works.1.check'
                      }}
                      positive={{
                        name: 'works.1.check'
                      }}
                      label="Nos últimos 10 anos teve algum outro emprego?"
                    />
                    <FormWork
                      changeTab={changeTab}
                      datepickerStart={{
                        name: 'works.2.start_date',
                        selected: birthDate,
                        setSelected: setBirthDate,
                        onChange: date => setBirthDate(date)
                      }}
                      datepickerEnd={{
                        name: 'works.2.end_date',
                        selected: birthDate,
                        setSelected: setBirthDate,
                        onChange: date => setBirthDate(date)
                      }}
                      inputOcupation={{
                        name: 'works.2.ocupation'
                      }}
                      inputCompany={{
                        name: 'works.2.company'
                      }}
                      inputCity={{
                        name: 'works.2.city'
                      }}
                      negative={{
                        name: 'works.2.check'
                      }}
                      positive={{
                        name: 'works.2.check'
                      }}
                      label="Nos últimos 10 anos teve algum outro emprego?"
                    />

                    <div className="separator border-2 my-6"></div>

                    <h1>Questões gerais</h1>

                    <FormGeneral
                      changeTab={changeTab}
                      label="Nos últimos 2 anos, você ou um membro da sua família contraiu tuberculose de pulmão ou esteve em contato próximo com alguém com tuberculose?"
                      explain={{
                        name: 'hasTuberculosis.explain'
                      }}
                      negative={{
                        name: 'hasTuberculosis.check'
                      }}
                      positive={{
                        name: 'hasTuberculosis.check'
                      }}
                    />
                    <FormGeneral
                      changeTab={changeTab}
                      label="Você é portador de qualquer deficiência física ou mental que exige atendimento especial dos serviços de saúde ou social durante sua visita ao Canadá?"
                      explain={{
                        name: 'hasDefict.explain'
                      }}
                      negative={{
                        name: 'hasDefict.check'
                      }}
                      positive={{
                        name: 'hasDefict.check'
                      }}
                    />
                    <FormGeneral
                      changeTab={changeTab}
                      label="Você alguma vez permanceu por mais tempo do que o permitido, estudou ou trabalhou sem autorização no Canadá?"
                      explain={{
                        name: 'illegal.explain'
                      }}
                      negative={{
                        name: 'illegal.check'
                      }}
                      positive={{
                        name: 'illegal.check'
                      }}
                    />
                    <FormGeneral
                      changeTab={changeTab}
                      label="Você já teve qualquer tipo de visto ou permissão recusada para o Canadá ou qualquer outro país, ou já foi impedido de entrar ou foi deportado do Canadá ou qualquer outro país?"
                      explain={{
                        name: 'visaDenied.explain'
                      }}
                      negative={{
                        name: 'visaDenied.check'
                      }}
                      positive={{
                        name: 'visaDenied.check'
                      }}
                    />
                    <FormGeneral
                      changeTab={changeTab}
                      label="Você alguma vez já solicitou qualquer tipo de visto canadense?"
                      explain={{
                        name: 'applyVisa.explain'
                      }}
                      negative={{
                        name: 'applyVisa.check'
                      }}
                      positive={{
                        name: 'applyVisa.check'
                      }}
                    />
                    <FormGeneral
                      changeTab={changeTab}
                      label="Você já cometeu, foi detido, ou indiciado por qualquer contravenção criminal em qualquer país?"
                      explain={{
                        name: 'hasCrime.explain'
                      }}
                      negative={{
                        name: 'hasCrime.check'
                      }}
                      positive={{
                        name: 'hasCrime.check'
                      }}
                    />
                    <FormGeneral
                      changeTab={changeTab}
                      label="Você já esteve em uma milícia, unidade militar de defesa civil ou polícia?"
                      explain={{
                        name: 'military.explain'
                      }}
                      negative={{
                        name: 'military.check'
                      }}
                      positive={{
                        name: 'military.check'
                      }}
                    />
                    <div className="separator border-2 my-6"></div>

                    <div className="row  d-flex align-items-end justify-content-end">
                      <div className="text-center col-xl-2 d-flex align-items-end justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-xl btn-primary mb-5"
                        >
                          <span className="indicator-label">Próximo</span>
                        </button>
                      </div>
                    </div>
                  </Form>
                </div>
              </Tab>
              <Tab notClick title="Informações Familiares" key={1}>
                <div className="form mt-5">
                  <Form
                    className="form"
                    onSubmit={handleNextForm}
                    defaultValues={dataForm}
                  >
                    <div className="row mb-5">
                      <div className="col-xl " style={{ maxWidth: '330px' }}>
                        <h1>Informações Familiares</h1>
                      </div>
                    </div>
                    <div className="row mb-5">
                      <FormMaritalStatus
                        changeTab={changeTab}
                        datepickerMarriageStart={{
                          name: 'spouse.start_date',
                          selected: birthDate,
                          setSelected: setBirthDate,
                          onChange: date => setBirthDate(date)
                        }}
                        datepickerMarriageEnd={{
                          name: 'spouse.end_start',
                          selected: birthDate,
                          setSelected: setBirthDate,
                          onChange: date => setBirthDate(date)
                        }}
                        inputSpouseName={{ name: 'spouse.name' }}
                        inputSpouseSurName={{ name: 'spouse.surname' }}
                        inputStatus={{
                          name: 'spouse.marital_status'
                        }}
                        label="Estado Civil"
                        datepickerStableUnion={{
                          name: 'spouse.date',
                          selected: birthDate,
                          setSelected: setBirthDate,
                          onChange: date => setBirthDate(date)
                        }}
                        inputSpouseAddress={{ name: 'spouse.address' }}
                        inputSpouseCountry={{ name: 'spouse.country' }}
                        inputSpouseDescriptionOccupation={{
                          name: 'spouse.description'
                        }}
                        inputSpouseOccupation={{ name: 'spouse.occupation' }}
                        datepickerSpouseDateBirth={{
                          name: 'spouse.birth_date',
                          selected: birthDate,
                          setSelected: setBirthDate,
                          onChange: date => setBirthDate(date)
                        }}
                        selectTravelWithYou={{
                          name: 'spouse.travel_you',
                          options: optionsSimple,
                          blank: true
                        }}
                        selectRelationshipType={{
                          name: 'spouse.relation',
                          options: optionsValue?.relationship || [],
                          blank: true
                        }}
                      />
                    </div>

                    <div className="separator border-2 my-10"></div>

                    <div className="row mb-5">
                      <h1>Dados da sua mãe</h1>
                      <FormFamily
                        changeTab={changeTab}
                        datepickerBirthDate={{
                          name: 'mother.birth_date'
                        }}
                        inputName={{ name: 'mother.name' }}
                        inputRelationship={{ name: 'mother.surname' }}
                        inputAddress={{ name: 'mother.address' }}
                        inputCountry={{ name: 'mother.country' }}
                        inputOccupation={{ name: 'mother.occupation' }}
                        selectTravelWithYou={{
                          name: 'mother.travel',
                          options: optionsSimple,
                          blank: true
                        }}
                        selectMaritalStatus={{
                          name: 'mother.marital_status',
                          options: optionsValue?.maritalStatus || [],
                          blank: true
                        }}
                        defaultShow="show"
                        type="parents"
                        tooltip={{
                          type: 'sua mãe'
                        }}
                      />
                    </div>

                    <div className="separator border-2 my-6"></div>

                    <div className="row mb-5">
                      <FormFamily
                        changeTab={changeTab}
                        datepickerBirthDate={{
                          name: 'father.birth_date'
                        }}
                        inputName={{ name: 'father.name' }}
                        inputRelationship={{ name: 'father.surname' }}
                        inputAddress={{ name: 'father.address' }}
                        inputCountry={{ name: 'father.country' }}
                        inputOccupation={{ name: 'father.occupation' }}
                        selectTravelWithYou={{
                          name: 'father.travel',
                          options: optionsSimple,
                          blank: true
                        }}
                        selectMaritalStatus={{
                          name: 'father.marital_status',
                          options: optionsValue?.maritalStatus || [],
                          blank: true
                        }}
                        hasHeader={{
                          negative: { name: 'father.isActive' },
                          positive: { name: 'father.isActive' },
                          label: 'Dados do seu pai (mesmo se falecido)'
                        }}
                        label="Dados do seu pai (mesmo se falecido)"
                        type="parents"
                        tooltip={{
                          type: 'seu pai'
                        }}
                      />
                    </div>

                    <div className="separator border-2 my-10"></div>

                    {sons.map(
                      (son, index) =>
                        son && (
                          <FormFamily
                            changeTab={changeTab}
                            key={`${Math.random() * 9999}`}
                            datepickerBirthDate={{
                              name: `sons.${index}.birth_date`
                            }}
                            inputName={{ name: `sons.${index}.name` }}
                            inputRelationship={{
                              name: `sons.${index}.surname`
                            }}
                            inputAddress={{ name: `sons.${index}.address` }}
                            inputCountry={{ name: `sons.${index}.country` }}
                            inputOccupation={{
                              name: `sons.${index}.occupation`
                            }}
                            selectTravelWithYou={{
                              name: `sons.${index}.travel`,
                              options: optionsSimple || [],
                              blank: true
                            }}
                            selectMaritalStatus={{
                              name: `sons.${index}.marital_status`,
                              options: optionsValue?.maritalStatus || [],
                              blank: true
                            }}
                            negative={{ name: `sons.${index + 1}.isActive` }}
                            positive={{ name: `sons.${index + 1}.isActive` }}
                            index={index}
                            setData={setSons}
                            data={sons}
                            hasHeader={
                              index === 0
                                ? {
                                    negative: {
                                      name: `sons.${index}.isActive`
                                    },
                                    positive: {
                                      name: `sons.${index}.isActive`
                                    },
                                    label: 'Você tem filho(s)?'
                                  }
                                : undefined
                            }
                            label="Você tem outro(a) filho(s)?"
                            type="son"
                            tooltip={{
                              type: 'filho(a)'
                            }}
                          />
                        )
                    )}

                    <div className="separator border-2 my-10"></div>

                    {brothers.map(
                      (brother, index) =>
                        brother && (
                          <FormFamily
                            changeTab={changeTab}
                            key={`${Math.random() * 9999}`}
                            datepickerBirthDate={{
                              name: `brothers.${index}.birth_date`
                            }}
                            inputName={{ name: `brothers.${index}.name` }}
                            inputRelationship={{
                              name: `brothers.${index}.surname`
                            }}
                            inputAddress={{
                              name: `brothers.${index}.address`
                            }}
                            inputCountry={{
                              name: `brothers.${index}.country`
                            }}
                            inputOccupation={{
                              name: `brothers.${index}.occupation`
                            }}
                            selectTravelWithYou={{
                              name: `brothers.${index}.travel`,
                              options: optionsSimple || [],
                              blank: true
                            }}
                            selectMaritalStatus={{
                              name: `brothers.${index}.marital_status`,
                              options: optionsValue?.maritalStatus || [],
                              blank: true
                            }}
                            negative={{
                              name: `brothers.${index + 1}.isActive`
                            }}
                            positive={{
                              name: `brothers.${index + 1}.isActive`
                            }}
                            index={index}
                            setData={setBrothers}
                            data={brothers}
                            hasHeader={
                              index === 0
                                ? {
                                    negative: {
                                      name: `brothers.${index}.isActive`
                                    },
                                    positive: {
                                      name: `brothers.${index}.isActive`
                                    },
                                    label: 'Você tem irmão ou irmã?'
                                  }
                                : undefined
                            }
                            label="Você tem outro(a) irmão/irmã?"
                            type="brother"
                            tooltip={{
                              type: 'irmão/irmã'
                            }}
                          />
                        )
                    )}

                    <div className="separator border-2 my-10"></div>
                    <div className="row d-flex align-items-end justify-content-between">
                      <div className="text-center col-xl-2 mw-100px d-flex align-items-end justify-content-start">
                        <button
                          type="submit"
                          onClick={() => handleChangePage('previous')}
                          className="btn btn-xl btn-primary mb-5"
                        >
                          <span className="indicator-label">Anterior</span>
                        </button>
                      </div>
                      <div className="text-center col-xl-2 mw-100px d-flex align-items-end justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-xl btn-primary mb-5"
                        >
                          <span className="indicator-label">Próxima</span>
                        </button>
                      </div>
                    </div>
                  </Form>
                </div>
              </Tab>
              <Tab notClick title="Acompanhantes" key={2}>
                <div className="form mt-5">
                  <Form
                    className="form"
                    onSubmit={handleNextForm}
                    defaultValues={dataForm}
                  >
                    <h1 className="mb-5">Acompanhantes de viagem</h1>
                    <div className="separator border-2 my-6"></div>
                    {peoples.map(
                      (people, index) =>
                        people && (
                          <>
                            <FormPeople
                              changeTab={changeTab}
                              key={`${Math.random() * 9999}`}
                              datepickerBirthDate={{
                                name: `peoples.${index}.birth_date`,
                                selected: birthDate,
                                setSelected: setBirthDate,
                                onChange: date => setBirthDate(date)
                              }}
                              inputName={{ name: `peoples.${index}.name` }}
                              inputRelationship={{
                                name: `peoples.${index}.surname`
                              }}
                              selectGender={{
                                name: `peoples.${index}.gender`,
                                options: optionsValue?.gender || [],
                                blank: true
                              }}
                              negative={{
                                name: `peoples.${index + 1}.isActive`
                              }}
                              positive={{
                                name: `peoples.${index + 1}.isActive`
                              }}
                              hasHeader={
                                index === 0
                                  ? {
                                      negative: {
                                        name: `peoples.${index}.isActive`
                                      },
                                      positive: {
                                        name: `peoples.${index}.isActive`
                                      },
                                      label: 'Está viajando com alguém?'
                                    }
                                  : undefined
                              }
                              label="Está viajando com alguém?"
                              data={peoples}
                              index={index}
                              setData={setPeoples}
                              parent="child"
                              tooltip={{
                                type: 'está viajando com você'
                              }}
                            />
                          </>
                        )
                    )}

                    <div className="separator border-2 my-10"></div>

                    {familiars.map(
                      (familiar, index) =>
                        familiar && (
                          <FormPeople
                            changeTab={changeTab}
                            key={`${Math.random() * 9999}`}
                            datepickerBirthDate={{
                              name: `familiars.${index}.birth_date`,
                              selected: birthDate,
                              setSelected: setBirthDate,
                              onChange: date => setBirthDate(date)
                            }}
                            inputName={{ name: `familiars.${index}.name` }}
                            inputRelationship={{
                              name: `familiars.${index}.surname`
                            }}
                            selectGender={{
                              name: `familiars.${index}.gender`,
                              options: optionsValue?.gender || [],
                              blank: true
                            }}
                            negative={{
                              name: `familiars.${index + 1}.isActive`
                            }}
                            positive={{
                              name: `familiars.${index + 1}.isActive`
                            }}
                            label="Tem algum conhecido no Canadá?"
                            hasHeader={
                              index === 0
                                ? {
                                    negative: {
                                      name: `familiars.${index}.isActive`
                                    },
                                    positive: {
                                      name: `familiars.${index}.isActive`
                                    },
                                    label: 'Tem algum conhecido no Canadá?'
                                  }
                                : undefined
                            }
                            data={familiars}
                            setData={setFamiliars}
                            index={index}
                            tooltip={{
                              type: 'é sua conhecida'
                            }}
                          />
                        )
                    )}

                    <div className="separator border-2 my-10"></div>
                    <div className="row d-flex align-items-end justify-content-between">
                      <div className="text-center col-xl-2 mw-100px d-flex align-items-end justify-content-start">
                        <button
                          type="submit"
                          onClick={() => handleChangePage('previous')}
                          className="btn btn-xl btn-primary mb-5"
                        >
                          <span className="indicator-label">Anterior</span>
                        </button>
                      </div>
                      <div className="text-center col-xl-2 mw-100px d-flex align-items-end justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-xl btn-primary mb-5"
                        >
                          <span className="indicator-label">Próxima</span>
                        </button>
                      </div>
                    </div>
                  </Form>
                </div>
              </Tab>
              <Tab notClick title="Intenção" key={3}>
                <div className="form mt-5">
                  <Form
                    className="form"
                    onSubmit={
                      changeTab === 'previous' ? handleNextForm : onSubmit
                    }
                    defaultValues={dataForm}
                  >
                    <h1 className="mb-5">Sobre o propósito da viagem</h1>
                    <p className="mb-2">
                      Faça uma breve descrição de suas atividades enquanto
                      estiver no Canadá: (max. 300 caracteres). Atenção: este
                      campo é importante para que o oficial conheça os seus
                      planos. Descreva de forma clara e objetiva.
                    </p>
                    <div className="row mb-10">
                      <Textarea
                        label=""
                        name="intention"
                        style={{ minHeight: 100 }}
                        maxLength={300}
                        rules={{ required: changeTab !== 'previous' }}
                        hasErrorCustom
                      />
                    </div>
                    <div className="separator border-2 my-6"></div>
                    <div className="row mb-5">
                      <h1>Anexos</h1>
                      <p>
                        Insira seus documentos. Ex (Passaporte, visto
                        anteriores, foto digital e etc)
                      </p>
                    </div>
                    {attachments.map(attachment =>
                      attachment?.name ? (
                        <div className="row mb-5" key={Math.random() * 9999}>
                          <div className="col-sm">
                            <Input
                              name={`attachments.${attachment.id}.name`}
                              value={attachment.name}
                              readOnly
                            />
                          </div>
                          <div className="col-sm d-flex justify-content-between container-download">
                            {!!attachment.url && (
                              <div className="col-xl-3 col-sm mb-5 mw-150px">
                                <button
                                  type="button"
                                  className="btn btn-lg btn-block btn-primary"
                                  onClick={() =>
                                    handleDownloadAttachments(attachment)
                                  }
                                >
                                  Baixar
                                </button>
                              </div>
                            )}
                            <div className="col-sm">
                              <button
                                type="button"
                                className="btn btn-lg btn-block btn-primary"
                                onClick={() =>
                                  handleRemoveAttachments(attachment?.id)
                                }
                              >
                                Excluir
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="row mb-5 d-flex"
                          key={Math.random() * 9999}
                        >
                          <Input
                            className="col-sm"
                            inputOptions={{ classname: 'pe-1' }}
                            label=""
                            name={`attachments.${attachment.id}`}
                            onChange={event => {
                              if (
                                event.target.files?.[0]?.size &&
                                event.target.files?.[0]?.size / 1024 > 6000
                              ) {
                                addToast({
                                  title: 'Arquivo grande',
                                  description:
                                    'O arquivo precisa ter menos que 5MB',
                                  type: 'info'
                                })
                                return (event.target.value = '')
                              }
                              return event
                            }}
                            type="file"
                            hasCustom
                          />
                          <div className="col-sm ms-md-5">
                            <button
                              type="button"
                              className="btn btn-lg btn-block btn-primary"
                              onClick={() =>
                                handleRemoveAttachments(attachment?.id)
                              }
                            >
                              Excluir
                            </button>
                          </div>
                        </div>
                      )
                    )}
                    <div className="row d-flex align-items-end justify-content-end">
                      <div className="text-center col-md-2 mw-100px d-flex align-items-end justify-content-end">
                        <button
                          type="button"
                          className="btn btn-xl btn-primary mb-5"
                          onClick={() => {
                            const findBigger = Math.max(
                              ...attachments.map(attachment => attachment.id)
                            )
                            const biggerId =
                              findBigger === Infinity ||
                              findBigger === -Infinity
                                ? 1
                                : findBigger
                            setAttachments(old => {
                              const copyOld = [...old]
                              copyOld.push({
                                id: (biggerId || 1) + 1
                              })
                              return copyOld
                            })
                          }}
                        >
                          <span className="indicator-label">Adicionar</span>
                        </button>
                      </div>
                    </div>
                    <div className="separator border-2 my-6"></div>
                    <div className="row d-flex align-items-end justify-content-between">
                      <div className="text-center col-md-2 mw-100px d-flex align-items-end justify-content-start">
                        <button
                          type="submit"
                          onClick={() => handleChangePage('previous')}
                          className="btn btn-xl btn-primary mb-5"
                        >
                          <span className="indicator-label">Anterior</span>
                        </button>
                      </div>
                      <div className="text-center col-md-2 mw-100px d-flex align-items-end justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-xl btn-primary mb-5"
                        >
                          <span className="indicator-label">Enviar</span>
                        </button>
                      </div>
                    </div>
                  </Form>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </ContainerWrapperContent>
      <Alert
        message={
          'Foi selecionada nacionalidade estrangeira, essa solicitação será para passaporte para estrangeiro (caderneta amarela). Caso seja brasileiro e deseje passaporte comum (caderneta azul), selecione a nacionalidade Brasil. Deseja prosseguir assim mesmo?'
        }
        onClickCancellButton={handlerClickButtonCancellAlert}
        onClickConfirmButton={handlerClickButtonConfirmAlert}
        isActive={alert.isActive}
      />
    </div>
  )
}

export default Canada
