import moment from 'moment'

export const SINGLE_FIELD_NAME = 'single'
export const MARRIED_FIELD_NAME = 'married'
export const STABLE_UNION_FIELD_NAME = 'stable_union'
export const WIDOWED_FIELD_NAME = 'widowed'
export const DIVORCED_FIELD_NAME = 'divorced'
export const LEGALLY_SEPARATED_FIELD_NAME = 'legally_separated'
export const STUDENT_FIELD_NAME = 'student'
export const RETIREE_FIELD_NAME = 'retiree'
export const FROM_HOME_FIELD_NAME = 'from_home'
export const PLACES_APPLICATION = [
  {
    name: 'BRASILIA',
    value: 'BRASILIA'
  },
  {
    name: 'SÃO PAULO',
    value: 'SÃO PAULO'
  },
  {
    name: 'RECIFE',
    value: 'RECIFE'
  },
  {
    name: 'PORTO ALEGRE',
    value: 'PORTO ALEGRE'
  },
  {
    name: 'RIO DE JANEIRO',
    value: 'RIO DE JANEIRO'
  }
]

export const maxDate = moment().subtract(18, 'y').toDate()
export const TYPE_CITIZENSHIP = [
  {
    name: 'Cidadão Americano',
    value: 'US Citizen'
  },
  {
    name: 'Permanente Legal dos EUA',
    value: 'US Legal Permanent'
  },
  {
    name: 'Residente',
    value: 'Resident'
  },
  {
    name: 'Não-imigrante',
    value: 'Non Immigrant'
  }
]
export const TYPE_SOCIAL_NETWORK = [
  {
    name: 'Facebook',
    value: 'Facebook'
  },
  {
    name: 'Google+',
    value: 'Google+'
  },
  {
    name: 'Instagram',
    value: 'Instagram'
  },
  {
    name: 'Linkedin',
    value: 'Linkedin'
  },
  {
    name: 'Pinterest',
    value: 'Pinterest'
  },
  {
    name: 'Quora',
    value: 'Quora'
  },
  {
    name: 'Reddit',
    value: 'Reddit'
  },
  {
    name: 'Snapchat',
    value: 'Snapchat'
  },
  {
    name: 'TikTok',
    value: 'TikTok'
  },
  {
    name: 'Twitter',
    value: 'Twitter'
  },
  {
    name: 'Youtube',
    value: 'Youtube'
  }
]
export enum INPUT_MASK {
  CPF = '000.000.000-00',
  PHONE = '(00) 0000-0000',
  CELL_PHONE = '(00) 00000-0000',
  ZIP_CODE = '00000-000'
}

export const formatsAvailable = [
  'YYYY-MM-DD',
  'YYYY-MM-DD HH:mm:ss',
  'DD/MM/YYYY',
  'DD/MM/YYYY HH:mm:ss',
  'YYYY-MM-DDTHH:mm:ssZ',
  'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'
]

export const OPTIONS_YES_OR_NO = [
  {
    name: 'Sim',
    value: 'Sim'
  },
  {
    name: 'Não',
    value: 'Não'
  }
]
