import moment from 'moment'
import { useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { IMaskInput } from 'react-imask'
import { Checkbox, Input, InputProps } from '../../../../../../components/Form'
import {
  Date as DatePicker,
  DateProps
} from '../../../../../../components/Form/date'
import { useTabs } from '../../../../../../hooks/tabs'
import { ContainerAnimation } from '../../../styles'

interface DatepickerProps extends DateProps {
  selected?: Date
  setSelected?: React.Dispatch<React.SetStateAction<Date>>
}

interface FormWorkProps {
  datepickerStart: DatepickerProps
  datepickerEnd: DatepickerProps
  inputOcupation: InputProps
  inputCompany: InputProps
  inputCity: InputProps
  label?: string
  positive?: InputProps
  negative?: InputProps
  changeTab?: React.SetStateAction<string>
}

export const FormWork: React.FC<FormWorkProps> = ({
  datepickerStart,
  datepickerEnd,
  inputOcupation,
  inputCompany,
  inputCity,
  positive,
  negative,
  label
}) => {
  const inputMaskFormat = 'DD/MM/YYYY'
  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()
  const {
    register,
    watch,
    resetField,
    getValues,
    setValue,
    formState: { errors },
    clearErrors
  } = useFormContext()
  const watchField = watch(positive?.name)
  const { currentTabStatus: changeTab } = useTabs()
  useEffect(() => {
    if (watchField === 'false') {
      const prefixArray = positive.name.split('.')
      prefixArray.pop()
      const prefix = prefixArray.join('.')
      const workFields = getValues(prefix)
      if (workFields) {
        const objectEntries = Object.entries(workFields)
        for (let [key] of objectEntries) {
          key = `${prefix}.${key}`
          if (positive.name !== key) {
            setValue(key, '')
          }
        }
      }
      clearErrors(prefix)
    }
  }, [
    clearErrors,
    datepickerEnd.name,
    datepickerStart.name,
    getValues,
    inputCity.name,
    inputCompany.name,
    inputOcupation.name,
    positive,
    resetField,
    setValue,
    watchField
  ])

  return (
    <div className="row mb-10 pe-xl-5 mt-10">
      <div className="">
        {!!label && (
          <p className="mb-2 form-check-label text-gray-700 fw-bold  p-0">
            {label}
          </p>
        )}
        <div className="d-flex">
          {positive && (
            <Checkbox
              className="col-xl-1"
              label="Sim "
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              register={register}
              {...positive}
              type="radio"
              value="true"
              rules={{
                required: changeTab !== 'previous'
              }}
              errors={errors}
              hasErrorCustom
              hasCustom
            />
          )}
          {negative && (
            <Checkbox
              className="col-xl-1"
              label="Não"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              {...negative}
              register={register}
              type="radio"
              value="false"
              errors={errors}
              rules={{
                required: changeTab !== 'previous'
              }}
              hasCustom
            />
          )}
        </div>
      </div>
      <ContainerAnimation
        isShow={!label ? 'show' : watchField === 'true' ? 'show' : 'hidden'}
        height="400"
        id="work"
      >
        <div className="row mb-5 pe-xl-5">
          <Controller
            shouldUnregister={true}
            name={datepickerStart.name}
            rules={
              !label
                ? { required: changeTab !== 'previous' }
                : watchField === 'true'
                ? { required: changeTab !== 'previous' }
                : { required: false }
            }
            render={({ field }) => (
              <DatePicker
                popperProps={{ strategy: 'fixed' }}
                locale="ptBR"
                className="col-xl"
                register={register}
                inputOptions={{ classname: 'd-flex align-items-center' }}
                label="Data de inicio"
                dateFormat="dd/MM/yyyy"
                placeholderText="DD/MM/AAAA"
                hasCustom
                selected={startDate}
                tooltip={{
                  container: {
                    classname: 'ms-2'
                  },
                  classname: 'mw-30px',
                  message: `<p style="max-width: 300px">
                  Quando começou nesse emprego ou escola/universidade?
                    </p>`
                }}
                errors={errors}
                hasErrorCustom
                {...field}
                customInput={
                  <IMaskInput
                    mask={Date}
                    pattern={'d/m/Y'}
                    format={date => {
                      return moment(date).format(inputMaskFormat)
                    }}
                    parse={value => {
                      return moment(value, inputMaskFormat).toDate()
                    }}
                  />
                }
                onChange={(e: any) => {
                  field.onChange(e)
                  setStartDate(e)
                }}
              />
            )}
          />
          <Controller
            shouldUnregister={true}
            name={datepickerEnd.name}
            render={({ field }) => (
              <DatePicker
                popperProps={{ strategy: 'fixed' }}
                locale="ptBR"
                className="col-xl"
                register={register}
                inputOptions={{ classname: 'd-flex align-items-center' }}
                label="Data de término"
                dateFormat="dd/MM/yyyy"
                placeholderText="DD/MM/AAAA"
                tooltip={{
                  container: {
                    classname: 'ms-2'
                  },
                  classname: 'mw-30px',
                  message: `<p style="max-width: 300px">
                  Caso ainda esteja estudando deixe o campo em branco
                    </p>`
                }}
                hasCustom
                selected={endDate}
                {...field}
                errors={errors}
                hasErrorCustom
                customInput={
                  <IMaskInput
                    mask={Date}
                    pattern={'d/m/Y'}
                    format={date => {
                      return moment(date).format(inputMaskFormat)
                    }}
                    parse={value => {
                      return moment(value, inputMaskFormat).toDate()
                    }}
                  />
                }
                onChange={(e: any) => {
                  field.onChange(e)
                  setEndDate(e)
                }}
              />
            )}
          />
        </div>
        <div className="row mb-5">
          <Input
            className="col-xl"
            label="Ocupação"
            register={register}
            inputOptions={{
              classname: 'd-flex align-items-center'
            }}
            {...inputOcupation}
            tooltip={{
              container: {
                classname: 'ms-2'
              },
              classname: 'mw-30px',
              message: `<p style="max-width: 300px">
              Entre com seu cargo. Se for estudante informe apenas 'Estudante'
                          </p>`
            }}
            rules={
              !label
                ? { required: changeTab !== 'previous' }
                : watchField === 'true'
                ? { required: changeTab !== 'previous' }
                : { required: false }
            }
            errors={errors}
            hasErrorCustom
            hasCustom
          />
        </div>
        <div className="row mb-5 pe-xl-5">
          <Input
            className="col-xl"
            register={register}
            label="Nome da empresa ou escola/faculdade"
            inputOptions={{
              classname: 'd-flex align-items-center'
            }}
            {...inputCompany}
            rules={
              !label
                ? { required: changeTab !== 'previous' }
                : watchField === 'true'
                ? { required: changeTab !== 'previous' }
                : { required: false }
            }
            errors={errors}
            hasErrorCustom
            hasCustom
          />
          <Input
            className="col-xl"
            label="Cidade"
            register={register}
            labelOptions={{ classname: 'col-xl-4' }}
            inputOptions={{
              classname: 'd-flex align-items-center'
            }}
            {...inputCity}
            rules={
              !label
                ? { required: changeTab !== 'previous' }
                : watchField === 'true'
                ? { required: changeTab !== 'previous' }
                : { required: false }
            }
            errors={errors}
            hasErrorCustom
            hasCustom
          />
        </div>
      </ContainerAnimation>
    </div>
  )
}
