import React, { useCallback, useEffect, useState } from 'react'
import {
  ContainerHeader,
  ContainerLogo,
  ContainerWrapperContent,
  WrapperContent
} from './styles'
import Form, { Input, Select, Textarea } from '../../../components/Form'
import Tabs from '../../../components/Tabs'
import { useLoading } from '../../../hooks/loading'
import api from '../../../services/api'
import { useToast } from '../../../hooks/toast'
import { Loading } from '../../../components/Loading'
import { useHistory, useLocation } from 'react-router-dom'
import { Date as DatePicker } from '../../../components/Form/date'
import { IMaskInput } from 'react-imask'
import moment from 'moment'
import Tab from '../../../components/Tabs/Tab'
import { Alert } from '../../../components/Alert'
import logo from '../../../assets/media/logos/logo.png'
import { StringParam, useQueryParam } from 'use-query-params'
import { FormCompanion } from './components/People'
type OptionsValue = {
  cetificationType: SelectOptionsValue[]
  gender: SelectOptionsValue[]
  maritalStatus: SelectOptionsValue[]
  previousPassportSituation: SelectOptionsValue[]
  raceColor: SelectOptionsValue[]
  reasonChange: SelectOptionsValue[]
  profession: SelectOptionsValue[]
  states: {
    UF: string
    cities: SelectOptionsValue[]
  }[]
  countries: SelectOptionsValue[]
  nationality: SelectOptionsValue[]
  typeEmployment: SelectOptionsValue[]
  passportTypes: SelectOptionsValue[]
  lenghtStay: SelectOptionsValue[]
  visaType: SelectOptionsValue[]
  parentage: SelectOptionsValue[]
}
type SelectOptionsValue = {
  name: string
  value: string
}
type FormData = {
  id?: number
  name: string
  gender: string
  parentage: {
    name: string
    gender: string
  }[]
  race_color: string
  marital_status: string
  nationality: string
  country: string
  state: string
  city: string
  previousNames: {
    name: string
    reason: string
  }[]
  document_number: string
  issue_date: Date
  birth_date: Date
  issuing_body: string
  state_expedition: string
  cpf: string
  cpf_responsible: string
  certificate: {
    enrollment: string
    type: string
    number: string
    book: string
    sheet: string
    registry: string
    state_expedition: string
    city_expedition: string
  }
  previousPassport: {
    situation: string
    series: string
    number: string
    hasUpdateName: boolean
  }
  profession: string
  email: string
  email_confirmation: string
  applicant: {
    country: string
    zip_code: string
    state: string
    city: string
    street: string
    district: string
    ddd: string
    phone: string
  }
  emancipated: boolean
  international_adoption: boolean
  new_model_certificate: boolean
  cities?: any
  countryValue?: any
  companions?: any
  attachments?: any[]
}
export const Mexico: React.FC = () => {
  const location = useLocation()
  const [id] = useQueryParam('B3C1836D42ADD8F9B5C41211C401838C', StringParam)
  const { activeLoading, disableLoading, loading } = useLoading()
  const { addToast } = useToast()
  const history = useHistory()
  const [activeTab, setActiveTab] = useState(0)
  const [countries, setCountries] = useState([])
  const [optionsValue, setOptionsValue] = useState<OptionsValue>()
  const [alert, setIsActiveAlert] = useState<{
    isActive: boolean
    id: number
    name: string
  }>({
    id: 0,
    isActive: false,
    name: ''
  })
  const [birthDate, setBirthDate] = useState<Date>()
  const [issueDate, setIssueDate] = useState<Date>()
  const [dueDate, setDueDate] = useState<Date>()
  const [admissionDate, setAdmissionDate] = useState<Date>()
  const [entryMexicoDate, setEntryMexicoDate] = useState<Date>()
  const [dataForm, setDataForm] = useState<FormData>({} as FormData)
  const [copyDataForm, setCopyDataForm] = useState<FormData>({} as FormData)
  const [attachments, setAttachments] = useState<
    { id: number; url?: string; name?: string; file?: File }[]
  >([{ id: 1 }])
  const [hasCriminalRecord, setHasCriminalRecord] = useState<string>('')
  const [wasDeported, setWasDeported] = useState<string>('')
  const [age, setAge] = useState('')
  const [changeTab, setChangeTab] = useState('next')
  const [companions, setCompanions] = useState([{ id: 0, isActive: '' }])
  const [optionsSimple] = useState([
    { name: 'Sim', value: 'Sim' },
    { name: 'Não', value: 'Não' }
  ])
  const inputMaskFormat = 'DD/MM/YYYY'

  const formatingDateFields = useCallback((originDate: any) => {
    const copyData = Object.entries(originDate)
    for (const [key, value] of copyData) {
      if (value) {
        if (moment(value, 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]', true).isValid()) {
          originDate[key] = moment(value).format(inputMaskFormat)
        }
        if (typeof value === 'object') {
          formatingDateFields(value)
        }
      }
    }
  }, [])

  useEffect(() => {
    window.addEventListener('keydown', event => {
      if (event.key === 'Enter') {
        event.preventDefault()
      }
    })
    return () => {
      window.removeEventListener('keydown', event => {
        if (event.key === 'Enter') {
          event.preventDefault()
        }
      })
    }
  }, [])

  useEffect(() => {
    async function loadData() {
      activeLoading()
      if (id) {
        const convertedId = atob(`${id}`)
        try {
          const response = await api.get(
            `/commercial/public/clients/view/${convertedId}`
          )
          const { data } = response
          const locationData = data.values as FormData
          const copyData: FormData = { ...locationData }

          setCompanions(
            copyData.companions?.length ? copyData.companions : companions
          )
          if (copyData.attachments?.length) {
            const copyAttachment: any[] = []
            for (const attachment of copyData.attachments) {
              if (attachment) {
                fetch(attachment.url)
                  .then(response => response.blob())
                  .then(blob => {
                    const file = new File([blob], attachment.name)
                    copyAttachment[attachment.id] = {
                      ...attachment,
                      file
                    }
                  })
              }
            }
            setAttachments(copyAttachment)
          }
          setDataForm({
            id: data.id,
            ...copyData
          })
          setCopyDataForm({
            id: data.id,
            ...copyData
          })
          disableLoading()
        } catch (err) {
          disableLoading()
          addToast({
            type: 'error',
            title: 'Error ao carregar o cliente',
            description:
              'Houve um error ao carregar o cliente, tente novamente mais tarde!'
          })
        }
      }
      disableLoading()
    }
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const onSubmit = useCallback(
    async (formValues: any) => {
      activeLoading()
      try {
        const data = {
          ...dataForm,
          ...formValues,
          companions: formValues.companions.filter(
            (item: any) => item && item.isActive
          )
        }
        formatingDateFields(data)
        const formData = new FormData()
        const attachmentsData =
          data?.attachments?.map(
            // eslint-disable-next-line array-callback-return
            (dataItem: any, index: number) => {
              if (dataItem) {
                const findAttachment = attachments.find(
                  attachment => index === attachment.id
                )
                if (findAttachment) {
                  if (findAttachment.file) {
                    return findAttachment
                  }
                  return dataItem
                }
              }
            }
          ) || []
        for (const attachment of attachmentsData) {
          if (attachment) {
            if (attachment[0]) {
              formData.append('attachments', attachment[0])
            }
            if (attachment.file) {
              formData.append('attachments', attachment.file)
            }
          }
        }
        delete data.attachments
        formData.append('values', JSON.stringify(data))
        formData.append('name', data.name)
        formData.append('email', data.email)
        formData.append('cpf', data.cpf)
        formData.append('type', 'mexico')

        if (dataForm.id) {
          await api.put(
            `/commercial/public/clients/update/${dataForm.id}`,
            formData
          )
          disableLoading()
          addToast({
            type: 'success',
            title: 'Registro alterado',
            description: 'Registro alterado com sucesso'
          })
        } else {
          await api.post('/commercial/public/clients', formData)
          disableLoading()
          addToast({
            type: 'success',
            title: 'Registro criado',
            description: 'Registro criado com sucesso'
          })
        }

        history.go(0)
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro ao adicionar registro',
          description:
            'Ocorreu um erro ao adicionar o registro, por favor, tente novamente.'
        })
        disableLoading()
      }
    },
    [
      activeLoading,
      addToast,
      attachments,
      dataForm,
      disableLoading,
      formatingDateFields,
      history
    ]
  )

  const handleNextForm = useCallback(
    async (data: any) => {
      const formData = {
        ...JSON.parse(JSON.stringify(copyDataForm)),
        ...JSON.parse(JSON.stringify(dataForm)),
        ...JSON.parse(JSON.stringify(data))
      }
      if (data?.attachments) {
        const attachmentsData = data?.attachments
          ?.map(
            // eslint-disable-next-line array-callback-return
            (dataItem: any, index: number) => {
              if (dataItem) {
                const findAttachment = attachments.find(
                  attachment => index === attachment?.id
                )
                if (findAttachment) {
                  if (findAttachment.file) {
                    return findAttachment
                  }
                  return {
                    id: index,
                    name: dataItem[0]?.name,
                    file: dataItem[0]
                  }
                }
              }
            }
          )
          .filter((item: any) => item)
        setAttachments(attachmentsData)
      }
      setDataForm(formData)
      if (id) {
        setCopyDataForm({
          ...copyDataForm,
          ...formData
        })
      }
      if (changeTab === 'previous') {
        setChangeTab('next')
        setActiveTab(activeTab - 1)
      } else {
        setActiveTab(activeTab + 1)
      }
    },
    [copyDataForm, dataForm, attachments, id, changeTab, activeTab]
  )

  const loadOptions = useCallback(async () => {
    activeLoading()
    try {
      const { data } = await api.get('/options')
      setOptionsValue({
        ...data
      })
      setCountries(data.countries)
      disableLoading()
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao buscar dados',
        description:
          'Ocorreu um erro ao buscar os dados, por favor, tente novamente.'
      })
      disableLoading()
    }
  }, [activeLoading, addToast, disableLoading])

  useEffect(() => {
    loadOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlerClickButtonCancellAlert = () => {
    setIsActiveAlert({
      id: 0,
      isActive: false,
      name: ''
    })
  }
  const handlerClickButtonConfirmAlert = async () => {
    setIsActiveAlert({
      id: 0,
      isActive: false,
      name: ''
    })
  }

  const handleRemoveAttachments = useCallback(
    (id: number) => {
      const biggerId = Math.max(...attachments.map(attachment => attachment.id))

      const filterAttachments = attachments.map(attachment =>
        attachment && attachment.id === id
          ? {
              id: (biggerId || 1) + 1,
              url: null,
              name: null
            }
          : { ...attachment }
      )
      setAttachments(filterAttachments)
    },
    [attachments]
  )

  const handleDownloadAttachments = useCallback(
    (attachment: any) => {
      activeLoading()
      fetch(attachment.url)
        .then(response => response.blob())
        .then(blob => {
          disableLoading()
          const blobURL = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = blobURL
          a.download = attachment.name
          document.body.appendChild(a)
          a.click()
        })
    },
    [activeLoading, disableLoading]
  )
  return (
    <div>
      <Loading isActive={loading} />
      <div className="row gy-5 g-xl">
        <ContainerHeader>
          <WrapperContent>
            <ContainerLogo>
              <a href="/">
                <img
                  alt="Logo"
                  src={logo}
                  className="w-150px logo"
                  style={{ objectFit: 'cover' }}
                />
              </a>
            </ContainerLogo>
          </WrapperContent>
        </ContainerHeader>
      </div>
      <ContainerWrapperContent>
        <div className="gy-5 g-xl ps-2">
          <h1>Formulário de Solicitação de Visto Para o México</h1>
          <div className="card px-10 py-3 mt-3">
            <Tabs
              classname="justify-content-between"
              subtitle="(*) Preenchimento Obrigatório"
              setActiveTab={setActiveTab}
              activeTab={activeTab}
            >
              <Tab notClick title="Informação Pessoal" key={0}>
                <div className="form mt-5">
                  <Form
                    className="form"
                    onSubmit={handleNextForm}
                    defaultValues={dataForm}
                  >
                    <h1 className="mb-5">Dados Pessoais</h1>
                    <div className="row mb-5">
                      <Input
                        className="col-xl"
                        label="Nome (Como Aparece No Passaporte)*:"
                        name="name"
                        hasCustom
                        rules={{
                          required: changeTab !== 'previous'
                        }}
                      />
                      <Input
                        className="col-xl"
                        label="Sobrenome (Como Aparece No Passaporte)*:"
                        name="lastName"
                        hasCustom
                        rules={{
                          required: changeTab !== 'previous'
                        }}
                      />
                    </div>
                    <div className="row mb-5">
                      <DatePicker
                        locale="ptBR"
                        name="birth_date"
                        className="col-xl"
                        label="Data de nascimento*:"
                        dateFormat="dd/MM/yyyy"
                        placeholderText="DD/MM/AAAA"
                        hasCustom
                        selected={birthDate}
                        rules={{ required: changeTab !== 'previous' }}
                        maxDate={new Date()}
                        customInput={
                          <IMaskInput
                            mask={Date}
                            pattern={'d/m/Y'}
                            format={date => {
                              return moment(date).format(inputMaskFormat)
                            }}
                            parse={value => {
                              return moment(value, inputMaskFormat).toDate()
                            }}
                          />
                        }
                        onChange={date => {
                          if (moment(date).isValid()) {
                            setAge(`${moment().diff(moment(date), 'y')}`)
                          }
                          setBirthDate(date)
                        }}
                        controlled
                      />
                      <Input
                        className="col-xl-2"
                        label="Idade:"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        name="age"
                        hasCustom
                        value={age}
                        controlled
                        mask={{
                          mask: Number
                        }}
                        onChange={event => setAge(event.target.value)}
                        // rules={{
                        //   required: changeTab !== 'previous' && Number(age) >= 0
                        // }}
                      />
                      <Input
                        className="col-xl"
                        label="Número do CPF"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        name="cpf"
                        mask={{
                          mask: '000.000.000-00',
                          type: 'cpf'
                        }}
                        controlled
                        rules={{
                          required: true,
                          minLength: {
                            value: 14,
                            message: 'Cpf Inválido'
                          }
                        }}
                        hasCustom
                      />
                      <Select
                        blank
                        className="col-xl"
                        label="Sexo*:"
                        name="gender"
                        options={optionsValue?.gender || []}
                        rules={{ required: changeTab !== 'previous' }}
                        controlled
                        hasCustom
                      />
                      <Select
                        blank
                        name="marital_status"
                        className="col-xl"
                        label="Estado Civil*"
                        options={optionsValue?.maritalStatus || []}
                        rules={{ required: changeTab !== 'previous' }}
                        controlled
                        hasCustom
                      />
                    </div>
                    <div className="row mb-5">
                      <Input
                        className="col-xl"
                        label="Passaporte*:"
                        name="number_document"
                        hasCustom
                        rules={{
                          required: changeTab !== 'previous'
                        }}
                      />
                      <Select
                        blank
                        className="col-xl-3"
                        label="Tipo de Passaporte*:"
                        name="passport_type"
                        hasCustom
                        options={optionsValue?.passportTypes || []}
                        rules={{ required: changeTab !== 'previous' }}
                        controlled
                      />
                      <Select
                        blank
                        className="col-xl"
                        label="Tipo de Visto*:"
                        name="visa_type"
                        hasCustom
                        options={optionsValue?.visaType || []}
                        rules={{ required: changeTab !== 'previous' }}
                        controlled
                      />
                      <Select
                        blank
                        className="col-xl-3"
                        label="País de Nascimento*:"
                        name="country"
                        hasCustom
                        rules={{
                          required: changeTab !== 'previous'
                        }}
                        options={countries}
                        controlled
                      />
                    </div>
                    <div className="row mb-5">
                      <Select
                        blank
                        className="col-xl"
                        label="Nacionalidade*:"
                        name="nationality"
                        hasCustom
                        rules={{ required: changeTab !== 'previous' }}
                        options={[
                          {
                            name: 'APATRIDA',
                            value: 'APATRIDA'
                          },
                          ...countries
                        ]}
                        controlled
                      />
                      <Select
                        blank
                        className="col-xl-3"
                        label="País de Expedição*:"
                        name="country_expedition"
                        hasCustom
                        rules={{
                          required: changeTab !== 'previous'
                        }}
                        options={countries}
                        controlled
                      />
                      <DatePicker
                        locale="ptBR"
                        name="issue_date"
                        className="col-xl-3"
                        label="Data de emissão*:"
                        dateFormat="dd/MM/yyyy"
                        placeholderText="DD/MM/AAAA"
                        hasCustom
                        selected={issueDate}
                        rules={{ required: changeTab !== 'previous' }}
                        customInput={
                          <IMaskInput
                            mask={Date}
                            pattern={'d/m/Y'}
                            format={date => {
                              return moment(date).format(inputMaskFormat)
                            }}
                            parse={value => {
                              return moment(value, inputMaskFormat).toDate()
                            }}
                          />
                        }
                        onChange={date => {
                          setIssueDate(date)
                        }}
                        controlled
                      />
                      <DatePicker
                        locale="ptBR"
                        name="due_date"
                        className="col-xl-3"
                        label="Data de Vencimento*:"
                        dateFormat="dd/MM/yyyy"
                        placeholderText="DD/MM/AAAA"
                        hasCustom
                        selected={dueDate}
                        rules={{ required: changeTab !== 'previous' }}
                        customInput={
                          <IMaskInput
                            mask={Date}
                            pattern={'d/m/Y'}
                            format={date => {
                              return moment(date).format(inputMaskFormat)
                            }}
                            parse={value => {
                              return moment(value, inputMaskFormat).toDate()
                            }}
                          />
                        }
                        onChange={date => {
                          setDueDate(date)
                        }}
                        controlled
                      />
                    </div>

                    <div className="separator border-2 my-6"></div>

                    <div className="row  d-flex align-items-end justify-content-end">
                      <div className="text-center col-xl-2 d-flex align-items-end justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-xl btn-primary mb-5"
                        >
                          <span className="indicator-label">Próximo</span>
                        </button>
                      </div>
                    </div>
                  </Form>
                </div>
              </Tab>
              <Tab notClick title="Dados Complementares" key={1}>
                <div className="form mt-5">
                  <Form
                    className="form"
                    onSubmit={handleNextForm}
                    defaultValues={dataForm}
                  >
                    <div className="row mb-5">
                      <Input
                        className="col-xl"
                        label="CEP*"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        name="address.zip_code"
                        mask={{
                          mask: '00000-000',
                          type: 'zipCode',
                          prefixInputsData: 'address.'
                        }}
                        controlled
                        rules={{
                          required: changeTab !== 'previous',
                          minLength: { value: 8, message: 'Cep Inválido' }
                        }}
                        maxLength={10}
                        hasCustom
                      />
                      <Input
                        className="col-xl"
                        label="Endereço Atual*"
                        name="address.street"
                        rules={{ required: changeTab !== 'previous' }}
                        hasCustom
                      />
                    </div>
                    <div className="row mb-5">
                      <Input
                        className="col-xl"
                        label="Número*"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        name="address.number"
                        rules={{ required: changeTab !== 'previous' }}
                        hasCustom
                      />
                      <Input
                        className="col-xl"
                        label="Complemento"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        name="address.complement"
                        hasCustom
                      />
                    </div>
                    <div className="row mb-5">
                      <Input
                        className="col-xl"
                        label="Cidade*"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        name="address.city"
                        rules={{ required: changeTab !== 'previous' }}
                        hasCustom
                      />
                      <Input
                        className="col-xl"
                        label="Bairro*"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        name="address.district"
                        rules={{ required: changeTab !== 'previous' }}
                        hasCustom
                      />
                    </div>
                    <div className="row mb-5">
                      <Input
                        className="col-xl"
                        label="E-mail*:"
                        name="email"
                        hasCustom
                        rules={{
                          required: changeTab !== 'previous',
                          pattern: {
                            value: /\S+@\S+\.\S+/,
                            message: 'Email inválido'
                          }
                        }}
                      />
                      <Input
                        className="col-xl"
                        label="Telefone Pessoal*"
                        mask={{
                          mask: '(00) 00000-0000'
                        }}
                        name="phone"
                        rules={{ required: changeTab !== 'previous' }}
                        controlled
                        hasCustom
                      />
                    </div>
                    <div className="row mb-5">
                      <h2>Onde trabalha, estuda ou realiza atividades</h2>
                    </div>

                    <div className="row mb-5">
                      <Input
                        className="col-xl"
                        label="CEP*"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        name="work.zip_code"
                        mask={{
                          mask: '00000-000',
                          type: 'zipCode',
                          prefixInputsData: 'work.'
                        }}
                        controlled
                        rules={{
                          required: changeTab !== 'previous',
                          minLength: { value: 8, message: 'Cep Inválido' }
                        }}
                        maxLength={10}
                        hasCustom
                      />
                      <Select
                        blank
                        className="col-xl"
                        label="Pais de residência*:"
                        name="work.country"
                        hasCustom
                        rules={{
                          required: changeTab !== 'previous'
                        }}
                        options={countries}
                        controlled
                      />
                      <Input
                        className="col-xl"
                        label="Endereço*"
                        name="work.street"
                        rules={{ required: changeTab !== 'previous' }}
                        hasCustom
                      />
                    </div>
                    <div className="row mb-5">
                      <Input
                        className="col-xl"
                        label="Número*"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        name="work.number"
                        rules={{ required: changeTab !== 'previous' }}
                        hasCustom
                      />
                      <Input
                        className="col-xl"
                        label="Complemento"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        name="work.complement"
                        hasCustom
                      />
                    </div>
                    <div className="row mb-5">
                      <Input
                        className="col-xl"
                        label="Cidade*"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        name="work.city"
                        rules={{ required: changeTab !== 'previous' }}
                        hasCustom
                      />
                      <Input
                        className="col-xl"
                        label="Bairro*"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        name="work.district"
                        rules={{ required: changeTab !== 'previous' }}
                        hasCustom
                      />
                    </div>

                    <div className="row mb-5">
                      <Input
                        className="col-xl-3"
                        label="Ocupação*:"
                        name="ocupation"
                        rules={{ required: changeTab !== 'previous' }}
                        hasCustom
                      />
                      <Select
                        blank
                        className="col-xl-4"
                        label="Tipo de vinculo trabalhista*"
                        name="Type_employment"
                        hasCustom
                        options={optionsValue?.typeEmployment || []}
                        rules={{
                          required: changeTab !== 'previous'
                        }}
                        controlled
                      />
                      <DatePicker
                        locale="ptBR"
                        name="admission_date"
                        className="col-xl"
                        label="Data de admissão, abertura da empresa, ou entrada na escola*:"
                        dateFormat="dd/MM/yyyy"
                        placeholderText="DD/MM/AAAA"
                        hasCustom
                        rules={{ required: changeTab !== 'previous' }}
                        selected={admissionDate}
                        onChange={date => {
                          setAdmissionDate(date)
                        }}
                        customInput={
                          <IMaskInput
                            mask={Date}
                            pattern={'d/m/Y'}
                            format={date => {
                              console.log(date)
                              return moment(date).format(inputMaskFormat)
                            }}
                            parse={value => {
                              console.log(value)
                              return moment(value, inputMaskFormat).toDate()
                            }}
                          />
                        }
                        controlled
                      />
                    </div>
                    <div className="row mb-5">
                      <Input
                        className="col-xl"
                        label="Telefone Comercial*"
                        mask={{
                          mask: '(00) 0000-0000'
                        }}
                        name="commercial_phone"
                        rules={{ required: changeTab !== 'previous' }}
                        controlled
                        hasCustom
                      />
                      <Input
                        className="col-xl"
                        label="Renda mensal*:"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        name="monthly_income"
                        price
                        rules={{ required: changeTab !== 'previous' }}
                        hasCustom
                      />
                      <Select
                        blank
                        className="col-xl"
                        label="Possúi imóvel em seu nome?*"
                        name="own_property"
                        options={optionsSimple}
                        rules={{ required: changeTab !== 'previous' }}
                        controlled
                        hasCustom
                      />
                    </div>
                    <div className="row mb-5">
                      <Select
                        blank
                        className="col-xl-3"
                        label="Conta com permanência legal?*"
                        name="status_legal"
                        rules={{ required: changeTab !== 'previous' }}
                        options={optionsSimple}
                        controlled
                        hasCustom
                      />
                      <Select
                        blank
                        className="col-xl-3"
                        label="Tem antecedentes penais?*"
                        name="hasCriminalRecord.check"
                        options={optionsSimple}
                        value={hasCriminalRecord}
                        rules={{ required: changeTab !== 'previous' }}
                        onChange={event =>
                          setHasCriminalRecord(event.target.value)
                        }
                        hasCustom
                        controlled
                      />
                      <Input
                        className="col-xl"
                        label="Em caso afirmativo especificar:"
                        name="hasCriminalRecord.explain"
                        hasCustom
                        rules={{
                          required:
                            changeTab !== 'previous' &&
                            hasCriminalRecord.toLowerCase() === 'sim'
                        }}
                      />
                    </div>
                    <div className="row mb-5">
                      <Textarea
                        className="col-xl"
                        name="observations"
                        label="Observações"
                        style={{ minHeight: 100 }}
                        maxLength={300}
                      />
                    </div>
                    <div className="separator border-2 my-6"></div>
                    <div className="row d-flex align-items-end justify-content-between">
                      <div className="text-center col-xl-2 mw-100px d-flex align-items-end justify-content-start">
                        <button
                          type="submit"
                          onClick={() => setChangeTab('previous')}
                          className="btn btn-xl btn-primary mb-5"
                        >
                          <span className="indicator-label">Anterior</span>
                        </button>
                      </div>
                      <div className="text-center col-xl-2 mw-100px d-flex align-items-end justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-xl btn-primary mb-5"
                        >
                          <span className="indicator-label">Próxima</span>
                        </button>
                      </div>
                    </div>
                  </Form>
                </div>
              </Tab>
              <Tab notClick title="Informação e Motivo da viagem" key={2}>
                <div className="form mt-5">
                  <Form
                    className="form"
                    onSubmit={
                      changeTab === 'previous' ? handleNextForm : onSubmit
                    }
                    defaultValues={dataForm}
                  >
                    <h3 className="mb-5">
                      LOCAL ONDE SE HOSPEDARÁ NO MÉXICO (RESIDENCIA, HOTEL,
                      ESCOLA, OUTROS)
                    </h3>
                    <div className="row mb-5">
                      <Input
                        className="col-xl pe-xl-0"
                        inputOptions={{ classname: 'pe-xl-0' }}
                        label="Nome*"
                        name="place.name"
                        rules={{ required: changeTab !== 'previous' }}
                        hasCustom
                      />
                    </div>
                    <div className="row mb-5">
                      <Input
                        className="col-xl"
                        label="CEP*"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        name="place.zip_code"
                        mask={{
                          mask: '00000-000'
                          // type: 'zipCode'
                          // prefixInputsData: 'place.'
                        }}
                        controlled
                        rules={{
                          required: changeTab !== 'previous',
                          minLength: { value: 8, message: 'Cep Inválido' }
                        }}
                        maxLength={10}
                        hasCustom
                      />
                      <Input
                        className="col-xl"
                        label="Endereço*"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        name="place.street"
                        rules={{ required: changeTab !== 'previous' }}
                        hasCustom
                      />
                    </div>
                    <div className="row mb-5">
                      <Input
                        className="col-xl"
                        label="Número*"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        name="place.number"
                        rules={{ required: changeTab !== 'previous' }}
                        hasCustom
                      />
                      <Input
                        className="col-xl"
                        label="Complemento"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        name="place.complement"
                        hasCustom
                      />
                    </div>
                    <div className="row mb-5">
                      <Input
                        className="col-xl"
                        label="Cidade*"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        name="place.city"
                        rules={{ required: changeTab !== 'previous' }}
                        hasCustom
                      />
                      <Input
                        className="col-xl"
                        label="Bairro*"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        name="place.district"
                        rules={{ required: changeTab !== 'previous' }}
                        hasCustom
                      />
                    </div>
                    <div className="row mb-5">
                      <Input
                        className="col-xl"
                        label="Telefone*"
                        inputOptions={{
                          classname: 'd-flex align-items-center'
                        }}
                        mask={{
                          mask: '(00) 00000-0000'
                        }}
                        name="place.phone"
                        rules={{ required: changeTab !== 'previous' }}
                        controlled
                        hasCustom
                      />
                      <Input
                        className="col-xl"
                        label="Cidade por onde entrará*:"
                        name="city_enter"
                        rules={{ required: changeTab !== 'previous' }}
                        hasCustom
                      />
                      <DatePicker
                        locale="ptBR"
                        name="date_entry_mexico"
                        className="col-xl"
                        label="Data de Entrada no México*:"
                        dateFormat="dd/MM/yyyy"
                        placeholderText="DD/MM/AAAA"
                        hasCustom
                        selected={entryMexicoDate}
                        rules={{ required: changeTab !== 'previous' }}
                        customInput={
                          <IMaskInput
                            mask={Date}
                            pattern={'d/m/Y'}
                            format={date => {
                              return moment(date).format(inputMaskFormat)
                            }}
                            parse={value => {
                              return moment(value, inputMaskFormat).toDate()
                            }}
                          />
                        }
                        onChange={date => {
                          setEntryMexicoDate(date)
                        }}
                        controlled
                      />
                      <Select
                        blank
                        className="col-xl"
                        label="Tempo de permanência*:"
                        name="lenght_stay"
                        hasCustom
                        options={optionsValue?.lenghtStay || []}
                        rules={{ required: changeTab !== 'previous' }}
                        controlled
                      />
                    </div>
                    <div className="row mb-5">
                      <Select
                        blank
                        className="col-xl-3"
                        label="Já visitou o México antes?*"
                        name="have_visited_mexico"
                        options={optionsSimple}
                        rules={{ required: changeTab !== 'previous' }}
                        hasCustom
                        controlled
                      />
                      <Select
                        blank
                        className="col-xl-3"
                        label="Foi deportado do México*"
                        name="was_deported.check"
                        options={optionsSimple}
                        onChange={event => setWasDeported(event.target.value)}
                        rules={{ required: changeTab !== 'previous' }}
                        hasCustom
                        controlled
                      />
                      <Input
                        className="col-xl"
                        label="Em caso afirmativo indicar a causa:"
                        name="was_deported.explain"
                        rules={{
                          required:
                            changeTab !== 'previous' &&
                            wasDeported.toLowerCase() === 'sim'
                        }}
                        hasCustom
                      />
                    </div>
                    <div className="row mb-5">
                      {companions.map(
                        (familiar, index) =>
                          familiar && (
                            <FormCompanion
                              changeTab={changeTab}
                              key={`${Math.random() * 9999}`}
                              inputName={{ name: `companions.${index}.name` }}
                              selectRelationship={{
                                name: `companions.${index}.relationship`,
                                options: optionsValue?.parentage || [],
                                blank: true
                              }}
                              negative={{
                                name: `companions.${index + 1}.isActive`
                              }}
                              positive={{
                                name: `companions.${index + 1}.isActive`
                              }}
                              label="Vai viajar com mais alguém?"
                              hasHeader={
                                index === 0
                                  ? {
                                      negative: {
                                        name: `companions.${index}.isActive`
                                      },
                                      positive: {
                                        name: `companions.${index}.isActive`
                                      },
                                      label: 'Vai viajar com alguém?'
                                    }
                                  : undefined
                              }
                              data={companions}
                              setData={setCompanions}
                              index={index}
                            />
                          )
                      )}
                    </div>
                    <div className="row mb-5">
                      <Textarea
                        name="travel_purpose"
                        label="Propósito de viagem ao México*"
                        style={{ minHeight: 100 }}
                        maxLength={300}
                      />
                    </div>
                    <h1 className="mb-5">Menores de idade</h1>
                    <h6>
                      Em caso de menores, adolescentes ou pessoas que se
                      encontram sujeita a tutela jurídica conforme a legislação
                      civil deverá apresentar a solicitação do visto assinada
                      pela mãe e pai, ou por quem exerça o pátrio poder ou a
                      tutela. Se a solicitação é apresentada por um dos
                      progenitores deverão apresentar autorização perante
                      cartório do outro progenitor que autorize a tramitar o
                      visto. Quando vão sozinhos ou com terceiro maior de idade,
                      devem apresentar documento outorgado ante notário público
                      ou por autoridade que tenha faculdade para isso no que
                      conste a autorização de ambos os pais ou de quem exerçam
                      sobre eles o pátrio poder ou tutela. Em caso de menores de
                      idade, a assinatura será dos pais ou tutores, ou quem
                      exerça o pátrio poder
                    </h6>
                    <h1>Documentos que se adjuntam a solicitação</h1>
                    <div className="row mb-5 flex-xl-column">
                      <Input
                        className="col-xl"
                        label="Documento:"
                        name="document_1"
                        hasCustom
                      />
                      <Input
                        className="col-xl"
                        label="Documento:"
                        name="document_2"
                        hasCustom
                      />
                      <Input
                        className="col-xl"
                        label="Documento:"
                        name="document_3"
                        hasCustom
                      />
                      <Input
                        className="col-xl"
                        label="Documento:"
                        name="document_4"
                        hasCustom
                      />
                      <Input
                        className="col-xl"
                        label="Documento:"
                        name="document_5"
                        hasCustom
                      />
                    </div>
                    <div className="row mb-5">
                      <h6>
                        Nos casos que o solicitante apresente documentos
                        alterados, apócrifos, ou obtidos fraudulentamente será
                        registrado nas listas de controle migratório e não
                        poderá obter entrar no México. De acordo à Lei e seu
                        Regulamento, o visto mexicano permite-lhe à pessoa
                        estrangeira apresenta-se a um lugar destinado ao
                        transito internacional de pessoas e no filtro de revisão
                        migratória e solicitar sua entrada a território
                        nacional. A apresentação do visto não garante a entro ao
                        território nacional. A entrada a México está
                        condicionada à aprovação das autoridades sanitárias e
                        migratórias no ponto de entrada, as que poderão
                        verificar em todo momento os requisitos estabelecidos
                        nas disposições jurídicas aplicáveis no filtro de
                        revisão migratória. Os dados contidos nesta solicitação
                        são confidenciais de acordo ao disposto nos artigos 18,
                        fração I e II, e 19 da Lei Federal de Transparência e
                        Acesso à informação Pública Governamental e serão
                        tratados como dispõem os artigos 20, 21 e 22 da mesma
                        Lei.
                      </h6>
                    </div>
                    <div className="row mb-5">
                      <h1>Anexos</h1>
                      <p>
                        Insira seus documentos. Ex (Passaporte, visto
                        anteriores, foto digital e etc)
                      </p>
                    </div>
                    {attachments.map(attachment => {
                      return attachment?.name ? (
                        <div className="row mb-5" key={Math.random() * 9999}>
                          <div className="col-sm">
                            <Input
                              name={`attachments.${attachment.id}.name`}
                              value={attachment.name}
                              readOnly
                            />
                          </div>
                          <div className="col-sm d-flex justify-content-between container-download">
                            {!!attachment.url && (
                              <div className="col-xl-3 col-sm mb-5 mw-150px">
                                <button
                                  type="button"
                                  className="btn btn-lg btn-block btn-primary"
                                  onClick={() =>
                                    handleDownloadAttachments(attachment)
                                  }
                                >
                                  Baixar
                                </button>
                              </div>
                            )}
                            <div className="col-sm">
                              <button
                                type="button"
                                className="btn btn-lg btn-block btn-primary"
                                onClick={() =>
                                  handleRemoveAttachments(attachment?.id)
                                }
                              >
                                Excluir
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="row mb-5 d-flex"
                          key={Math.random() * 9999}
                        >
                          <Input
                            className="col-sm"
                            inputOptions={{ classname: 'pe-1' }}
                            label=""
                            name={`attachments.${attachment.id}`}
                            onChange={event => {
                              if (
                                event.target.files?.[0]?.size &&
                                event.target.files?.[0]?.size / 1024 > 6000
                              ) {
                                addToast({
                                  title: 'Arquivo grande',
                                  description:
                                    'O arquivo precisa ter menos que 5MB',
                                  type: 'info'
                                })
                                return (event.target.value = '')
                              }
                              return event
                            }}
                            type="file"
                            hasCustom
                          />
                          <div className="col-sm ms-md-5">
                            <button
                              type="button"
                              className="btn btn-lg btn-block btn-primary"
                              onClick={() =>
                                handleRemoveAttachments(attachment?.id)
                              }
                            >
                              Excluir
                            </button>
                          </div>
                        </div>
                      )
                    })}
                    <div className="row d-flex align-items-end justify-content-end">
                      <div className="text-center col-md-2 mw-100px d-flex align-items-end justify-content-end">
                        <button
                          type="button"
                          className="btn btn-xl btn-primary mb-5"
                          onClick={() => {
                            const findBigger = Math.max(
                              ...attachments.map(attachment => attachment.id)
                            )
                            const biggerId =
                              findBigger === Infinity ||
                              findBigger === -Infinity
                                ? 1
                                : findBigger
                            setAttachments(old => {
                              const copyOld = [...old]
                              copyOld.push({
                                id: (biggerId || 1) + 1
                              })
                              return copyOld
                            })
                          }}
                        >
                          <span className="indicator-label">Adicionar</span>
                        </button>
                      </div>
                    </div>
                    <div className="separator border-2 my-6"></div>
                    <div className="row d-flex align-items-end justify-content-between">
                      <div className="text-center col-md-2 mw-100px d-flex align-items-end justify-content-start">
                        <button
                          type="submit"
                          onClick={() => setChangeTab('previous')}
                          className="btn btn-xl btn-primary mb-5"
                        >
                          <span className="indicator-label">Anterior</span>
                        </button>
                      </div>
                      <div className="text-center col-md-2 mw-100px d-flex align-items-end justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-xl btn-primary mb-5"
                        >
                          <span className="indicator-label">Enviar</span>
                        </button>
                      </div>
                    </div>
                  </Form>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </ContainerWrapperContent>
      <Alert
        message={
          'Foi selecionada nacionalidade estrangeira, essa solicitação será para passaporte para estrangeiro (caderneta amarela). Caso seja brasileiro e deseje passaporte comum (caderneta azul), selecione a nacionalidade Brasil. Deseja prosseguir assim mesmo?'
        }
        onClickCancellButton={handlerClickButtonCancellAlert}
        onClickConfirmButton={handlerClickButtonConfirmAlert}
        isActive={alert.isActive}
      />
    </div>
  )
}

export default Mexico
