import React, { useCallback, useEffect, useState } from 'react'
import api from '../../../../../services/api'
import Form, { Select } from '../../../../../components/Form'
import { useHistory } from 'react-router-dom'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { apiUpdate } from '../../domain/api'
import { ContractData, nameActions } from '../../domain/info'
import { FormContainer } from './styles'

type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
}
type TypesFormProps = {
  isOpenInModal?: false | IsOpenInModalProps
  initialValues?: ContractData & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}
export const FormContract = ({
  isOpenInModal,
  initialValues,
  typeForm
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()
  const [status, setStatus] = useState<string>('')
  const [tranformStatus] = useState<any>({
    Novo: 'new',
    'Em Andamento': 'in progress',
    Concluído: 'concluded'
  })

  useEffect(() => {
    if (initialValues) {
      setStatus(tranformStatus[initialValues?.status])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues])

  const { activeLoading, disableLoading } = useLoading()

  const onSubmitForm = useCallback(
    async (data: any) => {
      data = {
        ...data
      }
      const id = initialValues?.idUpdate

      try {
        if (isOpenInModal) {
          const { handleOnClose } = isOpenInModal

          try {
            activeLoading()
            await api.put(apiUpdate(String(id)), data)
            updateDataTable()
            disableLoading()
            handleOnClose()
            addToast({
              type: 'success',
              title: 'Registro atualizado',
              description: 'Registro alterado com sucesso'
            })
          } catch (error) {
            disableLoading()
            handleOnClose()
            addToast({
              type: 'error',
              title: 'Erro ao atualizar o registro',
              description:
                'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
            })
          }
        } else {
          try {
            activeLoading()
            await api.put(apiUpdate(String(id)), data)
            updateDataTable()
            disableLoading()
            history.push(nameActions.read.to)
            addToast({
              type: 'success',
              title: 'Registro atualizado',
              description: 'Registro alterado com sucesso'
            })
          } catch (error) {
            history.push(nameActions.read.to)
            addToast({
              type: 'error',
              title: 'Erro ao atualizar o registro',
              description:
                'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
            })
          }
        }
        disableLoading()
      } catch (err) {
        if (typeForm === 'create') {
          addToast({
            type: 'error',
            title: 'Erro no cadastro',
            description:
              'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
          })
          if (isOpenInModal) isOpenInModal.handleOnClose()
        }
      }
    },
    [
      activeLoading,
      addToast,
      disableLoading,
      history,
      initialValues,
      isOpenInModal,
      typeForm,
      updateDataTable
    ]
  )

  return (
    <>
      <Form onSubmit={onSubmitForm}>
        <>
          <div className="card">
            <FormContainer>
              <div className="row mb-5">
                <Select
                  className="col-sm"
                  name="status"
                  label="Status"
                  options={[
                    {
                      name: 'Novo',
                      value: 'new'
                    },
                    {
                      name: 'Em Andamento',
                      value: 'in progress'
                    },
                    {
                      name: 'Concluído',
                      value: 'concluded'
                    }
                  ]}
                  rules={{ required: true }}
                  blank
                  controlled
                  value={status || ''}
                  onChange={event => setStatus(event.target.value)}
                />
              </div>
            </FormContainer>
            <div className="card-footer d-flex justify-content-end py-6 px-9">
              <button type="submit" className="btn btn-primary">
                Salvar
              </button>
            </div>
          </div>
        </>
      </Form>
    </>
  )
}
