import { Header } from '../../../../../components/DataTable'

export const headers: Header[] = [
  { name: 'Nome Completo', field: 'name', sortable: true },
  { name: 'CPF', field: 'cpf', sortable: true },
  { name: 'Email', field: 'email', sortable: true },
  { name: 'Status', field: 'status', sortable: true },
  { name: 'Tipo', field: 'type', sortable: true },
  { name: 'Criado em', field: 'created_at', sortable: true },
  {
    name: 'Ações',
    field: 'actions',
    sortable: false
  }
]

export const contractHeaders: Header[] = [
  { name: 'Nome', field: 'applicant_name', sortable: true },
  { name: 'CPF', field: 'cpf', sortable: true },
  { name: 'Email', field: 'email', sortable: true },
  { name: 'Valor', field: 'values', sortable: true },
  { name: 'Status', field: 'status', sortable: true },
  { name: 'Criado em', field: 'created_at', sortable: true }
]
