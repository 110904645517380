/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback, useEffect, useState } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import Container from '../../../../components/Container'
import api from '../../../../services/api'
import { useToast } from '../../../../hooks/toast'
import { useLoading } from '../../../../hooks/loading'
import { Alert } from '../../../../components/Alert'
import { nameActions } from '../domain/info'
import { apiDelete, apiList } from '../domain/api'
import { breadcrumbView } from '../domain/breadcrumb'
import {
  toolsViewCreate,
  toolsViewDelete,
  toolsViewUpdate,
  toolsViewList
} from '../domain/tools'
import { phoneMask } from '../../../../utlis/mask'
import { ModalImage } from './styles'

type FormData = {
  full_name: string
  gender: string
  parentage: {
    name: string
    gender: string
  }[]
  race_color: string
  marital_status: string
  nationality: string
  country: string
  state: string
  city: string
  previousNames: {
    name: string
    reason: string
  }[]
  document_number: string
  issue_date: Date
  birth_date: Date
  issuing_body: string
  state_expedition: string
  cpf: string
  cpf_responsible: string
  certificate: {
    enrollment: string
    type: string
    number: string
    book: string
    sheet: string
    registry: string
    state_expedition: string
    city_expedition: string
  }
  previousPassport: {
    situation: string
    series: string
    number: string
    hasUpdateName: boolean
  }
  profession: string
  email: string
  email_confirmation: string
  applicant: {
    country: string
    zip_code: string
    state: string
    city: string
    street: string
    district: string
    ddd: string
    phone: string
  }
  emancipated: boolean
  international_adoption: boolean
  new_model_certificate: boolean
}

interface ClientData {
  id: string
  name?: string
  cpf?: string
  type?: string
  values?: FormData
}

const ClientView = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const location = useLocation()
  const [client, setClient] = useState<ClientData | null>(null)
  const { addToast } = useToast()
  const [alert, setIsActiveAlert] = useState<{
    isActive: boolean
    id: number
    name: string
  }>({
    id: 0,
    isActive: false,
    name: ''
  })

  const { disableLoading, activeLoading } = useLoading()
  const checkFileType = (file: string): string => {
    if (file.match(/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i)) {
      return 'media'
    }
    return 'document'
  }
  useEffect(() => {
    activeLoading()
    async function loadClient(): Promise<void> {
      activeLoading()
      try {
        const response = await api.get<ClientData>(apiList(id))
        const { data } = response
        // const copyData: any = { ...data }
        // Object.entries(data).map(async ([key, value]) => {
        //   if (key.includes('url') && value) {
        //     const newKey = key.replaceAll('_url', '_type')
        //     const keyOrigin = key.replaceAll('_url', '')
        //     const type = checkFileType(value)
        //     value = value.substring(value.lastIndexOf('/') + 1)
        //     const indexTrace = value.indexOf('-') + 1
        //     const removedHash = value.slice(indexTrace, value.length)
        //     copyData[newKey] = type
        //     copyData[keyOrigin] = removedHash
        //   }
        //   return value
        // })
        setClient(data)
        disableLoading()
      } catch (err) {
        const [, baseUrl, path] = location.pathname.split('/')
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao carregar o cliente',
          description:
            'Houve um error ao carregar o cliente, tente novamente mais tarde!'
        })
        if (path.includes('view')) {
          history.push(`/${baseUrl}`)
        } else {
          history.push(`/${baseUrl}/${path}`)
        }
      }
    }
    loadClient()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLoading, addToast, disableLoading, history, location.pathname])
  const handlerClickButtonCancellAlert = () => {
    setIsActiveAlert({
      id: 0,
      isActive: false,
      name: ''
    })
    addToast({
      type: 'info',
      title: 'Operação cancelada.'
    })
  }

  const handlerClickButtonConfirmAlert = async (id: string) => {
    try {
      await api.delete(apiDelete(id))
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'success',
        title: 'Cliente removido com sucesso.'
      })
    } catch (err) {
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'error',
        title: 'Cliente não removido, pois ainda está sendo usada.'
      })
    }
  }

  const [alertRemoveParent, setAlertRemoveParent] = useState(false)

  const handleOnClickRemoveParent = () => {
    setAlertRemoveParent(true)
  }

  const handlerOnClickButtonConfirmRemoveParent = async (id: number) => {
    try {
      await api.delete(apiDelete(String(id)))
      setAlertRemoveParent(false)
      addToast({
        type: 'success',
        title: 'Cliente removido com sucesso.'
      })
      history.goBack()
    } catch (err: any) {
      setAlertRemoveParent(false)
      if (err.response.status === 403) {
        addToast({
          type: 'error',
          title: err.response.data.message
        })
        return
      }
      addToast({
        type: 'error',
        title: 'Cliente não removido, pois ainda está sendo usada.'
      })
    }
  }

  const handlerOnClickButtonCancelRemoveParent = () => {
    setAlertRemoveParent(false)
  }

  const handleDownload = useCallback(async (filename: string, url: string) => {
    const response = await fetch(url)
    const blob = await response.blob()

    const urlBlob = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.download = filename
    a.href = urlBlob
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }, [])
  return (
    <div className="d-flex flex-column container-fluid">
      <>
        <Container
          pageTitle={nameActions.read.name}
          breadcrumb={breadcrumbView}
          tools={[
            toolsViewUpdate(String(id)),
            toolsViewDelete(() => {
              handleOnClickRemoveParent()
            }),
            toolsViewCreate(),
            toolsViewList()
          ]}
        >
          <div className="card-body p-9">
            <div className="form-body">
              <div className="row mb-5">
                <div className="col-md-6">
                  <div className="form-group">
                    <label
                      htmlFor="desired_value"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      Nome completo
                    </label>
                    <p className="fw-bolder fs-6 text-gray-800">
                      {client?.name}
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      htmlFor="desired_value"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      Sexo
                    </label>
                    <p className="fw-bolder fs-6 text-gray-800">
                      {client?.values?.gender}
                    </p>
                  </div>
                </div>
              </div>
              {client?.values?.parentage?.map((parent, index) => (
                <div className="row mb-5" key={index}>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label
                        htmlFor="desired_value"
                        className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                      >
                        Filiação {index + 1}
                      </label>
                      <p className="fw-bolder fs-6 text-gray-800">
                        {parent.name}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label
                        htmlFor="desired_value"
                        className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                      >
                        Sexo
                      </label>
                      <p className="fw-bolder fs-6 text-gray-800">
                        {parent.gender}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Container>

        <Alert
          message={`Tem certeza que deseja excluir o registro ${alert.name} ?`}
          onClickCancellButton={handlerClickButtonCancellAlert}
          onClickConfirmButton={() => {
            handlerClickButtonConfirmAlert(String(alert.id))
          }}
          isActive={alert.isActive}
        />
        {/* <Alert
          message={`Tem certeza que deseja excluir o registro ${client?.full_name} ?`}
          onClickCancellButton={handlerOnClickButtonCancelRemoveParent}
          onClickConfirmButton={() =>
            handlerOnClickButtonConfirmRemoveParent(Number(client?.id))
          }
          isActive={alertRemoveParent}
        /> */}
      </>
      <div className="container-fluid mb-xl-10">
        <div className="card">
          <div className="card-body p-9">
            <h1>Dados Pessoais</h1>
            <div className="form-body">
              <div className="row mb-5">
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      htmlFor="number_installments"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      Data de nascimento
                    </label>
                    <p className="fw-bolder fs-6 text-gray-800">
                      {client?.values?.birth_date}
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      htmlFor="amount_receivable"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      Emancipado
                    </label>
                    <p className="fw-bolder fs-6 text-gray-800">
                      {client?.values?.emancipated ? 'Sim' : 'Não'}
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      htmlFor="status"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      Raça ou cor
                    </label>
                    <p className="fw-bolder fs-6 text-gray-800">
                      {client?.values?.race_color}
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      htmlFor="status"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      Adoção Internacional
                    </label>
                    <p className="fw-bolder fs-6 text-gray-800">
                      {client?.values?.international_adoption ? 'Sim' : 'Não'}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      htmlFor="number_installments"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      Estado civil
                    </label>
                    <p className="fw-bolder fs-6 text-gray-800">
                      {client?.values?.marital_status}
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      htmlFor="amount_receivable"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      Nacionalidade
                    </label>
                    <p className="fw-bolder fs-6 text-gray-800">
                      {client?.values?.nationality}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid mb-xl-10">
        <div className="card">
          <div className="card-body p-9">
            <h1>Local de nascimento</h1>
            <div className="form-body">
              <div className="row mb-5">
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      htmlFor="desired_value"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      País
                    </label>
                    <p className="fw-bolder fs-6 text-gray-800">
                      {client?.values?.country}
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      htmlFor="desired_value"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      UF
                    </label>
                    <p className="fw-bolder fs-6 text-gray-800">
                      {client?.values?.state}
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      htmlFor="number_installments"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      Cidade
                    </label>
                    <p className="fw-bolder fs-6 text-gray-800">
                      {client?.values?.city}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid mb-xl-10">
        <div className="card">
          <div className="card-body p-9">
            <h1>Nomes Anteriores</h1>
            <div className="form-body">
              {client?.values?.previousNames?.map((item, index) => (
                <div className="row mb-5" key={index}>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label
                        htmlFor="desired_value"
                        className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                      >
                        Nome
                      </label>
                      <p className="fw-bolder fs-6 text-gray-800">
                        {item.name}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label
                        htmlFor="desired_value"
                        className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                      >
                        Motivo
                      </label>
                      <p className="fw-bolder fs-6 text-gray-800">
                        {item.reason}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid mb-xl-10">
        <div className="card">
          <div className="card-body p-9">
            <h1>Documentos de Identificação</h1>
            <div className="form-body">
              <div className="row mb-5">
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      htmlFor="desired_value"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      Número
                    </label>
                    <p className="fw-bolder fs-6 text-gray-800">
                      {client?.values?.document_number}
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      htmlFor="desired_value"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      Data de emissão
                    </label>
                    <p className="fw-bolder fs-6 text-gray-800">
                      {client?.values?.issue_date}
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      htmlFor="desired_value"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      Órgão emissor
                    </label>
                    <p className="fw-bolder fs-6 text-gray-800">
                      {client?.values?.issuing_body}
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      htmlFor="desired_value"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      UF de expedição
                    </label>
                    <p className="fw-bolder fs-6 text-gray-800">
                      {client?.values?.state_expedition}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid mb-xl-10">
        <div className="card">
          <div className="card-body p-9">
            <h1>CPF</h1>
            <div className="form-body">
              <div className="row mb-5">
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      htmlFor="desired_value"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      Número
                    </label>
                    <p className="fw-bolder fs-6 text-gray-800">
                      {client?.values?.cpf}
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      htmlFor="desired_value"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      Responsável
                    </label>
                    <p className="fw-bolder fs-6 text-gray-800">
                      {client?.values?.cpf_responsible}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid mb-xl-10">
        <div className="card">
          <div className="card-body p-9">
            <h1>Certidão</h1>
            <div className="form-body">
              <div className="row mb-5">
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      htmlFor="desired_value"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      Modelo novo
                    </label>
                    <p className="fw-bolder fs-6 text-gray-800">
                      {client?.values?.new_model_certificate ? 'Sim' : 'Não'}
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      htmlFor="desired_value"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      Matrícula
                    </label>
                    <p className="fw-bolder fs-6 text-gray-800">
                      {client?.values?.certificate?.enrollment}
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      htmlFor="desired_value"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      Tipo
                    </label>
                    <p className="fw-bolder fs-6 text-gray-800">
                      {client?.values?.certificate?.type}
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      htmlFor="desired_value"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      Cartório
                    </label>
                    <p className="fw-bolder fs-6 text-gray-800">
                      {client?.values?.certificate?.registry}
                    </p>
                  </div>
                </div>
              </div>

              <div className="row mb-5">
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      htmlFor="desired_value"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      Número
                    </label>
                    <p className="fw-bolder fs-6 text-gray-800">
                      {client?.values?.certificate.number}
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      htmlFor="desired_value"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      Livro
                    </label>
                    <p className="fw-bolder fs-6 text-gray-800">
                      {client?.values?.certificate?.book}
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      htmlFor="desired_value"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      Folha
                    </label>
                    <p className="fw-bolder fs-6 text-gray-800">
                      {client?.values?.certificate?.sheet}
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      htmlFor="desired_value"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      UF de Expedição
                    </label>
                    <p className="fw-bolder fs-6 text-gray-800">
                      {client?.values?.certificate?.state_expedition}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      htmlFor="desired_value"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      Cidade de Expedição
                    </label>
                    <p className="fw-bolder fs-6 text-gray-800">
                      {client?.values?.certificate?.city_expedition}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid mb-xl-10">
        <div className="card">
          <div className="card-body p-9">
            <h1>Passaporte Anterior</h1>
            <div className="form-body">
              <div className="row mb-5">
                <div className="col-md-6">
                  <div className="form-group">
                    <label
                      htmlFor="desired_value"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      Situação
                    </label>
                    <p className="fw-bolder fs-6 text-gray-800">
                      {client?.values?.previousPassport?.situation}
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      htmlFor="desired_value"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      Série
                    </label>
                    <p className="fw-bolder fs-6 text-gray-800">
                      {client?.values?.previousPassport?.series}
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      htmlFor="desired_value"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      Número
                    </label>
                    <p className="fw-bolder fs-6 text-gray-800">
                      {client?.values?.previousPassport?.number}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-md-12">
                  <div className="form-group">
                    <label
                      htmlFor="desired_value"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      Houve alteração em seu nome/sobrenome por motivo de
                      casamento, divórcio ou decisão judicial, após a expedição
                      do último passaporte?
                    </label>
                    <p className="fw-bolder fs-6 text-gray-800">
                      {client?.values?.previousPassport?.hasUpdateName
                        ? 'Sim'
                        : 'Não'}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid mb-xl-10">
        <div className="card">
          <div className="card-body p-9">
            <h1>Dados complementares</h1>
            <div className="form-body">
              <div className="row mb-5">
                <div className="col-md-6">
                  <div className="form-group">
                    <label
                      htmlFor="desired_value"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      Profissão
                    </label>
                    <p className="fw-bolder fs-6 text-gray-800">
                      {client?.values?.profession}
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      htmlFor="desired_value"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      Email
                    </label>
                    <p className="fw-bolder fs-6 text-gray-800">
                      {client?.values?.email}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid mb-xl-10">
        <div className="card">
          <div className="card-body p-9">
            <h1>Endereço do requerente</h1>
            <div className="form-body">
              <div className="row mb-5">
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      htmlFor="desired_value"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      País
                    </label>
                    <p className="fw-bolder fs-6 text-gray-800">
                      {client?.values?.applicant?.country}
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      htmlFor="desired_value"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      CEP
                    </label>
                    <p className="fw-bolder fs-6 text-gray-800">
                      {client?.values?.applicant?.zip_code}
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      htmlFor="desired_value"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      UF
                    </label>
                    <p className="fw-bolder fs-6 text-gray-800">
                      {client?.values?.applicant?.state}
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      htmlFor="desired_value"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      Cidade
                    </label>
                    <p className="fw-bolder fs-6 text-gray-800">
                      {client?.values?.applicant?.city}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      htmlFor="desired_value"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      Logradouro
                    </label>
                    <p className="fw-bolder fs-6 text-gray-800">
                      {client?.values?.applicant?.street}
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      htmlFor="desired_value"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      Distrito/Bairro
                    </label>
                    <p className="fw-bolder fs-6 text-gray-800">
                      {client?.values?.applicant?.district}
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      htmlFor="desired_value"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      Telefone
                    </label>
                    <p className="fw-bolder fs-6 text-gray-800">
                      ({client?.values?.applicant?.ddd}){' '}
                      {client?.values?.applicant?.phone}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClientView
