import styled, { css, keyframes } from 'styled-components'
import Tooltip from '../../../../../../components/Tooltip'

interface ContainerAnimation {
  isShow: string
  height?: string
  id?: string
}

const topToBottom = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`

export const ContainerHeader = styled.div`
  background-color: #fff;
  box-shadow: 0px 4px 6px 0px rgb(12 0 46 / 6%);
  margin-bottom: 20px;
  height: 64px;
`

export const WrapperContent = styled.div`
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  height: 100%;
`

export const ContainerLogo = styled.div``

export const ContainerWrapperContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  .mobile-margin {
    input {
      margin-left: 15px;
    }
  }
  input:disabled {
    background-color: #eaeaea !important;
  }
  select:disabled {
    background-color: #eaeaea !important;
  }
  .mw-xs {
    @media (min-width: 1200px) {
      input {
        max-width: 236px;
      }
    }
  }
`

export const Table = styled.table`
  @media (max-width: 1200px) {
    thead.table-border {
      tr {
        th {
          padding: 5px;
        }
      }
    }
    /* border: 1px solid #ccc; */
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;

    tr {
      /* background-color: #f8f8f8;
      border: 1px solid #ddd; */
      padding: 0.35em;
    }

    th,
    td {
      padding: 0.625em;
      text-align: left;
    }

    th {
      font-size: 0.85em;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }
    border: 0;

    thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    tr {
      /* border-bottom: 3px solid #ddd; */
      display: block;
      margin-bottom: 0.625em;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, auto));
    }

    td {
      /* border-bottom: 1px solid #ddd; */
      font-size: 1em;
      text-align: left;
      display: flex;
      flex-wrap: wrap;
      font-weight: 600;
      max-width: 250px;
    }

    td::before {
      content: attr(data-label);
      /* float: left; */
      /* font-weight: bold; */
      width: 100%;
      font-weight: 500;
      text-transform: uppercase;
    }

    td:last-child {
      border-bottom: 0;
    }
    .responsive-align {
      display: flex;
      justify-content: start !important;
      align-items: center;
    }
  }
`
export const Row = styled.tr`
  background: #fbfcfd;
`

export const LinkContainer = styled.div`
  display: flex;
  justify-content: center;

  > div {
    margin: 0px 0.4em;

    cursor: pointer;
  }

  a + a {
    margin-left: 5px;
  }
`

export const Error = styled(Tooltip)`
  height: 20px;
  width: fit-content;
  margin: 0;
  svg {
    margin: 0;
  }

  span {
    width: max-content;
    background: #c53030;
    color: #fff;
    &::before {
      border-color: #c53030 transparent;
    }
  }
`

export const ContainerAnimation = styled.div<ContainerAnimation>`
  /* display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; */
  height: 0px;
  transition: height 0.7s ease-in-out;
  clear: both;
  opacity: 0;
  /* position: absolute; */
  /* margin-top: 6.7rem;
  margin-left: 9px; */
  overflow: hidden;
  padding: 0;
  will-change: height;
  ${props => {
    if (props.isShow === 'show') {
      return css`
        width: 100%;
        /* position: absolute; */
        /* background-color: #fff; */
        opacity: 1;
        /* height: 800px; */
        height: ${document.getElementById(props.id)?.scrollHeight}px;
        /* margin-top: 6.7rem;
        margin-left: 9px; */
        z-index: 1;
        /* box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
          0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%); */
      `
    }
    if (props.isShow === 'hidden') {
      return css`
        opacity: 0;
        height: 0;
        animation: ${topToBottom} 1s ease-in-out;
      `
    }
  }}
`
