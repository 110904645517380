import React, {
  ReactElement,
  useCallback,
  useEffect,
  useLayoutEffect
} from 'react'
import { useFormContext } from 'react-hook-form'
import { Checkbox, Input, InputProps } from '../../../../../../components/Form'
import { ContainerAnimation } from './styles'
import useCollapse from 'react-collapsed'
interface CheckboxWithFormProps {
  label: string
  positive: InputProps
  negative: InputProps
  changeTab?: React.SetStateAction<string>
}

export const CheckboxWithForm: React.FC<CheckboxWithFormProps> = ({
  label,
  negative,
  positive,
  changeTab,
  children
}) => {
  const {
    register,
    watch,
    resetField,
    getValues,
    clearErrors,
    setValue,
    formState: { errors }
  } = useFormContext()
  const watchField = watch(positive?.name)
  useEffect(() => {
    if (watchField === 'false') {
      const prefixArray = positive.name.split('.')
      if (prefixArray.length > 1) {
        prefixArray.pop()
      }
      const prefix = prefixArray.join('.')
      // console.log(positive.name.split('.'))
      // console.log(prefixArray)
      // console.log(prefix)
      const workFields = getValues(prefix)
      if (workFields) {
        const objectEntries = Object.entries(workFields)
        for (let [key] of objectEntries) {
          key = `${prefix}.${key}`
          if (positive.name !== key) {
            // setValue(key, '')
          }
        }
      }
      clearErrors(prefix)
    }
  }, [clearErrors, getValues, positive.name, resetField, setValue, watchField])

  const hasOpen = watchField === 'true'

  const { getToggleProps, getCollapseProps } = useCollapse({
    isExpanded: hasOpen
  })
  const registeredField = useCallback(
    (child: ReactElement) => {
      let rules

      if (child.props?.hasValidationParent) {
        const watchField = watch(child.props?.hasValidationParent.parent_name)
        rules = {
          ...child.props?.rules,
          required:
            watchField ===
            (child.props?.hasValidationParent?.condition || 'true')
        }
      }
      return React.createElement(child.type, {
        ...{
          ...child.props,
          register,
          rules,
          errors,
          key: child.props.name
        }
      })
    },
    [errors, register, watch]
  )

  const buildChildren = useCallback(
    (children: any, key = 0): any => {
      if (Array.isArray(children)) {
        return children.map((child: ReactElement, index) => {
          return buildChildren(child, index)
        })
      }

      if (children?.props?.children) {
        const childCopy = React.cloneElement(children, {
          key,
          children: buildChildren(children.props.children)
        })
        return childCopy
      }
      return children?.props?.name ? registeredField(children) : children
    },
    [registeredField]
  )
  return (
    <div className="">
      <div>
        <p className="mb-2 form-check-label text-gray-700 fw-bold p-0">
          {label}
        </p>
        <div className="d-xl-flex">
          <Checkbox
            className="col-sm col-xl-2 mb-2"
            label="Sim"
            register={register}
            value="true"
            inputOptions={{
              classname: 'd-flex align-items-center'
            }}
            rules={{ required: changeTab !== 'previous' }}
            type="radio"
            errors={errors}
            hasCustom
            hasErrorCustom
            {...positive}
            {...getToggleProps()}
          />
          <Checkbox
            className="col-sm col-xl-2 mb-2"
            label="Não"
            register={register}
            inputOptions={{
              classname: 'd-flex align-items-center'
            }}
            type="radio"
            value="false"
            rules={{ required: changeTab !== 'previous' }}
            errors={errors}
            hasCustom
            {...negative}
          />
        </div>
      </div>
      <div
        {...getCollapseProps()}
        // isShow={watchField === 'true' ? 'show' : 'hidden'}
        // id={positive.name}
      >
        {/* {children} */}
        {buildChildren(children)}
      </div>
    </div>
  )
}
