import React, { ReactElement, useState } from 'react'
import TabTitle from './TabTitle'

type Props = {
  children: ReactElement[]
  subtitle?: string
  classname?: string
  activeTab?: number
  setActiveTab?: React.Dispatch<React.SetStateAction<number>>
}

const Tabs: React.FC<Props> = ({
  children,
  subtitle,
  classname,
  setActiveTab,
  activeTab
}) => {
  const [selectedTab, setSelectedTab] = useState(0)

  return (
    <div>
      <ul
        className={
          'nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder ' +
          classname
        }
      >
        {children.map((item, index) => (
          <TabTitle
            key={index}
            active={
              activeTab !== undefined
                ? index === activeTab
                : index === selectedTab
            }
            title={item.props.title}
            index={index}
            setSelectedTab={setActiveTab || setSelectedTab}
            notClick={item.props.notClick}
          />
        ))}
      </ul>
      <div className="card mb-5 mb-xl-10">
        {subtitle && !!subtitle.length && (
          <div className="d-flex justify-content-end">
            <span className="row">{subtitle}</span>
          </div>
        )}
        {children[activeTab !== undefined ? activeTab : selectedTab]}
      </div>
    </div>
  )
}

export default Tabs
