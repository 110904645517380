import React, { useCallback, useEffect, useState } from 'react'
import Container from '../../../../components/Container'
import { StringParam, useQueryParam } from 'use-query-params'
import Form, { Select } from '../../../../components/Form'
import { Date as DatePicker } from '../../../../components/Form/date'
import Modal from '../../../../components/Modal'
import { useUpdateDataTable } from '../../../../hooks/dataTable'
import DataTable from '../../../../components/DataTable'
import { breadcrumbList } from '../domain/breadcrumb'
import { contractHeaders } from '../domain/headers'
import {
  ContractData,
  nameEntityContracts,
  nameSourceContracts
} from '../domain/info'
import { FormContract } from '../components/FormContract'
import { AsideDefault } from '../../../../assets/layout/components/aside/AsideDefault'
import { toolsListContracts } from '../domain/tools'
import { useParams } from 'react-router-dom'

const ContractsList: React.FC = () => {
  const { id } = useParams<{ id: string }>()

  const [status, setStatus] = useQueryParam('status', StringParam)
  const [date, setDate] = useQueryParam('date', StringParam)
  const [endDateQuery, setEndDateQuery] = useQueryParam('endDate', StringParam)
  const [clearFilter, setClearFilter] = useState<any>()
  const { updateDataTable } = useUpdateDataTable()
  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()

  const [modalEdit, setModalEdit] = useState(false)
  const [searchParameters, setSearchParameters] = useState<any[]>([])
  const [currentItemUpdate, setCurrentItemUpdate] = useState<ContractData>(
    {} as ContractData
  )
  const handleClearFilter = useCallback(() => {
    setClearFilter({
      status: '',
      start_date: '',
      end_date: ''
    })
    setStartDate(undefined)
    setEndDate(undefined)
    setSearchParameters([])
  }, [])

  const handleSetFilter = useCallback(data => {
    setSearchParameters([data])
    setStatus('')
    setDate('')
    setEndDateQuery('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setSearchParameters([{ status, start_date: date, end_date: endDateQuery }])
    setStatus('')
    setDate('')
    setEndDateQuery('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handleClickOnClose = () => {
    setModalEdit(false)
    updateDataTable()
  }
  return (
    <div className="page d-flex flex-row flex-column-fluid">
      <AsideDefault />
      <Container
        pageTitle="Listagem"
        breadcrumb={breadcrumbList}
        tools={toolsListContracts}
      >
        <Form onSubmit={handleSetFilter} defaultValues={clearFilter}>
          <div className="form">
            <div className="row mb-5">
              <DatePicker
                className="col-md-3"
                name="start_date"
                label="Início"
                selected={startDate}
                onChange={date => setStartDate(date)}
                controlled
              />
              <DatePicker
                label="Término"
                className="col-md-3"
                name="end_date"
                selected={endDate}
                onChange={date => setEndDate(date)}
                controlled
              />
              <Select
                className="col-md-3"
                name="status"
                label="Status"
                options={[
                  {
                    name: 'Pagamento Pendente',
                    value: 'pending'
                  },
                  {
                    name: 'Pagamento Confirmado',
                    value: 'confirmed'
                  }
                ]}
                blank
                defaultValue=""
              />
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <div className="col-md-2 d-flex justify-content-end">
              <button
                type="reset"
                className="btn btn-light-primary"
                onClick={handleClearFilter}
              >
                Limpar
              </button>
            </div>
            <div className="col-md-1 d-flex justify-content-end">
              <button type="submit" className="btn btn-primary">
                Filtrar
              </button>
            </div>
          </div>
        </Form>
        <DataTable
          source={`${nameSourceContracts}/${id}`}
          entity={nameEntityContracts}
          format={{ orderBy: 'created_at' }}
          notHasChildren
          headers={contractHeaders}
          searchParameters={[{ ...searchParameters[0] }]}
          actions={[
            {
              name: 'Atualizar',
              title: 'Atualizar',
              spanIcon: 'fa fa-edit me-5',
              target: '_blank'
            },
            {
              name: 'Status',
              title: 'Mudar Status',
              spanIcon: 'fa-regular fa-clipboard',
              target: '_blank',
              onClick: (item: ContractData) => {
                setModalEdit(true)
                setCurrentItemUpdate(item)
              }
            }
          ]}
        />
      </Container>
      {modalEdit && (
        <Modal
          onClickButtonCancel={handleClickOnClose}
          isOpenModal={modalEdit}
          pageTitle={'Editar'}
          Children={
            <FormContract
              typeForm="update"
              initialValues={{
                ...currentItemUpdate,
                idUpdate: currentItemUpdate?.id
              }}
              isOpenInModal={{
                handleOnClose: handleClickOnClose,
                idParent: currentItemUpdate?.id
              }}
            />
          }
        />
      )}
    </div>
  )
}

export default ContractsList
