import { ToolsContainerProps } from '../../../../../../components/Container'
import { nameActions } from '../../info'

export const toolsListContracts: ToolsContainerProps[] = [
  {
    name: nameActions.readContracts.name,
    to: nameActions.readContracts.to,
    icon: nameActions.readContracts.icon
  }
]
