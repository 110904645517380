import { Redirect, Route as ReactRouter, Switch } from 'react-router-dom'
import { NotFoundPage } from '../pages/NotFound'
import Dashboard from '../pages/Dashboard'
import SignIn from '../pages/Signin'
import { MenusRouter } from './menus/MenusRouter'
import { useAuth } from '../hooks/auth'
import { ForgotPassword } from '../pages/ForgotPassword'
import { UsersRouter } from './users/UsersRouter'
import { OperationIndexesRouter } from './financial/OperationIndexesRouter'
import { FormsRouter } from './forms/FormsRouter'
import { ClientsRouter } from './commercial/ClientsRouter'
import PrivateRoutes from './PrivateRoute'
import Home from '../pages/Home'

const Routes: React.FC = () => {
  const { userLogged, backupHistory } = useAuth()
  return (
    <Switch>
      <ReactRouter path="/" exact component={Home} />
      <PrivateRoutes path="/dashboard" exact component={Dashboard} />
      <ReactRouter path="/forms" component={FormsRouter} />
      <ReactRouter path="/signin" exact component={SignIn}>
        {!backupHistory.length && userLogged() && <Redirect to="/dashboard" />}
      </ReactRouter>
      <PrivateRoutes path="/users" component={UsersRouter} />
      <PrivateRoutes path="/commercial/clients" component={ClientsRouter} />
      <PrivateRoutes
        path="/financial/operationIndexes"
        component={OperationIndexesRouter}
      />
      <PrivateRoutes path="/menus" component={MenusRouter} />
      <ReactRouter path="/forgot" component={ForgotPassword} />
      <ReactRouter path="*" component={NotFoundPage} />
    </Switch>
  )
}
export default Routes
