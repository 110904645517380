import moment from 'moment'
import { useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { IMaskInput } from 'react-imask'
import {
  Checkbox,
  Input,
  InputProps,
  Select,
  SelectProps
} from '../../../../../../components/Form'
import {
  Date as DatePicker,
  DateProps
} from '../../../../../../components/Form/date'
import { ContainerAnimation } from './styles'

interface DatepickerProps extends DateProps {
  selected: Date
  setSelected: React.Dispatch<React.SetStateAction<Date>>
}

interface FormMaritalStatusProps {
  datepickerMarriageStart: DatepickerProps
  datepickerMarriageEnd: DatepickerProps
  datepickerSpouseDateBirth: DatepickerProps
  datepickerStableUnion: DatepickerProps
  inputSpouseName: InputProps
  inputSpouseSurName: InputProps
  inputSpouseAddress: InputProps
  inputSpouseOccupation: InputProps
  inputSpouseCountry: InputProps
  inputSpouseDescriptionOccupation: InputProps
  selectTravelWithYou: SelectProps
  selectRelationshipType: SelectProps
  label?: string
  type?: string
  inputStatus?: InputProps
  changeTab?: React.SetStateAction<string>
}

export const FormMaritalStatus: React.FC<FormMaritalStatusProps> = ({
  datepickerMarriageStart,
  datepickerMarriageEnd,
  inputSpouseName,
  inputSpouseSurName,
  inputStatus,
  label,
  datepickerStableUnion,
  inputSpouseAddress,
  inputSpouseCountry,
  inputSpouseDescriptionOccupation,
  inputSpouseOccupation,
  datepickerSpouseDateBirth,
  selectTravelWithYou,
  selectRelationshipType,
  changeTab
}) => {
  const inputMaskFormat = 'DD/MM/YYYY'

  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()
  const [birthDate, setBirthDate] = useState<Date>()
  const [unionStableDate, setUnionStableDate] = useState<Date>()
  const unionTypes = ['married', 'stable union']
  const noUnionTypes = ['divorced', 'widowed']
  const {
    register,
    watch,
    getValues,
    setValue,
    control,
    clearErrors,
    formState: { errors }
  } = useFormContext()
  const watchField = watch(inputStatus?.name)

  useEffect(() => {
    if (watchField === 'single') {
      const prefixArray = inputStatus.name.split('.')
      prefixArray.pop()
      const prefix = prefixArray.join('.')
      const workFields = getValues(prefix)
      if (workFields) {
        setValue(datepickerSpouseDateBirth.name, '')
        setValue(datepickerStableUnion.name, '')
        setValue(datepickerStableUnion.name, '')
        setValue(selectTravelWithYou.name, '')
        setValue(datepickerMarriageStart.name, '')
        setValue(datepickerMarriageEnd.name, '')
        setValue(selectRelationshipType.name, '')
        const objectEntries = Object.entries(workFields)
        for (let [key] of objectEntries) {
          key = `${prefix}.${key}`
          if (inputStatus.name !== key) {
            setValue(key, '')
          }
        }
      }
      clearErrors(prefix)
    }
  }, [
    clearErrors,
    datepickerMarriageEnd.name,
    datepickerMarriageStart.name,
    datepickerSpouseDateBirth.name,
    datepickerStableUnion.name,
    getValues,
    inputStatus.name,
    selectRelationshipType.name,
    selectTravelWithYou,
    setValue,
    watchField
  ])

  return (
    <div>
      <div>
        {!!label && (
          <p className="mb-2 form-check-label text-gray-700 fw-bold  p-0">
            {label}
          </p>
        )}
        <div className="d-flex flex-column flex-sm-row">
          <div className="col mb-2">
            <Checkbox
              className="col-xl-1"
              label="Casado"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              register={register}
              {...inputStatus}
              type="radio"
              rules={{ required: changeTab !== 'previous' }}
              errors={errors}
              hasErrorCustom
              value="married"
              hasCustom
            />
          </div>
          <div className="col mb-2">
            <Checkbox
              className="col-xl-1"
              label="Solteiro"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              register={register}
              {...inputStatus}
              type="radio"
              rules={{ required: changeTab !== 'previous' }}
              errors={errors}
              value="single"
              hasCustom
            />
          </div>
          <div className="col mb-2">
            <Checkbox
              className="col-xl-1"
              label="União Estável"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              register={register}
              {...inputStatus}
              type="radio"
              value="stable union"
              rules={{ required: changeTab !== 'previous' }}
              hasCustom
              errors={errors}
            />
          </div>
          <div className="col mb-2">
            <Checkbox
              className="col-xl-1"
              label="Viúvo"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              register={register}
              {...inputStatus}
              type="radio"
              value="widowed"
              rules={{ required: changeTab !== 'previous' }}
              hasCustom
              errors={errors}
            />
          </div>
          <div className="col mb-2">
            <Checkbox
              className="col-xl-1"
              label="Divorciado"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              register={register}
              {...inputStatus}
              type="radio"
              value="divorced"
              rules={{ required: changeTab !== 'previous' }}
              errors={errors}
              hasCustom
            />
          </div>
        </div>
      </div>
      <div className="row mb-5 pe-xl-5 ps-3">
        {watchField === 'single' ? (
          <ContainerAnimation isShow="hidden" height="0">
            <div className="row mb-5"></div>
          </ContainerAnimation>
        ) : unionTypes.includes(watchField) ? (
          <ContainerAnimation
            isShow={unionTypes.includes(watchField) ? 'show' : 'hidden'}
            id="family_union"
          >
            <div className="row mb-5 pe-xl-5">
              <Input
                className="col-xl"
                label="Nome do cônjuge"
                register={register}
                inputOptions={{
                  classname: 'd-flex align-items-center'
                }}
                {...inputSpouseName}
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: unionTypes.includes(watchField)
                      }
                    : { required: false }
                }
                errors={errors}
                hasErrorCustom
                tooltip={{
                  container: {
                    classname: 'ms-2'
                  },
                  classname: 'mw-30px',
                  message: `<p style="max-width: 300px">
                  Informe o nome do seu conjuge
                          </p>`
                }}
                hasCustom
              />
              <Input
                className="col-xl"
                label="Sobrenome do cônjuge"
                register={register}
                inputOptions={{
                  classname: 'd-flex align-items-center'
                }}
                {...inputSpouseSurName}
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: unionTypes.includes(watchField)
                      }
                    : { required: false }
                }
                errors={errors}
                hasErrorCustom
                tooltip={{
                  container: {
                    classname: 'ms-2'
                  },
                  classname: 'mw-30px',
                  message: `<p style="max-width: 300px">
                  Informe o sobrenome do seu cônjuge
                          </p>`
                }}
                hasCustom
              />
            </div>
            <div className="row mb-5 pe-xl-5">
              <Controller
                shouldUnregister={true}
                control={control}
                name={datepickerSpouseDateBirth.name}
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: unionTypes.includes(watchField)
                      }
                    : { required: false }
                }
                render={({ field }) => (
                  <DatePicker
                    popperProps={{ strategy: 'fixed' }}
                    locale="ptBR"
                    className="col-xl"
                    register={register}
                    inputOptions={{ classname: 'd-flex align-items-center' }}
                    label="Data de nascimento do cônjuge"
                    dateFormat="dd/MM/yyyy"
                    placeholderText="DD/MM/AAAA"
                    hasCustom
                    tooltip={{
                      container: {
                        classname: 'ms-2'
                      },
                      classname: 'mw-30px',
                      message: `<p style="max-width: 300px">
                      Informe a data de nascimento do seu cônjuge
                        </p>`
                    }}
                    selected={birthDate}
                    {...field}
                    rules={
                      changeTab !== 'previous'
                        ? {
                            required: unionTypes.includes(watchField)
                          }
                        : { required: false }
                    }
                    errors={errors}
                    hasErrorCustom
                    customInput={
                      <IMaskInput
                        mask={Date}
                        pattern={'d/m/Y'}
                        format={date => {
                          return moment(date).format(inputMaskFormat)
                        }}
                        parse={value => {
                          return moment(value, inputMaskFormat).toDate()
                        }}
                      />
                    }
                    onChange={(e: any) => {
                      field.onChange(e)
                      setBirthDate(e)
                    }}
                  />
                )}
              />
              <Input
                className="col-xl pe-0"
                register={register}
                label="Endereço residencial do cônjuge"
                inputOptions={{
                  classname: 'd-flex align-items-center'
                }}
                {...inputSpouseAddress}
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: unionTypes.includes(watchField)
                      }
                    : { required: false }
                }
                errors={errors}
                hasErrorCustom
                hasCustom
              />
            </div>
            <div className="row mb-5 pe-xl-5">
              <Input
                className="col-xl pe-0"
                label="Ocupação do cônjuge"
                register={register}
                inputOptions={{
                  classname: 'd-flex align-items-center'
                }}
                {...inputSpouseOccupation}
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: unionTypes.includes(watchField)
                      }
                    : { required: false }
                }
                errors={errors}
                hasErrorCustom
                hasCustom
              />
              <Input
                className="col-xl pe-0"
                label="País de nascimento do cônjuge"
                register={register}
                inputOptions={{
                  classname: 'd-flex align-items-center'
                }}
                {...inputSpouseCountry}
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: unionTypes.includes(watchField)
                      }
                    : { required: false }
                }
                errors={errors}
                hasErrorCustom
                hasCustom
              />
            </div>
            <div className="row mb-5 pe-xl-5">
              <Controller
                shouldUnregister={true}
                control={control}
                name={datepickerStableUnion.name}
                render={({ field }) => (
                  <DatePicker
                    popperProps={{ strategy: 'fixed' }}
                    locale="ptBR"
                    className="col-xl"
                    register={register}
                    inputOptions={{ classname: 'd-flex align-items-center' }}
                    label="Data do casamento ou união estável"
                    dateFormat="dd/MM/yyyy"
                    placeholderText="DD/MM/AAAA"
                    hasCustom
                    selected={unionStableDate}
                    tooltip={{
                      container: {
                        classname: 'ms-2'
                      },
                      classname: 'mw-30px',
                      message: `<p style="max-width: 300px">
                      Informe a data do casamento ou início da união estável
                        </p>`
                    }}
                    {...field}
                    rules={
                      changeTab !== 'previous'
                        ? {
                            required: unionTypes.includes(watchField)
                          }
                        : { required: false }
                    }
                    errors={errors}
                    hasErrorCustom
                    customInput={
                      <IMaskInput
                        mask={Date}
                        pattern={'d/m/Y'}
                        format={date => {
                          return moment(date).format(inputMaskFormat)
                        }}
                        parse={value => {
                          return moment(value, inputMaskFormat).toDate()
                        }}
                      />
                    }
                    onChange={(e: any) => {
                      field.onChange(e)
                      setUnionStableDate(e)
                    }}
                  />
                )}
              />
              <Controller
                shouldUnregister={true}
                control={control}
                name={selectTravelWithYou.name}
                render={({ field }) => (
                  <Select
                    className="col-xl"
                    label="Vai viajar junto com você?"
                    register={register}
                    {...selectTravelWithYou}
                    {...field}
                    errors={errors}
                    hasErrorCustom
                    hasCustom
                  />
                )}
              />
            </div>
            <div className="row mb-5 pe-xl-5">
              <Input
                className="col-xl"
                label="Breve descrição da ocupação atual do cônjuge, nome completo e endereço do empregador"
                register={register}
                inputOptions={{
                  classname: 'd-flex align-items-center'
                }}
                {...inputSpouseDescriptionOccupation}
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: unionTypes.includes(watchField)
                      }
                    : { required: false }
                }
                errors={errors}
                hasErrorCustom
                hasCustom
              />
            </div>
          </ContainerAnimation>
        ) : (
          <ContainerAnimation
            isShow={noUnionTypes.includes(watchField) ? 'show' : 'hidden'}
            id="family_no_union"
          >
            <div className="row mb-5 pe-xl-5">
              <Input
                className="col-xl"
                label="Nome do cônjuge"
                register={register}
                inputOptions={{
                  classname: 'd-flex align-items-center'
                }}
                {...inputSpouseName}
                tooltip={{
                  container: {
                    classname: 'ms-2'
                  },
                  classname: 'mw-30px',
                  message: `<p style="max-width: 300px">
                  Nome do cônjuge anterior
                          </p>`
                }}
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: noUnionTypes.includes(watchField)
                      }
                    : { required: false }
                }
                errors={errors}
                hasErrorCustom
                hasCustom
              />
              <Input
                className="col-xl"
                label="Sobrenome do cônjuge"
                register={register}
                inputOptions={{
                  classname: 'd-flex align-items-center'
                }}
                {...inputSpouseSurName}
                tooltip={{
                  container: {
                    classname: 'ms-2'
                  },
                  classname: 'mw-30px',
                  message: `<p style="max-width: 300px">
                  Sobrenome do cônjuge anterior
                          </p>`
                }}
                rules={
                  changeTab !== 'previous'
                    ? {
                        required: noUnionTypes.includes(watchField)
                      }
                    : { required: false }
                }
                errors={errors}
                hasErrorCustom
                hasCustom
              />
            </div>
            <div className="row mb-5 pe-xl-5">
              <Controller
                shouldUnregister={true}
                control={control}
                name={datepickerSpouseDateBirth.name}
                render={({ field }) => (
                  <DatePicker
                    popperProps={{ strategy: 'fixed' }}
                    locale="ptBR"
                    className="col-xl"
                    register={register}
                    inputOptions={{ classname: 'd-flex align-items-center' }}
                    label="Data de nascimento do cônjuge"
                    dateFormat="dd/MM/yyyy"
                    placeholderText="DD/MM/AAAA"
                    tooltip={{
                      container: {
                        classname: 'ms-2'
                      },
                      classname: 'mw-30px',
                      message: `<p style="max-width: 300px">
                      Informe a data de nascimento do seu cônjuge anterior
                        </p>`
                    }}
                    hasCustom
                    selected={birthDate}
                    {...field}
                    rules={
                      changeTab !== 'previous'
                        ? {
                            required: noUnionTypes.includes(watchField)
                          }
                        : { required: false }
                    }
                    errors={errors}
                    hasErrorCustom
                    customInput={
                      <IMaskInput
                        mask={Date}
                        pattern={'d/m/Y'}
                        format={date => {
                          return moment(date).format(inputMaskFormat)
                        }}
                        parse={value => {
                          return moment(value, inputMaskFormat).toDate()
                        }}
                      />
                    }
                    onChange={(e: any) => {
                      field.onChange(e)
                      setBirthDate(e)
                    }}
                  />
                )}
              />
              <Controller
                shouldUnregister={true}
                control={control}
                name={datepickerMarriageStart.name}
                render={({ field }) => (
                  <DatePicker
                    popperProps={{ strategy: 'fixed' }}
                    locale="ptBR"
                    className="col-xl"
                    register={register}
                    inputOptions={{ classname: 'd-flex align-items-center' }}
                    label="Data de início do casamento"
                    dateFormat="dd/MM/yyyy"
                    placeholderText="DD/MM/AAAA"
                    tooltip={{
                      container: {
                        classname: 'ms-2'
                      },
                      classname: 'mw-30px',
                      message: `<p style="max-width: 300px">
                      Data do casamento
                        </p>`
                    }}
                    hasCustom
                    selected={startDate}
                    {...field}
                    rules={
                      changeTab !== 'previous'
                        ? {
                            required: noUnionTypes.includes(watchField)
                          }
                        : { required: false }
                    }
                    errors={errors}
                    hasErrorCustom
                    customInput={
                      <IMaskInput
                        mask={Date}
                        pattern={'d/m/Y'}
                        format={date => {
                          return moment(date).format(inputMaskFormat)
                        }}
                        parse={value => {
                          return moment(value, inputMaskFormat).toDate()
                        }}
                      />
                    }
                    onChange={(e: any) => {
                      field.onChange(e)
                      setStartDate(e)
                    }}
                  />
                )}
              />
            </div>
            <div className="row mb-5 pe-xl-5">
              <Controller
                shouldUnregister={true}
                control={control}
                name={datepickerMarriageEnd.name}
                render={({ field }) => (
                  <DatePicker
                    popperProps={{ strategy: 'fixed' }}
                    locale="ptBR"
                    className="col-xl"
                    register={register}
                    inputOptions={{ classname: 'd-flex align-items-center' }}
                    label="Data de término do casamento (ou data do falecimento do cônjuge no caso de víuvo)"
                    tooltip={{
                      container: {
                        classname: 'ms-2'
                      },
                      classname: 'mw-30px',
                      message: `<p style="max-width: 300px">
                      Informe a data do término do casamento (ou falecimento do cônjuge em caso de viúvo)
                        </p>`
                    }}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="DD/MM/AAAA"
                    hasCustom
                    selected={endDate}
                    {...field}
                    rules={
                      changeTab !== 'previous'
                        ? {
                            required: noUnionTypes.includes(watchField)
                          }
                        : { required: false }
                    }
                    errors={errors}
                    hasErrorCustom
                    customInput={
                      <IMaskInput
                        mask={Date}
                        pattern={'d/m/Y'}
                        format={date => {
                          return moment(date).format(inputMaskFormat)
                        }}
                        parse={value => {
                          return moment(value, inputMaskFormat).toDate()
                        }}
                      />
                    }
                    onChange={(e: any) => {
                      field.onChange(e)
                      setEndDate(e)
                    }}
                  />
                )}
              />
              <Controller
                shouldUnregister={true}
                control={control}
                name={selectRelationshipType.name}
                render={({ field }) => (
                  <Select
                    className="col-xl"
                    label="Tipo de Relacionamento Anterior"
                    register={register}
                    {...selectRelationshipType}
                    {...field}
                    errors={errors}
                    hasErrorCustom
                    hasCustom
                  />
                )}
              />
            </div>
          </ContainerAnimation>
        )}

        <div
          id="family_union_copy"
          style={{ height: '0', visibility: 'hidden' }}
        >
          <div className="row mb-5 pe-xl-5">
            <Input
              className="col-xl"
              label="Nome do cônjuge"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              {...inputSpouseName}
              hasErrorCustom
              tooltip={{
                container: {
                  classname: 'ms-2'
                },
                classname: 'mw-30px',
                message: `<p style="max-width: 300px">
                  Informe o nome do seu conjuge
                          </p>`
              }}
              hasCustom
            />
            <Input
              className="col-xl"
              label="Sobrenome do cônjuge"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              {...inputSpouseSurName}
              hasErrorCustom
              tooltip={{
                container: {
                  classname: 'ms-2'
                },
                classname: 'mw-30px',
                message: `<p style="max-width: 300px">
                  Informe o sobrenome do seu cônjuge
                          </p>`
              }}
              hasCustom
            />
          </div>
          <div className="row mb-5 pe-xl-5">
            <Controller
              shouldUnregister={true}
              control={control}
              name={datepickerSpouseDateBirth.name}
              render={({ field }) => (
                <DatePicker
                  popperProps={{ strategy: 'fixed' }}
                  locale="ptBR"
                  className="col-xl"
                  inputOptions={{ classname: 'd-flex align-items-center' }}
                  label="Data de nascimento do cônjuge"
                  dateFormat="dd/MM/yyyy"
                  placeholderText="DD/MM/AAAA"
                  hasCustom
                  tooltip={{
                    container: {
                      classname: 'ms-2'
                    },
                    classname: 'mw-30px',
                    message: `<p style="max-width: 300px">
                      Informe a data de nascimento do seu cônjuge
                        </p>`
                  }}
                  selected={birthDate}
                  {...field}
                  rules={
                    changeTab !== 'previous'
                      ? {
                          required: unionTypes.includes(watchField)
                        }
                      : { required: false }
                  }
                  hasErrorCustom
                  customInput={
                    <IMaskInput
                      mask={Date}
                      pattern={'d/m/Y'}
                      format={date => {
                        return moment(date).format(inputMaskFormat)
                      }}
                      parse={value => {
                        return moment(value, inputMaskFormat).toDate()
                      }}
                    />
                  }
                  onChange={(e: any) => {
                    field.onChange(e)
                    setBirthDate(e)
                  }}
                />
              )}
            />
            <Input
              className="col-xl pe-0"
              label="Endereço residencial do cônjuge"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              {...inputSpouseAddress}
              hasErrorCustom
              hasCustom
            />
          </div>
          <div className="row mb-5 pe-xl-5">
            <Input
              className="col-xl pe-0"
              label="Ocupação do cônjuge"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              {...inputSpouseOccupation}
              hasErrorCustom
              hasCustom
            />
            <Input
              className="col-xl pe-0"
              label="País de nascimento do cônjuge"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              {...inputSpouseCountry}
              hasErrorCustom
              hasCustom
            />
          </div>
          <div className="row mb-5 pe-xl-5">
            <Controller
              shouldUnregister={true}
              control={control}
              name={datepickerStableUnion.name}
              render={({ field }) => (
                <DatePicker
                  popperProps={{ strategy: 'fixed' }}
                  locale="ptBR"
                  className="col-xl"
                  inputOptions={{ classname: 'd-flex align-items-center' }}
                  label="Data do casamento ou união estável"
                  dateFormat="dd/MM/yyyy"
                  placeholderText="DD/MM/AAAA"
                  hasCustom
                  selected={unionStableDate}
                  tooltip={{
                    container: {
                      classname: 'ms-2'
                    },
                    classname: 'mw-30px',
                    message: `<p style="max-width: 300px">
                      Informe a data do casamento ou início da união estável
                        </p>`
                  }}
                  {...field}
                  rules={
                    changeTab !== 'previous'
                      ? {
                          required: unionTypes.includes(watchField)
                        }
                      : { required: false }
                  }
                  hasErrorCustom
                  customInput={
                    <IMaskInput
                      mask={Date}
                      pattern={'d/m/Y'}
                      format={date => {
                        return moment(date).format(inputMaskFormat)
                      }}
                      parse={value => {
                        return moment(value, inputMaskFormat).toDate()
                      }}
                    />
                  }
                  onChange={(e: any) => {
                    field.onChange(e)
                    setUnionStableDate(e)
                  }}
                />
              )}
            />
            <Controller
              shouldUnregister={true}
              control={control}
              name={selectTravelWithYou.name}
              render={({ field }) => (
                <Select
                  className="col-xl"
                  label="Vai viajar junto com você?"
                  {...selectTravelWithYou}
                  {...field}
                  rules={{
                    required: true,
                    minLength: {
                      value: 10,
                      message: 'Digite Nome e Sobrenome Completos'
                    }
                  }}
                  hasErrorCustom
                  hasCustom
                />
              )}
            />
          </div>
          <div className="row mb-5 pe-xl-5">
            <Input
              className="col-xl"
              label="Breve descrição da ocupação atual do cônjuge, nome completo e endereço do empregador"
              inputOptions={{
                classname: 'd-flex align-items-center'
              }}
              {...inputSpouseDescriptionOccupation}
              hasErrorCustom
              hasCustom
            />
          </div>
        </div>

        <div
          id="family_no_union_copy"
          style={{ height: '0', visibility: 'hidden' }}
        >
          <div>
            <div className="row mb-5 pe-xl-5">
              <Input
                className="col-xl"
                label="Nome do cônjuge"
                inputOptions={{
                  classname: 'd-flex align-items-center'
                }}
                {...inputSpouseName}
                tooltip={{
                  container: {
                    classname: 'ms-2'
                  },
                  classname: 'mw-30px',
                  message: `<p style="max-width: 300px">
                  Nome do cônjuge anterior
                          </p>`
                }}
                hasCustom
              />
              <Input
                className="col-xl"
                label="Sobrenome do cônjuge"
                inputOptions={{
                  classname: 'd-flex align-items-center'
                }}
                {...inputSpouseSurName}
                tooltip={{
                  container: {
                    classname: 'ms-2'
                  },
                  classname: 'mw-30px',
                  message: `<p style="max-width: 300px">
                  Sobrenome do cônjuge anterior
                          </p>`
                }}
                errors={errors}
                hasErrorCustom
                hasCustom
              />
            </div>
            <div className="row mb-5 pe-xl-5">
              <Controller
                shouldUnregister={true}
                control={control}
                name={datepickerSpouseDateBirth.name}
                render={({ field }) => (
                  <DatePicker
                    popperProps={{ strategy: 'fixed' }}
                    locale="ptBR"
                    className="col-xl"
                    inputOptions={{ classname: 'd-flex align-items-center' }}
                    label="Data de nascimento do cônjuge"
                    dateFormat="dd/MM/yyyy"
                    placeholderText="DD/MM/AAAA"
                    tooltip={{
                      container: {
                        classname: 'ms-2'
                      },
                      classname: 'mw-30px',
                      message: `<p style="max-width: 300px">
                      Informe a data de nascimento do seu cônjuge anterior
                        </p>`
                    }}
                    hasCustom
                    selected={birthDate}
                    {...field}
                    errors={errors}
                    hasErrorCustom
                    customInput={
                      <IMaskInput
                        mask={Date}
                        pattern={'d/m/Y'}
                        format={date => {
                          return moment(date).format(inputMaskFormat)
                        }}
                        parse={value => {
                          return moment(value, inputMaskFormat).toDate()
                        }}
                      />
                    }
                    onChange={(e: any) => {
                      field.onChange(e)
                      setBirthDate(e)
                    }}
                  />
                )}
              />
              <Controller
                shouldUnregister={true}
                control={control}
                name={datepickerMarriageStart.name}
                render={({ field }) => (
                  <DatePicker
                    popperProps={{ strategy: 'fixed' }}
                    locale="ptBR"
                    className="col-xl"
                    inputOptions={{ classname: 'd-flex align-items-center' }}
                    label="Data de início do casamento"
                    dateFormat="dd/MM/yyyy"
                    placeholderText="DD/MM/AAAA"
                    tooltip={{
                      container: {
                        classname: 'ms-2'
                      },
                      classname: 'mw-30px',
                      message: `<p style="max-width: 300px">
                      Data do casamento
                        </p>`
                    }}
                    hasCustom
                    selected={startDate}
                    {...field}
                    customInput={
                      <IMaskInput
                        mask={Date}
                        pattern={'d/m/Y'}
                        format={date => {
                          return moment(date).format(inputMaskFormat)
                        }}
                        parse={value => {
                          return moment(value, inputMaskFormat).toDate()
                        }}
                      />
                    }
                    onChange={(e: any) => {
                      field.onChange(e)
                      setStartDate(e)
                    }}
                  />
                )}
              />
            </div>
            <div className="row mb-5 pe-xl-5">
              <Controller
                shouldUnregister={true}
                control={control}
                name={datepickerMarriageEnd.name}
                render={({ field }) => (
                  <DatePicker
                    popperProps={{ strategy: 'fixed' }}
                    locale="ptBR"
                    className="col-xl"
                    inputOptions={{ classname: 'd-flex align-items-center' }}
                    label="Data de término do casamento (ou data do falecimento do cônjuge no caso de víuvo)"
                    tooltip={{
                      container: {
                        classname: 'ms-2'
                      },
                      classname: 'mw-30px',
                      message: `<p style="max-width: 300px">
                      Informe a data do término do casamento (ou falecimento do cônjuge em caso de viúvo)
                        </p>`
                    }}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="DD/MM/AAAA"
                    hasCustom
                    selected={endDate}
                    {...field}
                    customInput={
                      <IMaskInput
                        mask={Date}
                        pattern={'d/m/Y'}
                        format={date => {
                          return moment(date).format(inputMaskFormat)
                        }}
                        parse={value => {
                          return moment(value, inputMaskFormat).toDate()
                        }}
                      />
                    }
                    onChange={(e: any) => {
                      field.onChange(e)
                      setEndDate(e)
                    }}
                  />
                )}
              />
              <Controller
                shouldUnregister={true}
                control={control}
                name={selectRelationshipType.name}
                render={({ field }) => (
                  <Select
                    className="col-xl"
                    label="Tipo de Relacionamento Anterior"
                    {...field}
                    {...selectRelationshipType}
                    hasErrorCustom
                    hasCustom
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
